import React from 'react';

import { ISourcesApi } from '../SourcesApi';
import { createUseContext } from '../../../utils/create-use-context';

export type SourceApiContextValue = {
  api: ISourcesApi
}

const SourceApiContext = React.createContext<SourceApiContextValue | null>(null);
SourceApiContext.displayName = 'SourceApiContext';

export const useSourceApiContext = createUseContext(SourceApiContext);

export const SourceApiContextProvider = SourceApiContext.Provider;
