import { useMemo } from 'react';
import { useGlobalAnalyticsContext } from '../../thread/contexts/GlobalAnalyticsContext';
import { useAnalytics } from './context';

import {
  answerContentCopied,
  answerGenerationEnded,
  answerGenerationStarted,
  answerLinkClicked,
  appLoaded,
  createMemoEmailClickedEvent,
  createSourceDetailsToggledEvent,
  createTipsNavigationClickedEvent,
  dataSourceEditCancelled,
  dataSourceEditClicked,
  dataSourceEditSaved,
  docQuestionAsked,
  docQuestionCopied,
  docQuestionRetryClicked,
  docQuestionUnableToAnswerReceived,
  errorTriggered,
  expectationMessageViewed,
  faqClicked,
  followUpQuestionsSettingChanged,
  logoClicked,
  logoutClicked,
  newThreadClicked,
  npsModalClosed,
  questionAsked,
  questionDeleted,
  questionDeleteInitiated,
  questionFeedback,
  questionFeedbackClicked,
  restrictedSourceOpenInNewTabClicked,
  savedPromptCreated,
  savedPromptRemoved,
  savedPromptSelected,
  savedPromptsViewed,
  searchCleared,
  settingsClicked,
  shareEvent,
  sourceLinkClicked,
  sourceNavigationClicked,
  sourceOpenInNewTabClicked,
  sourcePrintClicked,
  sourceViewed,
  stopGeneratingClicked,
  textLimitWarningViewed,
  threadClicked,
  threadCreated,
  threadDeleted,
  threadGroupToggled,
  threadPrintClicked,
  threadSearched,
  threadRenamed,
  threadViewed,
  tipsExampleClicked,
  tryAgainClicked,
  unableToAnswerReceived,
  userNPSSurvey,
  viewLessSourcesClicked,
  viewMoreSourcesClicked
} from './events';
import { ChatContext, ThreadContext } from './types';

export function useAnalyticsEvent() {
  const analytics = useAnalytics();
  const context = useGlobalAnalyticsContext();

  const threadContext: ThreadContext = {
    threadId: context.threadId,
    answerStrategy: context.answerStrategy
  }

  const chatContext: ChatContext = {
    threadId: context.threadId,
    chatId: context.chatId,
    answerStrategy: context.answerStrategy
  }

  return useMemo(() => ({
    trackThreadCreated: threadCreated(analytics.track),
    trackThreadViewed: threadViewed(analytics.track),
    trackThreadRenamed: threadRenamed(analytics.track),
    trackThreadDeleted: threadDeleted(analytics.track),
    trackErrorTriggered: errorTriggered(analytics.track),
    trackFaqClicked: faqClicked(analytics.track),
    trackLogoClicked: logoClicked(analytics.track),
    trackLogoutClicked: logoutClicked(analytics.track),
    trackNewThreadClicked: newThreadClicked(analytics.track),
    trackQuestionAsked: questionAsked(analytics.track),
    trackQuestionFeedback: questionFeedback(analytics.track, chatContext),
    trackQuestionFeedbackClicked: questionFeedbackClicked(analytics.track, chatContext),
    trackShareCopy: shareEvent(analytics.track, 'responseCopyClicked', chatContext),
    trackSharePrint: shareEvent(analytics.track, 'responsePrintClicked', chatContext),
    trackSourceDetailsToggledOn: createSourceDetailsToggledEvent(analytics.track, 'sourceDetailsToggledOn', chatContext),
    trackSourceDetailsToggledOff: createSourceDetailsToggledEvent(analytics.track, 'sourceDetailsToggledOff', chatContext),
    trackSourceLinkClicked: sourceLinkClicked(analytics.track, chatContext),
    trackSourceNavigationClicked: sourceNavigationClicked(analytics.track, chatContext),
    trackSourceViewed: sourceViewed(analytics.track, threadContext),
    trackTryAgainClicked: tryAgainClicked(analytics.track, threadContext),
    trackUnableToAnswerReceived: unableToAnswerReceived(analytics.track),
    trackSourceOpenInNewTabClicked: sourceOpenInNewTabClicked(analytics.track, chatContext),
    trackSourcePrintClicked: sourcePrintClicked(analytics.track, chatContext),
    trackQuestionDeleted: questionDeleted(analytics.track, chatContext),
    trackQuestionDeleteInitiated: questionDeleteInitiated(analytics.track, chatContext),
    trackStopGeneratingClicked: stopGeneratingClicked(analytics.track, chatContext),
    trackUserNPSSurvey: userNPSSurvey(analytics.track),
    trackNPSModalClosed: npsModalClosed(analytics.track),
    trackAnswerGenerationStarted: answerGenerationStarted(analytics.track),
    trackAnswerGenerationEnded: answerGenerationEnded(analytics.track),
    trackAnswerLinkClicked: answerLinkClicked(analytics.track, chatContext),
    trackAnswerContentCopied: answerContentCopied(analytics.track, chatContext),
    trackDataSourceEditClicked: dataSourceEditClicked(analytics.track),
    trackDataSourceEditCancelled: dataSourceEditCancelled(analytics.track),
    trackDataSourceEditSaved: dataSourceEditSaved(analytics.track),
    trackFollowUpQuestionsSettingChanged: followUpQuestionsSettingChanged(analytics.track),
    trackDocQuestionAsked: docQuestionAsked(analytics.track),
    trackDocQuestionCopied: docQuestionCopied(analytics.track),
    trackDocQuestionRetryClicked: docQuestionRetryClicked(analytics.track),
    trackDocQuestionUnableToAnswerReceived: docQuestionUnableToAnswerReceived(analytics.track),
    trackSettingsClicked: settingsClicked(analytics.track),
    trackExpectationMessageViewed: expectationMessageViewed(analytics.track, chatContext),
    trackRestrictedSourceOpenInNewTabClicked: restrictedSourceOpenInNewTabClicked(analytics.track, chatContext),
    trackCreateMemoClicked: createMemoEmailClickedEvent(analytics.track, 'createMemoClicked', threadContext),
    trackCreateEmailClicked: createMemoEmailClickedEvent(analytics.track, 'createEmailClicked', threadContext),
    trackThreadPrintClicked: threadPrintClicked(analytics.track),
    trackTipsExampleClicked: tipsExampleClicked(analytics.track),
    trackTipsNextClicked: createTipsNavigationClickedEvent(analytics.track, 'tipsNextClicked'),
    trackTipsBackClicked: createTipsNavigationClickedEvent(analytics.track, 'tipsBackClicked'),
    trackThreadGroupToggled: threadGroupToggled(analytics.track),
    trackTextLimitWarningViewed: textLimitWarningViewed(analytics.track, threadContext),
    trackInTextCitationClicked: sourceLinkClicked(analytics.track, chatContext, 'inTextCitationClicked'),
    trackAppLoaded: appLoaded(analytics.track),
    trackViewMoreSourcesClicked: viewMoreSourcesClicked(analytics.track, chatContext),
    trackViewLessSourcesClicked: viewLessSourcesClicked(analytics.track, chatContext),
    trackSavedPromptCreated: savedPromptCreated(analytics.track, chatContext),
    trackSavedPromptRemoved: savedPromptRemoved(analytics.track, chatContext),
    trackSavedPromptsViewed: savedPromptsViewed(analytics.track),
    trackSavedPromptSelected: savedPromptSelected(analytics.track),
    trackThreadClicked: threadClicked(analytics.track),
    trackThreadSearched: threadSearched(analytics.track),
    trackSearchCleared: searchCleared(analytics.track)
  }), [analytics.track]);
}
