import React from 'react';
import { AskDocStreamState } from '../../ask-bluej/streaming/doc/reducer';
import { ActiveDocQuestionView } from './active-doc-question-view';
import { AskDocSubmit } from "../../ask-bluej/streaming/doc/shared";

type ActiveQuestionRenderProps = {
  state: AskDocStreamState;
  onResubmit: AskDocSubmit;
}

export function ActiveDocQuestionRender(props: ActiveQuestionRenderProps) {
  const { state, onResubmit } = props;
  return (
    <>
      <ActiveDocQuestionView state={state} onResubmit={onResubmit} />
    </>
  )
}
