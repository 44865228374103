import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { useCallback } from 'react';
import { Button } from '../../../../components/button';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';

type Props = {
  onAbort: () => void;
}
export const StopStreamingButton = ({ onAbort }: Props) => {
  const { trackStopGeneratingClicked } = useAnalyticsEvent();

  const handleClick = useCallback(() => {
    onAbort();
    trackStopGeneratingClicked();
  }, [onAbort, trackStopGeneratingClicked]);

  return (
    <Button
      className="inline-flex items-center gap-x-1 text-sm shadow-sm p-2"
      colour="primary"
      onClick={() => handleClick()}
      title="Stop generating"
      variant="contained"
    >
      <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
      Stop generating
    </Button>
  );
}
