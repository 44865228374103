import { Thread } from '@/types';
import { InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { PageTitle } from '../../../core/app/page-title';
import { useThreadContext } from '../../contexts/ThreadContext';
import { ShareThread } from '../share-thread';
import { ThreadChatLoader } from './thread-chat-loader';
import { ThreadChatsList } from './thread-chats-list';

type ThreadChatListProps = {
  activeQuestionView?: React.ReactNode;
  questionInputView?: React.ReactNode;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<Thread>, Error>>;
  hasNextPage: boolean;
  followUpQuestionView?: React.ReactNode;
  outerContainerRef: React.RefObject<HTMLDivElement>;
  innerContainerRef: React.RefObject<HTMLDivElement>;
};

export function ThreadChatList(props: ThreadChatListProps) {
  const {
    questionInputView,
    activeQuestionView,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    followUpQuestionView,
    outerContainerRef,
    innerContainerRef
  } = props;

  const { thread } = useThreadContext();
  const { trackThreadViewed } = useAnalyticsEvent();

  useEffect(() => {
    trackThreadViewed(thread.id);
  }, [thread.id]);

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      void fetchNextPage();
    }
  }, [thread.chats.length]);

  return (
    <>
      <PageTitle title={thread.title} />
      <div ref={outerContainerRef} className="flex-1 flex flex-col overflow-hidden relative">
        <div className="hidden lg:flex px-10 py-4 font-semibold text-lg border-b border-b-grey-300 justify-between items-center">
          <h1>{thread.title}</h1>
          <ShareThread />
        </div>
        <ScrollToBottom className="overflow-hidden h-full" followButtonClassName="hidden" initialScrollBehavior="auto">
          <div ref={innerContainerRef} className="flex-1 px-4 md:px-10 pt-4 overflow-hidden">
            <div className="max-w-5xl mx-auto">
              <div className="flex flex-col mb-4">
                { isFetchingNextPage &&
                  <div className="flex-1 pb-4">
                    <ThreadChatLoader />
                  </div>
                }
                <ThreadChatsList />
                { followUpQuestionView }
                { activeQuestionView }
              </div>
            </div>
          </div>
        </ScrollToBottom>
        { questionInputView }
      </div>
    </>
  );
}
