import { Preferences, UserPreferences } from '@/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAskBluejApiContext } from '../api/ask-bluej-api';

export function useUpdateUserPreferencesQuery() {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();

  const mutation = useMutation<UserPreferences, unknown, Preferences>({
    mutationFn: async (data) => {
      return api.updateUserPreferences(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['preferences'] })
    }
  });

  function updateUserPreferences(data: Preferences) {
    return mutation.mutateAsync(data);
  }

  return {
    updateUserPreferences
  }
}
