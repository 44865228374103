import { ErrorBoundary } from '@sentry/react';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';

import { useAdditionalSourcesQuery } from '../../../additional-sources/useAdditionalSourcesQuery';
import { ErrorFallback } from '../../../core/errors/error-fallback';
import { GlobalAnalyticsContextProvider } from '../../contexts/GlobalAnalyticsContext';
import { useChatQuery } from '../../useChatQuery';
import { Panel } from '../panel';
import { SourcesPager } from './sources-pager';
import { SourcesView } from './sources-view';

type Params = {
  threadId: string;
  chatId: string;
  sourceIndex: string;
}

export function AdditionalSourcesPanelRoute() {
  const { threadId, chatId, sourceIndex } = useParams<Params>();

  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const handleOnClose = useCallback(() => {
    navigate(`/${threadId}`, { replace: true });
  }, [threadId]);

  useEffect(() => {
    if (!threadId || !chatId || !sourceIndex || navigationType === 'POP') {
      handleOnClose();
    }
  }, [handleOnClose, threadId, chatId, sourceIndex, navigationType]);

  if (!threadId || !chatId || !sourceIndex || navigationType === 'POP') {
    return null;
  }

  const shouldAnimate = navigationType === 'PUSH';

  return (
    <AdditionalSourcesPanel
      animate={shouldAnimate}
      chatId={chatId}
      onClose={handleOnClose}
      sourceIndex={sourceIndex}
      threadId={threadId}
    />
  );
}

type AdditionalSourcesPanelProps = {
  animate: boolean;
  chatId: string;
  onClose: () => void;
  sourceIndex: string;
  threadId: string;
}

function AdditionalSourcesPanel({ animate, chatId, onClose, sourceIndex, threadId }: AdditionalSourcesPanelProps) {
  const { data, status } = useAdditionalSourcesQuery(threadId, chatId);
  const { data: chatData, status: chatStatus } = useChatQuery(threadId, chatId);

  if (!data || status !== 'success' || !chatData || chatStatus !== 'success') {
    onClose();
    return null;
  }

  // URLs are 1-indexed
  const parsedSourceIndex = parseInt(sourceIndex) - 1;

  if (parsedSourceIndex < 0 || parsedSourceIndex >= data.length) {
    onClose();
    return null;
  }

  const sourceViewListing = data[parsedSourceIndex];

  const sourceNavigationComponent = (
    <SourcesPager
      number={parsedSourceIndex}
      sourceType={sourceViewListing.type}
      sources={data}
      additionalSources={true}
    />
  );

  const answerStrategy = chatData.meta?.answerStrategyName || '';

  return (
    <GlobalAnalyticsContextProvider value={{ threadId, chatId, answerStrategy }}>
      <Panel animate={animate} onClose={onClose}>
        <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
          <SourcesView
            sourceViewListing={sourceViewListing}
            sourceNavigationComponent={sourceNavigationComponent}
            additionalSources={true}
          />
        </ErrorBoundary>
      </Panel>
    </GlobalAnalyticsContextProvider>
  );
}
