import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useHandleEnterKeySubmit } from '../hooks/useHandleEnterKeySubmit';
import AutosizingTextarea from 'react-textarea-autosize';

export const KeyboardSubmittableTextArea = forwardRef<ControlledInputRef, InputProps>((props, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const {
    disabled = false,
    onChange,
    onSubmit,
    value
  } = props;

  // Exposing a ref to allow the parent to focus the input
  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        if (inputRef.current) {
          // Prevent onscreen keyboard from popping up
          inputRef.current.readOnly = true;
          inputRef.current.focus();
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.readOnly = false;
            }
          }, 0);
        }
      },
    }
  }, []);

  const handleKeyDown = useHandleEnterKeySubmit({ onSubmit });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <AutosizingTextarea
      ref={inputRef}
      disabled={disabled}
      maxRows={6}
      value={value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      placeholder="Just ask..."
      className="pl-0 block w-full focus:ring-0 focus:outline-0 border-0 bg-transparent max-h-40 min-h-[40px] resize-none"
      data-1p-ignore
    />
  )
});

type InputProps = {
  onSubmit: () => void;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
};

export type ControlledInputRef = {
  focus: () => void;
}
