import React, { PropsWithChildren, useCallback } from 'react';

type ExternalContentClickTrackerProps = PropsWithChildren<{
  onExternalLinkClicked: (href: string) => void;
}>;

export function ExternalLinkClickTracker({ children, onExternalLinkClicked }: ExternalContentClickTrackerProps) {
  const handleOnClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target instanceof Element) {
      const anchor = getParentAnchor(event.target, event.currentTarget);

      if (anchor) {
        const targetUrl = new URL(anchor.href);
        const windowUrl = new URL(window.location.href);

        if (targetUrl.origin !== windowUrl.origin) {
          onExternalLinkClicked(targetUrl.href);
        }
      }
    }
  }, []);

  return (
    <div onClick={handleOnClick}>
      {children}
    </div>
  )
}

function getParentAnchor(element: HTMLElement | Element | null, container: HTMLElement): HTMLAnchorElement | null {
  if (element === null || element === container) {
    return null;
  }

  if (element instanceof HTMLAnchorElement) {
    return element;
  }

  return getParentAnchor(element.parentElement, container);
}
