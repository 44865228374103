import React from 'react';

import { FeedbackType } from './types';

type Feedback = Record<string, Record<string, FeedbackType>>;

// Quick and dirty tracking for feedback status, not persisted
const FEEDBACK_STATE: Feedback = {};

export function useFeedbackState() {
  const [feedbackState, setFeedbackState] = React.useState<Feedback>(FEEDBACK_STATE);

  const getGivenFeedback = React.useCallback((threadId: string, chatId: string): false | FeedbackType => {
    if (!threadId || !chatId || !feedbackState[threadId] || !feedbackState[threadId][chatId]) {
      return false;
    }
    return feedbackState[threadId][chatId];
  }, [feedbackState]);

  const setFeedbackGiven = (threadId: string, chatId: string, type?: FeedbackType): void => {
    if (threadId || chatId) {
      setFeedbackState(() => {
        if (!FEEDBACK_STATE[threadId]) {
          FEEDBACK_STATE[threadId] = {};
        }

        if (type) {
          FEEDBACK_STATE[threadId][chatId] = type;
        } else {
          delete FEEDBACK_STATE[threadId][chatId];
        }

        return { ...FEEDBACK_STATE };
      });

    }
  };

  return {
    getGivenFeedback,
    setFeedbackGiven,
    feedbackState
  }
}
