export type AskDocPayload = {
  guid: string;
  question: string;
  content: string;
  isRetry: boolean;
}

export type AskDocSubmit = (question: string, isRetry?: boolean) => Promise<boolean>;

export enum AskDocInteractionType {
  PAGE = 'source-page',
  PANE = 'source-pane',
  QUICK_SUMMARIZE = 'quick-summarize'
}

export const DOC_SSE_ENDPOINT = '/ask-doc-sse';
