import clsx from 'clsx';

export default function ThreadTopicBadge(props: { active: boolean, topic: string }) {
  const { active, topic } = props;

  const className = clsx('ml-2 mt-1 uppercase font-semibold text-xxs', {
    'text-grey-600': active,
    'text-grey-500': !active
  });

  return (
    <div className={className}>
      {topic}
    </div>
  )
}
