import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions
} from '@floating-ui/react';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { useGlobalAnalyticsContext } from '../../contexts/GlobalAnalyticsContext';
import { InlineCitationRenderProps } from '../answer-markdown';
import { useSourcesContext } from '../sources/sources-provider';


export const InlineCitationLink = (isActive: boolean) => ({ index, href, children }: InlineCitationRenderProps) => (
  createInlineCitationLink({
    isActive,
    index,
    href: href ?? '',
    children
  })
);

export const ReadOnlyInlineCitation = (props: React.PropsWithChildren) => {
  return (
    <span className="text-blue-400">
        {props.children}
    </span>
  );
}

type BaseCitationLinkProps = {
  isActive: boolean;
  index: number;
  href: string;
  children: React.ReactNode;
}

const createInlineCitationLink = ({ isActive, index, href, children }: BaseCitationLinkProps) => {
  const { trackInTextCitationClicked } = useAnalyticsEvent();
  const { threadId, chatId, answerStrategy } = useGlobalAnalyticsContext();
  const sources = useSourcesContext();
  if (!sources || sources.length === 0) return null;
  const source = sources[index];
  if (!source) return null;

  const handleOnClick = () => {
    trackInTextCitationClicked({
      sourceTitle: source.title,
      sourceType: source.badge,
      contentType: source.type,
      restricted: !('content' in source),
      referredUrl: source.href,
      answerStatus: isActive ? 'in-progress' : 'complete',
    });
  };

  const destination = isActive
    ? `/${threadId}/chat/${chatId}/active/source`
    : `/${threadId}/chat/${chatId}${href}`;
  const activeState = isActive ? { source, answerStrategy } : undefined;
  const state = { ...activeState, scrollToTop: true };

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: 'top',
    onOpenChange: setIsOpen,
    middleware: [
      offset(5),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      })
    ],
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context);
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
  ]);

  return (
    <>
      <Link
        ref={refs.setReference}
        {...getReferenceProps()}
        className="inline-block text-blue-400 underline-offset-4 hover:underline"
        to={destination}
        state={state}
        onClick={handleOnClick}
      >
        {children}
      </Link>
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={floatingStyles}
          role="tooltip"
          className="z-50 select-none rounded-md px-2 py-1 text-xs font-bold text-white bg-grey-600"
        >
          {source.title}
          <FloatingArrow ref={arrowRef} context={context} className="fill-grey-600" width={10} height={5} />
        </div>
      )}
    </>
  );
};
