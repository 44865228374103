import { useQuery } from '@tanstack/react-query';
import { useAskBluejApiContext } from '../api/ask-bluej-api';
import { Preferences } from '@/types';

export function useUserPreferencesQuery() {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['preferences'],
    queryFn: async (): Promise<Preferences> => {
      const { preferences } = await api.getUserPreferences();
      const preferredTopic = preferences?.preferredTopic;
      const preferredFollowUpOption = preferences?.preferredFollowUpOption;

      if (!preferredTopic) {
        throw new Error('Unable to get user\'s preferred topic.');
      }

      return {
        preferredTopic,
        preferredFollowUpOption
      }
    },
    staleTime: Infinity,
    gcTime: Infinity
  })
}
