import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { useUser } from '../core/user/context';

type Props = {
  preferredTopic: string;
}

export function FollowUpQuestionsSetting({ preferredTopic }: Props) {
  const { updateUserPreferences, preferences, preferencesDataStatus } = useUser();
  const { preferredFollowUpOption: currentFollowUpOption = true } = preferences ?? {};
  const { trackFollowUpQuestionsSettingChanged } = useAnalyticsEvent();
  const [followUpSelected, setFollowUpSelected] = useState<boolean>(currentFollowUpOption);

  const handleSuggestionsOptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.currentTarget.value === 'Yes';
    if (selectedOption === currentFollowUpOption) return;

    setFollowUpSelected((prev) => !prev);
    trackFollowUpQuestionsSettingChanged(selectedOption);
    updateUserPreferences({ preferredTopic, preferredFollowUpOption: selectedOption })
      .then(() => {
        toast.success('Follow-up questions preference updated', { toastId: 'follow-up-questions-success' });
      })
      .catch(() => {
        toast.error('Failed to update follow-up questions preference', { toastId: 'follow-up-questions-error' });
      });
  }, [preferredTopic, currentFollowUpOption]);

  if (preferencesDataStatus !== 'success') return null;
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  return (
    <div className="flex flex-col w-full mt-8 gap-2">
      <label className="block text-md font-semibold leading-6 text-gray-900">
        Suggest follow-up questions
      </label>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        { options.map((option) => (
          <div key={option.label} className="flex items-center">
            <input
              id={option.label}
              name="follow-up-questions"
              value={option.label}
              type="radio"
              className="focus:ring-blue-400 text-blue-400 border-gray-300"
              checked={followUpSelected === option.value}
              onChange={handleSuggestionsOptionChange}
            />
            <label
              htmlFor={option.label}
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              { option.label }
            </label>
          </div>
        )) }
      </div>
    </div>
  )
}
