import React from 'react';

type SourceMetaProps = {
  title: string;
  value: string;
}

export const SourceMetaRow: React.FC<SourceMetaProps> = ({ title, value }) => {
  if (!title || !value) {
    return null;
  }

  return (
    <tr>
      <td className="text-left align-top pr-2">{title}: </td>
      <td className="text-left align-top pl-2">{value}</td>
    </tr>
  );
}
