import { ResponseError } from '../core/api/api';
import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useSentryContext } from '../core/sentry/context';

export function useStrategyResolver() {
  const { api } = useAskBluejApiContext();
  const { sentry } = useSentryContext();

  return async function (topic: string) {
    const { topics } = await api.getStrategies().catch((error) => {
      if (error instanceof ResponseError && error.status !== 401) {
        sentry.reportMessage(`Failed to resolve strategy for topic "${topic}"`, { topic });
      }
      return { topics: [] };
    });

    const strategy = topics.find(t => t.value === topic)?.strategy;

    return strategy ?? '';
  }
}
