import { useMemo } from 'react';

import { SegmentAnalytics } from './segment';
import { mockSegmentAnalyticsLogger } from './mock-logger';
import { useConfigContext } from '../../config/ConfigContext';

export function useSegmentAnalytics() {
  const { segmentWriteKey, environment } = useConfigContext();
  const segmentAnalytics = useMemo(() => {
    if (import.meta.env.VITE_APP_MOCK_ANALYTICS === 'true') {
      return mockSegmentAnalyticsLogger;
    }
    return SegmentAnalytics.create(segmentWriteKey, environment);
  }, [segmentWriteKey]);

  return { segmentAnalytics };
}
