import { EventSourceActions } from '@bluejlegal/event-source-reducer'

import { AskDocStreamMessages, AskDocStreamMessageTypes } from './messages';

import { AskDocInteractionType, AskDocPayload } from './shared';

export type DocCommonState = {
  payload: AskDocPayload;
  apiUrl: string;
  interactionContext: AskDocInteractionType;
}

export type AskDocStreamInitiatedState = DocCommonState & {
  state: 'initiated';
  requestInitiatedTimestamp: number;
}

export type AskDocStreamAnsweringState = DocCommonState & {
  state: 'answering';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
}

export type AskDocStreamCompleteState = DocCommonState & {
  state: 'complete';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
}

// This aborted state isn't created through events or handled by the reducer right now.
// When we get an abort, we're creating the aborted state and the rendering it like an active question.
export type AskDocStreamAbortedState = DocCommonState & {
  state: 'aborted';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
}

export type AskDocStreamErrorState = DocCommonState & {
  state: 'error';
  answer: string;
  requestInitiatedTimestamp: number;
  answerStreamingStartedTimestamp: number;
  answerStreamingEndedTimestamp: number;
}

export function createInitialState(payload: AskDocPayload, apiUrl: string, interactionContext: AskDocInteractionType): AskDocStreamInitiatedState {
  return {
    state: 'initiated',
    payload,
    requestInitiatedTimestamp: Date.now(),
    apiUrl,
    interactionContext
  }
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}

function isMessageData(messageData: unknown): messageData is AskDocStreamMessages {
  return isObject(messageData) && AskDocStreamMessageTypes.has((messageData as AskDocStreamMessages).type);
}

export const filter = (messageData: unknown): messageData is AskDocStreamMessages => {
  return isMessageData(messageData);
};

export type AskDocStreamState = AskDocStreamInitiatedState | AskDocStreamAnsweringState | AskDocStreamCompleteState | AskDocStreamErrorState | AskDocStreamAbortedState;

type DocExpectedMessages = AskDocStreamMessages | EventSourceActions;

export function askDocStateReducer(state: AskDocStreamState, action: DocExpectedMessages): AskDocStreamState {
  if (action.type === 'EventSourceError' || action.type === 'error') {
    return errorState(state);
  }

  if (state.state === 'initiated') {
    return initiatedStateReducer(state, action);
  }

  if (state.state === 'answering') {
    return answeringStateReducer(state, action);
  }

  return state;
}

function errorState(state: AskDocStreamState): AskDocStreamErrorState {
  const answer = state.state === 'answering'
    ? state.answer
    : 'Something went wrong. Please try again.';

  return {
    ...state,
    state: 'error',
    answer,
    answerStreamingStartedTimestamp: Date.now(),
    answerStreamingEndedTimestamp: Date.now(),
    requestInitiatedTimestamp: state.requestInitiatedTimestamp,
  };
}

function initiatedStateReducer(state: AskDocStreamInitiatedState, action: DocExpectedMessages): AskDocStreamState {
  if (action.type === 'answer_chunk') {
    // Moving from initiated -> answering
    return {
      ...state,
      state: 'answering',
      answer: action.data,
      answerStreamingStartedTimestamp: Date.now()
    }
  }

  return state;
}

function answeringStateReducer(state: AskDocStreamAnsweringState, action: DocExpectedMessages): AskDocStreamState {
  if (action.type === 'answer_chunk') {
    return {
      ...state,
      answer: state.answer + action.data
    }
  }

  if (action.type === 'answer_complete') {
    return {
      ...state,
      state: 'complete',
      answer: state.answer,
      answerStreamingEndedTimestamp: Date.now()
    }
  }

  return state;
}
