import React, { createContext, PropsWithChildren, useContext } from 'react';
import { createUseContext } from '../../../utils/create-use-context';

type AnalyticsContextValue = Record<string, string>;

const GlobalAnalyticsContext = createContext<AnalyticsContextValue>({});
GlobalAnalyticsContext.displayName = 'GlobalAnalyticsContext';

type Props = {
  value: AnalyticsContextValue;
}

export function GlobalAnalyticsContextProvider(props: PropsWithChildren<Props>) {
  const currentContextValue = useContext(GlobalAnalyticsContext);

  const mergedContextValue = {
    ...currentContextValue,
    ...props.value
  };

  return (
    <GlobalAnalyticsContext.Provider value={mergedContextValue}>
      {props.children}
    </GlobalAnalyticsContext.Provider>
  );
}

export const useGlobalAnalyticsContext = createUseContext(GlobalAnalyticsContext);
