import React, { useCallback, useEffect } from 'react';

import { ThreadedAskStreamState } from '../../../ask-bluej/streaming/threaded/reducer';
import { useAskQuestionAnalytics } from '../../hooks/use-ask-question-analytics';
import { useConfigContext } from '../../../config/ConfigContext';
import { isNoAnswer } from '../../../ask-bluej';
import { IDK_COMPLETION_DELAY_MS } from './progress/shared';
import { QuestionInteractionType, QuestionPayload } from '../../../ask-bluej/streaming/threaded/shared'
import { useStreamingAskQuestion } from '../../../ask-bluej/streaming/threaded/useStreamingAskQuestion';

export default function ActiveQuestion(props: Props) {
  const {
    payload,
    onComplete,
    render: RenderComponent,
    interactionContext,
    onAbort,
    resubmitQuestion
  } = props;
  const { apiUrl } = useConfigContext();
  const state = useStreamingAskQuestion(payload, apiUrl, interactionContext);

  useAskQuestionAnalytics(payload, state);

  const handleStateUpdated = useCallback(async (state: ThreadedAskStreamState) => {
    if (state.state === 'complete' || state.state === 'error') {
      if (state.state === 'complete' && isNoAnswer(state.answer)) {
        // If we're getting an IDK we want to wait a bit before completing, otherwise things can flip really
        await new Promise((resolve) => setTimeout(resolve, IDK_COMPLETION_DELAY_MS));
      }
      onComplete(state);
    }
  }, [onComplete]);

  useEffect(() => {
    void handleStateUpdated(state);
  }, [state, handleStateUpdated]);

  return (
    <>
      <RenderComponent state={state} resubmitQuestion={resubmitQuestion} onAbort={onAbort} />
    </>
  );
}

type Props = {
  payload: QuestionPayload;
  render: React.ElementType<RenderProps>;
  onComplete: (state: ThreadedAskStreamState) => void;
  interactionContext: QuestionInteractionType | null;
  resubmitQuestion: (question: string, strategy?: string) => void;
  onAbort: (state: ThreadedAskStreamState) => void;
}

export type RenderProps = {
  state: ThreadedAskStreamState;
  resubmitQuestion: (question: string, strategy?: string) => void;
  onAbort: (state: ThreadedAskStreamState) => void;
}
