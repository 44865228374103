import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const scrubBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  switch (breadcrumb.category) {
    case 'navigation': {
      if (breadcrumb.data) {
        breadcrumb.data.to = breadcrumb.data.to.replace(/(\?question=).*?(&strategy=)/, '$1[Redacted]$2');
        breadcrumb.data.from = breadcrumb.data.from.replace(/(\?question=).*?(&strategy=)/, '$1[Redacted]$2');
      }
      return breadcrumb;
    }
    case 'ui.click': {
      if (breadcrumb.message) {
        breadcrumb.message = breadcrumb.message.replace(/(\[title="(?:Delete|Rename|Confirm Rename) Thread\s")[^"]*(""])/, '$1[Redacted]$2');
      }
      return breadcrumb;
    }
    default:
      return breadcrumb;
  }
}
