import { ThreadedAskStreamState } from '../../../ask-bluej/streaming/threaded/reducer';
import { ActiveQuestionView } from './index';
import { StreamingChatAbort } from './streaming-chat-abort';
import React from 'react';

type ActiveQuestionRenderProps = {
  state: ThreadedAskStreamState;
  resubmitQuestion: (question: string, strategy?: string) => void;
  onAbort: (state: ThreadedAskStreamState) => void;
}

export function ActiveQuestionRender(props: ActiveQuestionRenderProps) {
  const { state, resubmitQuestion, onAbort } = props;
  return (
    <>
      <ActiveQuestionView state={state} resubmitQuestion={resubmitQuestion} />
      <StreamingChatAbort state={state} onAbort={onAbort} />
    </>
  )
}
