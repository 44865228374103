import { Thread } from '@/types';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';

interface DeleteChatParams {
  threadId: string;
  id: string;
}

export function useChatDeleteQuery(threadId: string, chatId?: string) {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, DeleteChatParams>({
    mutationFn: async ({ threadId, id }: DeleteChatParams) => {
      return api.deleteChat(threadId, id);
    },
    onSuccess: () => {
      queryClient.setQueryData(['threads', threadId], (oldThread: InfiniteData<Thread> | undefined) => {
        return {
          ...oldThread,
          pages: oldThread?.pages.map((thread) => ({
            ...thread,
            chats: thread.chats.filter((chat) => chat.chat_id !== chatId)
          })) ?? [],
        };
      });
    }
  });

  function deleteChat(threadId: string, id: string) {
    return mutation.mutateAsync({ threadId, id });
  }

  return {
    deleteChat
  };
}
