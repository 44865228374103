import React, { PropsWithChildren } from 'react';

import { useFeatureFlag } from '../modules/core/feature-flag/context';
import { FlagKey, FlagValue } from '@/types';

/**
 * Wrap an experiment component with a feature flag check for visibility.
 *
 * @param props
 * @constructor
 */
export function ExperimentWrapper(props: PropsWithChildren<ExperimentWrapperProps>) {
  const { element, featureKey, defaultVisibility = false } = props;

  const isEnabled = useFeatureFlag(featureKey, defaultVisibility);

  if (isEnabled) {
    return element;
  }

  return props.children;
}

type ExperimentWrapperProps = {
  element: React.ReactNode;
  featureKey: FlagKey;
  defaultVisibility: FlagValue;
}