import { Link } from 'react-router-dom';
import React from 'react';

import TaxNotes from '../../../../assets/svg/taxnotes.svg?react';

type ExternalBadgeProps = {
  badge: string | null;
  href: string | null;
  onClick: () => void;
}

export function ExternalBadge({ badge, href, onClick }: ExternalBadgeProps) {
  if (!href || !badge) return null;

  const imageElement = badgeToImage[badge];

  if (!imageElement) return null;

  return (
    <Link
      title={badgeToTitle[badge]}
      className="inline-flex align-sub ml-2"
      to={backCompatUrl(href)}
      type="button"
      target="_blank"
      onClick={onClick}
      rel="noopener noreferrer"
    >
      { imageElement }
    </Link>
  )
}

// FIXME (cleanup) this is interim while we reingest content to the content store:
//    https://github.com/bluejlegal/ask-bluej-content-management/commit/0924265205f2b9a962022424641281b8fe94508c
export const backCompatUrl = (href?: string) => {
  if (!href) return '';
  return (href.toLowerCase().includes('taxnotes') && !href.includes('src=abj1')) ? `${href}?src=abj1` : href;
}

const badgeToImage: Record<string, React.JSX.Element> = {
  'taxnotes': <TaxNotes className="h-4"/>
}

const badgeToTitle: Record<string, string> = {
  'taxnotes': 'Tax Notes'
}
