import { useGlobalAnalyticsContext } from '../../contexts/GlobalAnalyticsContext';

interface ChildrenRenderProps {
  url: string;
}

type SourceLinkProps = {
  children: (props: ChildrenRenderProps) => JSX.Element;
  number: number;
  additionalSource: boolean;
}

export function SourceLink(props: SourceLinkProps) {
  const { number, additionalSource, children } = props;
  const { threadId, chatId } = useGlobalAnalyticsContext();
  const url = `/${threadId}/chat/${chatId}/${additionalSource ? 'additional-sources' : 'sources'}/${number}`;
  return children({ url });
}
