import React from 'react';

interface PlaceholderProps {
  children: React.ReactElement;
  count: number;
}

export const Placeholder = ({ children, count }: PlaceholderProps) => {
  return (
    <>
      { Array.from(Array(count).keys()).map((_value, index) => {
        return React.cloneElement(children, { key: index })
      }) }
    </>
  )
}
