import { SourceView, SourceViewListing } from '@/types';
import { Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { QueryStatus } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '../../../../components/button';
import { AskDocInteractionType } from '../../../ask-bluej/streaming/doc/shared';
import { AskDocument } from '../../../ask-doc/components/ask-doc';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { replaceLinks } from '../../../source/utils/replace-links';
import { useSharedScrollEventListener } from '../../hooks/useSharedScrollEventListener';
import { SourceHighlighter } from '../../source-highlighter/source-highlighter';
import { Placeholder } from '../placeholder';
import { PrintSource } from './print-source';
import { SourceDescription } from './source-description';
import { SourceHeader } from './source-header';
import { usePrintSourceData } from './usePrintSourceData';
import { RestrictedSourceInteractionContext } from '../../types';
import { ExternalLinkClickTracker } from '../../../source/components/source-highlighter/external-link-click-tracker';
import { backCompatUrl } from './external-badge';

interface SourceViewProps {
  successComponent?: React.ElementType;
  sourceViewListing: SourceViewListing;
  sourceNavigationComponent?: React.ReactElement;
  additionalSources?: boolean;
}

export function SourcesView(props: SourceViewProps) {
  const {
    successComponent: SuccessComponent = SourcesViewSuccessComponent,
    sourceViewListing,
    sourceNavigationComponent,
    additionalSources = false
  } = props;
  const { trackSourceOpenInNewTabClicked, trackSourcePrintClicked, trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();

  const { badge, type, content, href, id, externalUrl, title } = sourceViewListing;

  const resolvedHref = backCompatUrl(externalUrl ?? href);
  const {
    printComponentRef,
    handlePrint,
    sourceContent,
    sourceContentStatus,
    sourceView
  } = usePrintSourceData(sourceViewListing);

  const headerClasses = clsx('flex mb-2', {
    'justify-between': sourceNavigationComponent,
    'justify-end': !sourceNavigationComponent,
  });

  const handleOpenSource = useCallback(() => {
    if (externalUrl) {
      trackRestrictedSourceOpenInNewTabClicked({
        sourceType: badge,
        sourceTitle: title,
        interactionContext: RestrictedSourceInteractionContext.SOURCE_PANE_TOP_BUTTON,
        referredUrl: externalUrl,
        additionalSources
      });

      return;
    }

    trackSourceOpenInNewTabClicked({
      sourceType: type,
      sourceTitle: title,
      additionalSources
    });
  }, [additionalSources, badge, externalUrl, href, title]);

  return (
    <>
      <div className="flex flex-col overflow-hidden w-full">
        <div className="px-6 pt-6">
          <div className={headerClasses}>
            {sourceNavigationComponent}
            <SourcePanelNavigationButtons
              id={id}
              type={type}
              href={resolvedHref}
              handlePrint={() => {
                trackSourcePrintClicked({ sourceType: type, sourceTitle: title, additionalSources });
                setTimeout(() => handlePrint(), 1);
              }}
              showPrintButton={!!sourceViewListing && !sourceViewListing.externalUrl}
              handleOpenSource={handleOpenSource}
            />
          </div>
        </div>
        <SuccessComponent
          highlightContent={content}
          sourceView={sourceView}
          sourceViewListing={sourceViewListing}
          href={sourceViewListing.href}
          sourceContentStatus={sourceContentStatus}
          additionalSources={additionalSources}
        />
      </div>
      <PrintSource
        ref={printComponentRef}
        sourceContent={sourceContent}
        highlightContent={content}
        sourceView={sourceView}
      />
    </>
  )
}

type SourcePanelNavigationProps = {
  type: SourceView['type'];
  id: SourceView['id'];
  href: string;
  handlePrint: () => void;
  showPrintButton?: boolean;
  handleOpenSource: () => void;
}

function SourcePanelNavigationButtons({
  handlePrint,
  showPrintButton,
  handleOpenSource,
  href
}: SourcePanelNavigationProps) {
  return (
    <div className="flex mr-5 items-center">
      {showPrintButton && (
        <Button
          className="hidden lg:block mr-2 text-grey-600 hover:text-black-600"
          colour="secondary"
          onClick={handlePrint}
          title="Print"
          variant="icon"
        >
          <PrinterIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true"/>
        </Button>
      )}
      <Link
        title="Open source in new tab"
        type="button"
        target="_blank"
        className="transition-colors rounded-md text-grey-600 hover:text-blue-400 focus:outline-none focus-visible:ring-2 mr-2"
        to={href}
        onClick={handleOpenSource}
        rel="noopener noreferrer"
      >
        <span className="sr-only">Open source in new tab</span>
        <ArrowTopRightOnSquareIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true"/>
      </Link>
    </div>
  )
}

interface SourceViewSuccessProps {
  highlightContent: string;
  sourceView: SourceView;
  sourceViewListing: SourceViewListing;
  href: string;
  sourceContentStatus: QueryStatus;
  successComponent?: React.ElementType<SourceViewSuccessComponentProps>;
  errorComponent?: React.ElementType;
  loadingComponent?: React.ElementType;
  additionalSources: boolean;
}

function SourcesViewSuccessComponent(props: SourceViewSuccessProps) {
  const {
    sourceView,
    sourceViewListing,
    href,
    highlightContent,
    sourceContentStatus,
    successComponent: SuccessComponent = SourceViewSuccessComponent,
    loadingComponent: LoadingComponent = SourceViewLoadingComponent,
    additionalSources
  } = props;

  if (sourceContentStatus === 'error') {
    throw new Error('Error loading source content');
  }

  if (sourceContentStatus === 'pending' || sourceView === undefined) {
    return (
      <>
        <SourceHeader sourceData={sourceViewListing} additionalSources={additionalSources} />
        <LoadingComponent/>
      </>
    )
  }

  return (
    <SuccessComponent
      highlightContent={highlightContent}
      sourceView={sourceView}
      additionalSources={additionalSources}
      href={href}
    />
  )
}

interface SourceViewSuccessComponentProps {
  sourceView: SourceView;
  highlightContent: string;
  additionalSources: boolean;
  href: string;
}

function SourceViewSuccessComponent(props: SourceViewSuccessComponentProps) {
  const { sourceView, highlightContent,  additionalSources, href } = props;
  const { trackRestrictedSourceOpenInNewTabClicked, trackSourceViewed } = useAnalyticsEvent();
  const { badge, externalUrl, title, type } = sourceView;
  const { ref, emitter, skipNextEmit } = useSharedScrollEventListener();
  const { state } = useLocation();
  const [isQuickSummarize, setIsQuickSummarize] = useState(state?.context === 'quick-summarize');

  // TODO (backwards-compat) clean this up
  const tempRestrictedSourceClickHandler = useCallback((referredUrl: string) => {
    trackRestrictedSourceOpenInNewTabClicked({
      sourceType: badge,
      sourceTitle: title,
      interactionContext: RestrictedSourceInteractionContext.VIEW_IN_TAX_NOTES, // TODO There is only one, this could be wrong in the future
      referredUrl,
      additionalSources
    });
  }, [badge, title, type, externalUrl, href, additionalSources ]);

  useEffect(() => {
    if (isQuickSummarize) {
      const stateCopy = { ...state };
      delete stateCopy.context;
      window.history.replaceState({ state: stateCopy }, '');
    }
  }, [state]);

  useEffect(() => {
    // FIXME this also needs to track an "interactionContext" - whether the user is viewing from a link click or from paging through documents
    //  These field names are drifting a bit
    trackSourceViewed({ sourceTitle: title, sourceType: badge, contentType: type, additionalSources });
  }, [sourceView?.id]);

  const description = renderToString(<SourceDescription data={sourceView} />);
  const content = description ? `<div>${description}${sourceView?.content}</div>` : (sourceView?.content ?? '');

  const initialState = localStorage.getItem(`ask-${AskDocInteractionType.PANE}-tooltip`) !== 'true';
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  if (!sourceView) {
    return null;
  }

  return (
    <>
      <SourceHeader
        sourceData={sourceView}
        additionalSources={additionalSources}
        emitter={emitter}
      />
      <div className="p-6 break-words lg:break-normal flex-1 scroll-pt-8 overflow-y-scroll" ref={ref}>
        <div className="prose [&_*]:custom-prose-sm prose-pre:whitespace-pre-wrap max-w-full prose-custom-links [&_*]:!font-sans">
          <ExternalLinkClickTracker onExternalLinkClicked={tempRestrictedSourceClickHandler}>
            <SourceHighlighter
              content={highlightContent}
              source={content}
              onContentReady={replaceLinks}
              skipNextEmit={skipNextEmit}
            />
          </ExternalLinkClickTracker>
        </div>
      </div>
      {sourceView && (
        <>
          <div className="bg-transparent h-[72px]"/>
          <Transition
            appear={true}
            show={true}
            enter="transition transform ease-in duration-700"
            enterFrom="translate translate-y-full"
            enterTo="translate translate-y-0"
            afterEnter={() => setTooltipVisible(initialState)}
          >
            <div className="fixed right-0 bottom-0 w-full max-w-[1000px] lg:w-[50vw] border-t-2 border-blue-400 bg-gray-50">
              <AskDocument
                content={content}
                interactionContext={isQuickSummarize ? AskDocInteractionType.QUICK_SUMMARIZE : AskDocInteractionType.PANE}
                sourceView={sourceView}
                setIsQuickSummarize={setIsQuickSummarize}
                docQuestionTooltip={{ isTooltipVisible, setTooltipVisible }}
                additionalSources={additionalSources}
              />
            </div>
          </Transition>
        </>
      )}
    </>
  )
}

export function SourceViewLoadingComponent() {
  return (
    <div className="p-6 animate-pulse">
      <div className="grid gap-2 grid-cols-1">
        <div className="w-60">
          <div className="rounded-sm h-4 bg-slate-200"/>
        </div>
        <div className="w-80">
          <div className="rounded-sm h-4 bg-slate-200"/>
        </div>
        <Placeholder count={3}>
          <div className="rounded-sm h-4 bg-slate-200"/>
        </Placeholder>
        <div className="mt-12 grid gap-3 grid-cols-1">
          <Placeholder count={20}>
            <div className="rounded-sm h-4 bg-slate-200"/>
          </Placeholder>
        </div>
      </div>
    </div>
  )
}
