import { useQuery } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { spanEventArrayToTree } from './span-event-array-to-tree';

const POLLING_INTERVAL = 2100;

export function useTraceQuery(chatId: string) {
  const { api } = useAskBluejApiContext();

  return useQuery({
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryKey: ['traces', chatId],
    queryFn: async () => {
      const spanEvents = await api.getTrace(chatId);
      return {
        tree: spanEventArrayToTree(spanEvents),
        spans: spanEvents
      };
    },
    refetchInterval: (query => {
      const { data } = query.state;

      if (data && data.spans && data.spans.some((span) => span.parentId === null)) {
        return false;
      }

      return POLLING_INTERVAL;
    })
  })
}
