import React, { PropsWithChildren } from 'react';

import BlueJ from '../../../../assets/svg/bluej.svg?react'

export function QuestionInitiated({ children }: PropsWithChildren) {
  return (
    <div className="flex-row gap-6 w-full max-w-5xl lg:flex">
      <div className="mb-1 flex justify-between items-center lg:block -mr-3">
        <div className="relative flex justify-center items-center rounded-full text-grey-600">
          <div className="transform -translate-x-[0.1rem] -translate-y-[0.1rem] absolute flex bg-white rounded-full origin-center md:w-11 md:h-11 w-11 h-11 animate-response-ping ring"></div>
          <div className="w-12 h-12 z-10 rounded-full">
            <BlueJ />
          </div>
        </div>
      </div>
      <div className="w-full mt-0">
        <div className="flex-col">
          { children }
        </div>
      </div>
    </div>
  )
}
