import React, { Suspense } from 'react';

import { useIsUserAdmin } from './modules/core/user/context';
import { useConfigContext } from './modules/config/ConfigContext';
import { ConditionalRoute } from './utils/conditional-route';

const LazyAdminRoutingWrapper = React.lazy(() => import('./modules/admin'));

export function AdminRoutingWrapper() {
  const isAdmin = useIsUserAdmin();
  const { apiUrl } = useConfigContext();
  return (
    <ConditionalRoute predicate={isAdmin}>
      <Suspense fallback={null}>
        <LazyAdminRoutingWrapper apiUrl={apiUrl}/>
      </Suspense>
    </ConditionalRoute>
  );
}
