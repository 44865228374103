import { Chat } from '@/types';
import { withErrorBoundary } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { QuestionInteractionType } from '../../ask-bluej/streaming/threaded/shared';
import { convertMarkdownToText } from '../../ask-doc/extract';
import { useAskBluejApiContext } from '../../core/api/ask-bluej-api';
import { useShowStrategyDropdown } from '../../core/feature-flag/features/use-show-strategy-dropdown';
import { useSentryContext } from '../../core/sentry/context';
import { useStrategyContext } from '../contexts/StrategyContext';
import { useThreadContext } from '../contexts/ThreadContext';

type FollowUpQuestionsProps = {
  show: boolean;
  handleSelectQuestion: (question: string, interactionContext: QuestionInteractionType, strategy?: string) => void;
  chat: Chat | null;
}
export const FollowUpQuestions = withErrorBoundary((props: FollowUpQuestionsProps) => {
  const { show, handleSelectQuestion, chat } = props;
  const { api } = useAskBluejApiContext();
  const { sentry } = useSentryContext();
  const { thread } = useThreadContext();
  const { selectedStrategy } = useStrategyContext();
  const showStrategyDropdown = useShowStrategyDropdown();
  const [questions, setQuestions] = useState<string[]>([]);

  const handleOnClick = useCallback((question: string) => {
    let currentStrategy = '';
    if (showStrategyDropdown) {
      currentStrategy = selectedStrategy || ''
    }
    handleSelectQuestion(question, QuestionInteractionType.FOLLOW_UP, currentStrategy);
  }, [showStrategyDropdown, selectedStrategy, thread.topic]);

  useEffect(() => {
    setQuestions([]);
    if (show && chat !== null) {
      api.recommendFollowUp(thread.id, chat.question, chat.answer, chat.meta?.country ?? 'US').then((response) => {
        setQuestions(response.filter((question) => !question.toLowerCase().includes('sorry')));
      }).catch(() => {
        sentry.reportMessage(`Failed to recommend follow up questions for thread "${ thread.id }"`, {
          threadId: thread.id,
        });
        setQuestions([]);
      });
    }
  }, [thread.id, chat?.chat_id, show]);

  if (!show || questions.length === 0 || !thread.chats?.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 gap-2 gap-x-6">
      <div className="col-span-2 text-gray-500 text-sm">
        Suggested follow-up questions
      </div>
      { questions.slice(0,2).map((question, index) => (
        <button
          key={ index }
          title="Ask follow-up question"
          className="col-span-1 p-2 rounded border border-gray-300 hover:border-blue-400"
          onClick={() => handleOnClick(question)}
        >
          <div className="text-gray-800 text-sm text-left">{ convertMarkdownToText(question) }</div>
        </button>
      )) }
    </div>
  )
}, {});
