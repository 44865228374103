import { Thread } from '@/types';
import React from 'react';
import { createUseContext } from '../../../utils/create-use-context';

export type ThreadContextValue = {
  thread: Thread;
}

const ThreadContext = React.createContext<ThreadContextValue | null>(null);
ThreadContext.displayName = 'ThreadContext';

export const ThreadContextProvider = ThreadContext.Provider;
export const useThreadContext = createUseContext(ThreadContext);
