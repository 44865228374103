import { useEffect } from 'react';

export function useScrollToHash<TDeps extends unknown[]>(deps: TDeps) {
  useEffect(() => {
    const { hash } = window.location;

    if (hash) {
      const element = document.getElementById(hash.slice(1));

      if (element) {
        element.scrollIntoView();
      }
    }
  }, deps);
}
