import { PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import '../../../index.css';

export function AppLayout(props: PropsWithChildren) {
  const { children } = props;
  // TODO: We can lift some layout from admin/primary into here to share it
  //   - Move layout wrapper here <div className="flex h-dynamic-full">
  return (
    <>
      { children }
      <ToastContainer />
    </>
  )
}
