import { Link } from "react-router-dom";
import React from "react";
import NotFoundImage from '../../../assets/svg/not-found.svg?react';

export function NotFound() {
  return (
    <div className="flex flex-col items-center">
      <Link to="/">
        <NotFoundImage className="mt-20 mb-10" />
      </Link>
      <div>
        Sorry, that content cannot be found.
      </div>
    </div>
  );
}
