import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ArrowLongRightIcon, CheckIcon } from '@heroicons/react/20/solid';

import { useWelcomeMessage, useShowWelcomeMessage } from '../../core/feature-flag/features/use-welcome';
import { weekAgo } from '../utils';
import { Button } from '../../../components/button';

const WELCOME_STORAGE_KEY = '__ASK_BLUEJ_WELCOME_MESSAGE_SEEN__';

type ModalButtonProps = {
  index: number;
  onClick: () => void;
}

const Header3 = ({ children }: PropsWithChildren) => {
  return (
    <div className="font-semibold text-xl">
      { children }
    </div>
  )
}

const Header4 = ({ children }: PropsWithChildren) => {
  return (
    <div className="font-semibold">
      { children }
    </div>
  )
}

const UnorderedList = ({ children }: PropsWithChildren) => {
  return (
    <ul className="list-disc ml-6">
      { children }
    </ul>
  )
}

const ModalButton = ({ index, onClick }: ModalButtonProps) => {
  const className = 'flex h-8 w-24 justify-around items-center rounded-lg border text-sm font-medium px-2.5 py-1 focus:z-10';

  if (index === 0) {
    return (
      <Button className={className} colour="primary" onClick={onClick} title="Next" variant="icon">
        Next
        <ArrowLongRightIcon className="w-5" />
      </Button>
    )
  }

  return (
    <Button className={className} colour="primary" onClick={onClick} title="Got It!" variant="icon">
      Got It!
      <CheckIcon className="w-5 h-4" />
    </Button>
  )
}

export const WelcomeModal = () => {
  const { one, two } = useWelcomeMessage();
  const showWelcome = useShowWelcomeMessage();
  const [visible, setVisible] = useState<boolean>(showWelcome);
  const [messageIndex, setMessageIndex] = useState<number>(0);
  const handleClick = useCallback(() => {
    if (messageIndex === 0) {
      setMessageIndex(1);
    } else {
      localStorage.setItem(WELCOME_STORAGE_KEY, Date.now().toString());
      setVisible(false);
    }
  }, [messageIndex]);

  const messages = [one, two];
  const currentMessage = messages[messageIndex];
  const expiry = localStorage.getItem(WELCOME_STORAGE_KEY);

  useEffect(() => {
    setVisible(showWelcome);
  }, [showWelcome]);

  if (!visible || (expiry !== null && !weekAgo(parseInt(expiry)))) {
    return null;
  }

  return (
    <div tabIndex={-1} aria-hidden={!visible} className="backdrop-brightness-50 fixed z-50 inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center text-center p-4">
        <div className="relative overflow-hidden text-left sm:my-8 max-w-2xl bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-6 space-y-6">
           <ReactMarkdown
            components={{
              h3: Header3,
              h4: Header4,
              ul: UnorderedList
            }}>
             { currentMessage }
           </ReactMarkdown>
          </div>
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <ModalButton index={messageIndex} onClick={handleClick} />
          </div>
        </div>
      </div>
    </div>
  )
}
