import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import React, { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from '../../../../components/button';
import { Tip } from './constants';

type PromptTipsModalProps = {
  handleClose: () => void;
  show: boolean;
  tip: Tip;
}

export const Header1 = ({ children }: PropsWithChildren) => {
  return (
    <div className="font-semibold text-sm mb-4 relative">
      <SparklesIcon className="h-6 w-6 absolute -left-8 text-blue-500"/>
      { children }
    </div>
  )
}

export const Header2 = ({ children }: PropsWithChildren) => {
  return (
    <div className="text-sm mb-2">
      { children }
    </div>
  )
}

export const BlockQuote = ({ children }: PropsWithChildren) => {
  return (
    <div className="mb-3 border-grey-400 border rounded text-sm p-2 bg-white">
      { children }
    </div>
  )
}

const getMarkdownForTip = (tip: Tip) => `
# ${tip.title}
## Question:
> ${tip.prompt}
## Try instead:
${tip.examplePrompts?.map(prompt => '> ' + prompt).join('\n\n')}
`;

const getMarkdownForTipWithoutPrompt = (tip: Tip) => `
# Try asking:
${tip.examplePrompts?.map(prompt => '> ' + prompt).join('\n\n')}
`;

export function PromptTipsModal(props: PromptTipsModalProps) {
  const { tip, show, handleClose } = props;
  return (
    <Transition show={show}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <DialogPanel
                className=" max-w-xl relative transform overflow-hidden rounded-lg bg-grey-200 pr-8 pl-12 pb-4 pt-5 text-left shadow-xl transition-all">
                <div className="absolute right-2 top-2">
                  <Button
                    colour="secondary"
                    onClick={handleClose}
                    title="Close modal"
                    variant="icon"
                    className="w-6 h-6"
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="text-blue-500 inline-block" aria-hidden="true"/>
                  </Button>
                </div>
                <ReactMarkdown
                  components={{
                    h1: Header1,
                    h2: Header2,
                    blockquote: BlockQuote
                  }}
                >
                  {tip.prompt ? getMarkdownForTip(tip) : getMarkdownForTipWithoutPrompt(tip)}
                </ReactMarkdown>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
