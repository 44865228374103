import { ResponseError } from '../core/api/api';
import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useSentryContext } from '../core/sentry/context';

export function useThreadResolver() {
  const { api } = useAskBluejApiContext();
  const { sentry } = useSentryContext();

  return async function (threadId: string) {
    // only need the thread id, so fetching minimal chat info
    const thread = await api.getThread(threadId, 1, 1).catch((error) => {
      if (error instanceof ResponseError && error.status !== 401) {
        sentry.reportMessage('Failed to resolve thread', { threadId });
      }
      return null;
    });

    return thread?.id ?? '';
  }
}
