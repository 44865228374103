import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import AutosizingTextarea from 'react-textarea-autosize';

import { Button } from '../../../components/button';
import { AskDocInteractionType } from '../../ask-bluej/streaming/doc/shared';
import { useHandleEnterKeySubmit } from '../../thread/hooks/useHandleEnterKeySubmit';
import { useDocTooltip } from '../use-doc-tooltip';
import { DocTooltip } from './doc-tooltip';

type Props = {
  disabled?: boolean;
  defaultInput?: string;
  onSubmit: (question: string) => Promise<boolean>;
  interactionContext: AskDocInteractionType;
  docQuestionTooltip: {
    isTooltipVisible: boolean;
    setTooltipVisible: (visible: boolean) => void;
  }
}

export function DocQuestionInput(props: Props) {
  const { onSubmit, interactionContext, docQuestionTooltip, defaultInput, disabled } = props;
  const { isTooltipVisible, setTooltipVisible } = docQuestionTooltip;

  const [waitingForSubmit, setWaitingForSubmit] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 400);
  }, [inputRef]);

  const submit = () => {
    if (!inputRef.current || inputRef.current.value.trim() === '') {
      return;
    }

    if (showTooltip) {
      localStorage.setItem(`ask-${interactionContext}-tooltip`, 'true');
      setTooltipVisible(false);
    }

    setWaitingForSubmit(true);

    // Prevent onscreen keyboard from popping up
    inputRef.current.readOnly = true;
    inputRef.current.focus();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.readOnly = false;
      }
    }, 0);

    onSubmit(inputRef.current.value).then((success) => {
      setWaitingForSubmit(false);
      // if the question was submitted correctly, clear the question
      if (success && inputRef.current) {
        inputRef.current.value = '';
      }
    });
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submit();
  }

  const handleKeyDown = useHandleEnterKeySubmit({
    onSubmit: submit
  });

  const questionWrapperStyle = clsx('flex items-center p-4 max-w-5xl mx-auto', {
    'border border-grey-400 shadow-md rounded-t-lg gap-4 bg-grey-100 focus-within:ring-2 focus-within:ring-blue-400': interactionContext === AskDocInteractionType.PAGE,
  });

  const {
    tooltipRefs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps
  } = useDocTooltip({ isTooltipVisible, setTooltipVisible, interactionContext });
  const showTooltip = isTooltipVisible && !disabled;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          ref={tooltipRefs.setReference}
          {...getReferenceProps()}
          className={questionWrapperStyle}
        >
          <div className="flex-1">
            <div className="overflow-hidden group rounded-2xl">
              <AutosizingTextarea
                ref={inputRef}
                maxRows={6}
                onKeyDown={handleKeyDown}
                placeholder="Ask a question about this source"
                className="block w-full focus:ring-0 focus:outline-0 border-0 bg-transparent max-h-40 min-h-[40px] resize-none"
                name="query"
                defaultValue={defaultInput ?? ''}
                readOnly={disabled}
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <Button
              className="text-white py-0.5 px-1"
              colour="primary"
              disabled={disabled || waitingForSubmit}
              title="Ask question"
              type="submit"
              variant="icon"
            >
              <ArrowLongRightIcon className="w-8 h-8 stroke-current stroke-[0.75]" aria-hidden="true"/>
            </Button>
          </div>
        </div>
      </form>
      {showTooltip && (
        <div
          ref={tooltipRefs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <DocTooltip interactionContext={interactionContext} setTooltipVisible={setTooltipVisible}/>
        </div>
      )}
    </>
  );
}
