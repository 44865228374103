import { fetchEventSource } from '@microsoft/fetch-event-source';
import { IEventSource } from '@bluejlegal/event-source-reducer';

const PREVENT_RECONNECT = 'Prevent reconnection';

export class FetchEventSourceAdapter implements IEventSource {
  readonly CONNECTING: number = 0;
  readonly OPEN: number = 1;
  readonly CLOSED: number = 2;

  onmessage: null | ((event: MessageEvent<string>) => void) = null;
  onopen: null | ((ev: Event) => void) = null;
  onerror: null | ((ev: Event) => void) = null;
  readyState: number = this.CONNECTING;

  private abortController = new AbortController();

  constructor(url: string, body: string, headers: Record<string, string>, supportReconnects: boolean) {
    this.initialize(url, body, headers, supportReconnects);
  }

  private initialize(url: string, body: string, headers: Record<string, string>, supportReconnects: boolean) {
    void fetchEventSource(url, {
      credentials: 'include',
      body,
      method: 'POST',
      mode: 'cors',
      headers,
      openWhenHidden: !supportReconnects,
      onmessage: (event) => {
        if (this.onmessage) {
          this.onmessage(event as unknown as MessageEvent<string>);
        }
      },
      signal: this.abortController.signal,
      onerror: (event) => {
        if (this.readyState !== this.CLOSED) {
          if (supportReconnects) {
            this.readyState = this.CONNECTING;
          } else {
            this.readyState = this.CLOSED;
          }
        }

        if (this.onerror) {
          this.onerror(event);
        }

        if (!supportReconnects) {
          // according to fetchEventSource, we need to throw an error
          // in this handler in order to prevent re-connection
          throw new Error(PREVENT_RECONNECT, { cause: event });
        }
      },
      onopen: async (response) => {
        if (response.ok) {
          this.readyState = this.OPEN;

          if (this.onopen) {
            this.onopen({} as Event);
          }
        } else {
          // we expect this to trigger our onerror handler above
          const cause = { statusCode: response.status };
          throw new Error('Unexpected response code', { cause });
        }
      }
    }).catch((err) => {
      // The catch appears to only be called when onerror throws.
      // We are intentionally throwing 'Prevent reconnection' above, so we'll
      // let that pass. We don't depend on this catch handler for anything else.
      if (!(err instanceof Error && err.message === PREVENT_RECONNECT)) {
        // We don't actually expect these to happen, but we'll re-throw so Sentry can see them
        throw err;
      }
    });
  }

  close(): void {
    this.abortController.abort();
    this.onmessage = null;
    this.onopen = null;
    this.onerror = null;
  }
}
