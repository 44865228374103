import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

type PredicateFn = () => boolean;

type ConditionalRouteProps = React.PropsWithChildren<{
  predicate: PredicateFn | boolean;
}>;

export function ConditionalRoute({ children, predicate }: ConditionalRouteProps) {
  const navigate = useNavigate();
  const result = typeof predicate === 'boolean' ? predicate : predicate();
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (result) {
      setRender(true);
    } else {
      navigate('/')
    }
  }, [result]);

  if (render) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
}
