import { MinusCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import Progress from '../thread/components/active-question-view/progress/progress';
import { PROGRESS_BAR_COMPLETE_ANIMATION_MS } from '../thread/components/active-question-view/progress/shared';
import { Source } from '../thread/components/sources/source';
import { useGlobalAnalyticsContext } from '../thread/contexts/GlobalAnalyticsContext';
import { useAdditionalSourcesQuery } from './useAdditionalSourcesQuery';

type LoadingComponentProps = {
  status: 'pending' | 'success' | 'error';
  onComplete: () => void;
}

const LoadingComponent = ({ status, onComplete }: LoadingComponentProps) => {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increase the progress bar by 10% every second until it reaches 80%, or until the status changes from 'pending'
      // to 'success' or 'error', at which point the progress bar will animate to 100%
      setProgress((prevProgress) => {
        if (status === 'pending') {
          if (prevProgress < 80) return prevProgress + 10;
          return prevProgress;
        } else if (prevProgress < 100) {
          return 100;
        } else {
          clearInterval(interval);
          onComplete();
          return prevProgress;
        }
      });
    }, PROGRESS_BAR_COMPLETE_ANIMATION_MS);

    return () => clearInterval(interval);
  }, [status, onComplete]);

  return (
    <Progress progress={progress} step="Loading more sources" />
  );
};

type SuccessComponentProps = PropsWithChildren<{
  header: string;
  handleShowAdditionalSources: () => void;
}>;

const SuccessComponent = ({ header, handleShowAdditionalSources, children }: SuccessComponentProps) => {
  const { trackViewLessSourcesClicked } = useAnalyticsEvent();
  const headerStyles = clsx('mb-4 text-gray-600', { 'text-sm': children });

  const handleFewerSourcesClick = () => {
    trackViewLessSourcesClicked();
    handleShowAdditionalSources();
  }

  return (
    <>
      <div className={headerStyles}>{header}</div>
      {children}
      <div className="mt-4 flex text-blue-400 text-sm cursor-pointer" onClick={handleFewerSourcesClick}>
        <MinusCircleIcon className="size-5" />
        View fewer sources
      </div>
    </>
  );
};

type AdditionalSourcesListProps = {
  handleShowAdditionalSources: () => void;
}

export const AdditionalSourcesList = ({ handleShowAdditionalSources }: AdditionalSourcesListProps) => {
  const { threadId, chatId } = useGlobalAnalyticsContext();
  const { trackViewMoreSourcesClicked } = useAnalyticsEvent();
  const { data, status } = useAdditionalSourcesQuery(threadId, chatId);

  const [progressComplete, setProgressComplete] = useState(status !== 'pending');


  useEffect(() => {
    if (progressComplete) {
      trackViewMoreSourcesClicked({ sources: data ?? [] });
    }
  }, [data, progressComplete]);

  if (!progressComplete) {
    return <LoadingComponent status={status} onComplete={() => setProgressComplete(true)} />;
  }

  if (status === 'error') {
    return (
      <SuccessComponent
        header="Error getting additional sources"
        handleShowAdditionalSources={handleShowAdditionalSources}
      />
    );
  }

  if (!data || data?.length === 0) {
    return (
      <SuccessComponent
        header="No additional sources found"
        handleShowAdditionalSources={handleShowAdditionalSources}
      />
    );
  }

  return (
    <SuccessComponent
      header="Additional sources that may be relevant"
      handleShowAdditionalSources={handleShowAdditionalSources}>
      <div className="min-w-full text-grey-600 text-sm overflow-hidden">
        <div className="grid gap-y-2">
          {data.map((source, index) =>
            <Source
              source={source}
              index={index + 1}
              key={source.id}
              additionalSource={true}
            />
          )}
        </div>
      </div>
    </SuccessComponent>
  )
}
