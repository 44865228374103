import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useSourceApiContext } from './contexts/SourceApiContext';
import { SourceView } from '@/types';

export type SourceContentQueryProps = {
  type: string;
  id: string;
}

export function useSourceContentQuery(source: SourceContentQueryProps): UseQueryResult<SourceView> {
  const { api } = useSourceApiContext();
  return useQuery({
    queryKey: ['sources', source.type, source.id],
    queryFn: async () => {
      // remove trailing hash from id
      const strippedId = source.id.split('#')[0];
      return api.getSourceContent(strippedId, source.type).then((response) => {
        // FIXME: Currently using strippedId since content-management is returning a uuid incompatible with FE
        //  Not sure I understand this comment. I don't think there's a connection between "strippedId" and CAM uuids
        //  I'm also confused why our ids have a trailing "#" at all. Worth investigating.
        return { ...response, id: strippedId };
      });
    },
    staleTime: Infinity,
    gcTime: Infinity
  });
}
