import { Thread } from '@/types';

export type FeedbackType = 'agree' | 'disagree';

export interface FeedbackPayload {
  chatId: string;
  threadId: string;
  comment?: string;
  environment: string;
  type: FeedbackType;
}

export type UpdatableThreadProperties = Pick<Thread, 'title'>;

export enum FaqInteractionContext {
  MENU_BAR = 'menu-bar',
  EXPECTATION_MESSAGE = 'expectation-message'
}

export enum RestrictedSourceInteractionContext {
  QUESTION_SOURCE_LIST = 'question-source-list',
  SOURCE_PANE_TOP_BUTTON = 'source-pane-top-button',
  VIEW_IN_TAX_NOTES = 'view-in-taxnotes'
}
