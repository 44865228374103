import { useFeatureFlag } from '../context';

export function useShowWelcomeMessage(): boolean {
  return useFeatureFlag('show-welcome-message', false);
}

export function useWelcomeMessage(): { one: '', two: '' } {
  return useFeatureFlag('welcome-message', {
    one: '',
    two: ''
  });
}
