import React, { PropsWithChildren } from 'react';
import { createUseContext } from '../../../utils/create-use-context';

export type MobileHeaderContextValue = {
  toggleThreadMenuVisibility: () => void;
  threadMenuVisible: boolean;
}

export const MobileHeaderContext = React.createContext<MobileHeaderContextValue | null>(null);
MobileHeaderContext.displayName = 'MobileHeaderContext';

export function MobileContextProvider(props: PropsWithChildren) {
  const [threadMenuVisible, setThreadMenuVisible] = React.useState(false);
  const mobileContextValue = {
    toggleThreadMenuVisibility: () => {
      setThreadMenuVisible(!threadMenuVisible)
    },
    threadMenuVisible
  }
  return (
    <MobileHeaderContext.Provider value={mobileContextValue}>
      {props.children}
    </MobileHeaderContext.Provider>
  );
}

export const useMobileHeaderContext = createUseContext(MobileHeaderContext);
