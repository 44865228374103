
export type AskDocStreamMessages =
  | { type: 'answer_chunk'; data: string; }
  | { type: 'answer_complete'; data: null; }
  | { type: 'request_complete'; data: null; }
  | { type: 'request_error'; data: null; }
  | { type: 'error'; data: null; } // string maybe?

export const AskDocStreamMessageTypes: Set<AskDocStreamMessages['type']> = new Set([
  'answer_chunk' as const,
  'answer_complete' as const,
  'request_complete' as const,
  'request_error' as const,
  'error' as const
]);
