import { Match } from 'approx-string-match';

import { matchQuote } from './match';
import { NodeType, REGEX_WHITESPACE, REGEX_WHITESPACES } from './constants';

export class Highlighter {
  private readonly body: HTMLBodyElement;
  private readonly content: string;
  private readonly _document: Document;
  /**
   *
   * @param source The entire document containing text to be highlighted
   * @param content The text to be highlighted
   */
  constructor(source: string, content: string) {
    const document = new DOMParser().parseFromString(source, 'text/html');

    this.content = content;
    this._document = document;
    this.body = document.body as HTMLBodyElement;
  }

  /**
   * For each textNode we've found, we push them into groups so we can figure out if they're adjacent to each other.
   * (in the same parent node). Then we wrap each group in a custom element so we can highlight them.
   * @param nodes The text nodes to be wrapped
   * @private
   */
  private wrapTextNodes(nodes: Node[]) {
    if (!nodes) {
      return [];
    }

    let groups: Array<Node[]> = [];
    let previousNode: Node | null = null;
    let currentGroup: Node[] = [];

    nodes.forEach(node => {
      if (previousNode && previousNode.nextSibling === node) {
        currentGroup.push(node);
      } else {
        currentGroup = [node];
        groups.push(currentGroup);
      }

      previousNode = node;
    });

    // Filter Whitespace
    groups = groups.filter(group => {
      if (group.length === 1 && REGEX_WHITESPACE.test(group[0].nodeValue as string)) {
        // Allow whitespace nodes within links
        // return isNodeALink(span[0].parentElement?.parentElement) ||
        //   // Allow whitespace between data-defined-terms & links
        //   (nodeSpans.length > 2 && areNodeSiblingsClickable(span[0], inactivateClickAttributes))
      }
      // FIXME this is gross, I don't know how we ended up with nested ternaries
      return group
          .some(node => node.nodeName !== 'IMG' || (!REGEX_WHITESPACES.test(node.nodeValue as string))) &&
        group.every(node => node.nodeName !== 'BR' && (!REGEX_WHITESPACE.test(node.nodeValue as string) || group.length > 2));
    });

    const wrappedElements: HTMLElement[] = [];

    groups.forEach((group, index) => {
      const element = this._document.createElement('askbluej-highlight');
      element.className = 'bg-yellow-100';
      if (index === 0) {
        element.id = 'askbluej-highlight-start';
      }
      group[0].parentNode && group[0].parentNode.replaceChild(element, group[0]);
      group.forEach(node => {
        element.appendChild(node);
      });
      wrappedElements.push(element);
    });

    return wrappedElements;
  }

  /**
   * Given a range, find all text nodes within that range. If the node is partially selected, split it into two nodes.
   * (i.e. if the range selection spans across multiple DOM nodes, we split the text into multiple nodes)
   * @param range The range to find text nodes within
   * @private
   */
  private getTextNodes(range: Range) {
    if (range.collapsed) return [];

    let root = range.commonAncestorContainer;
    if (root.nodeType != NodeType.ELEMENT_NODE) {
      root = root.parentElement as HTMLElement;
    }

    if (!root) return [];
    const nodes: Node[] = [];
    const nodeIterator = root.ownerDocument && root.ownerDocument.createNodeIterator(root, NodeFilter.SHOW_ALL,
      {
        acceptNode(node) {
          return node.nodeType === NodeType.TEXT_NODE ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;

        }
      });

    let node: Node | null;

    if (!nodeIterator) {
      console.warn(`Could not create Node Iterator for root.ownerDocument: ${root.nodeName}`);
      return [];
    }

    // eslint-disable-next-line no-cond-assign
    while (node = nodeIterator.nextNode()) {
      if (!range.intersectsNode(node)) continue;

      if (node === range.startContainer && range.startOffset > 0) {
        (node as Text).splitText(range.startOffset);
        continue;
      }

      if (node === range.endContainer && range.endOffset < (node as Text).data.length) {
        (node as Text).splitText(range.endOffset);
      }

      nodes.push(node);
    }

    return nodes;
  }

  /**
   * Given an element, an optional start/end offsets, find the actual start/end offsets within the text node.
   * @param element The element to find the offsets within
   * @param offsets The start/end offsets
   * @private
   */
  private getTextNodeOffsets(element: Element, ...offsets: number[]) {
    let nextOffset = offsets.shift();
    const nodeIter = element.ownerDocument?.createNodeIterator(
      element,
      NodeFilter.SHOW_TEXT,
      null
    );
    const results: { node: Text, offset: number }[] = [];

    let currentNode = nodeIter?.nextNode();
    let textNode;
    let length = 0;

    // Find the text node containing the `nextOffset`th character from the start
    // of `element`.
    while (nextOffset !== undefined && currentNode) {
      textNode = currentNode;
      if (length + (textNode as Text).data.length > nextOffset) {
        results.push({ node: textNode as Text, offset: nextOffset - length });
        nextOffset = offsets.shift();
      } else {
        currentNode = nodeIter?.nextNode();
        length += (textNode as Text).data.length;
      }
    }

    // Boundary case.
    while (nextOffset !== undefined && textNode && length === nextOffset) {
      results.push({ node: textNode as Text, offset: (textNode as Text).data.length });
      nextOffset = offsets.shift();
    }

    if (nextOffset !== undefined) {
      throw new RangeError('Offset exceeds text length');
    }

    return results;
  }

  /**
   * Given an element, an offset, and a direction, find the actual offset within the text node. How does this differ
   * from the above function? It doesn't, really. We just need to handle the case where the offset is 0 and the direction
   * is 'forward' or the offset is the length of the text node and the direction is 'backward'.
   *
   * @param element The element to find the offset within
   * @param offset The offset
   * @param direction The direction to move in if the offset is 0 or the length of the text node
   * @private
   */
  private getElementOffset(element: Element, offset: number, direction: 'forward' | 'backward') {
    if (!element) return;

    try {
      return this.getTextNodeOffsets(element, offset)[0];
    } catch (err) {
      if (offset === 0 && direction !== undefined) {
        const treeWalker = document.createTreeWalker(
          element.getRootNode(),
          NodeFilter.SHOW_TEXT,
          null
        );
        treeWalker.currentNode = element;
        const forwards = direction === 'forward';
        const textNode = forwards ? treeWalker.nextNode() : treeWalker.previousNode();
        if (!textNode) {
          throw err;
        }
        return { node: textNode, offset: forwards ? 0 : (textNode as Text).data.length };
      } else {
        throw err;
      }
    }
  }

  /**
   * Given a "Match" object (from approx-string-match -- the approximate start and end indices of the text), find the
   * actual start and end indices within the text node.
   *
   * @param match The match object
   * @param body The HTMLBodyElement of the source document
   * @private
   */
  private createRange(match: Omit<Match, 'errors'>, body: HTMLBodyElement) {
    let start;
    let end;
    if (match.start <= match.end) {
      // Fast path for start and end points in same element.
      [start, end] = this.getTextNodeOffsets(
        body,
        match.start,
        match.end
      );
    } else {
      start = this.getElementOffset(body, match.start, 'forward');
      end = this.getElementOffset(body, match.end, 'backward');
    }

    const range = body.ownerDocument.createRange();

    if (start) {
      range.setStart(start.node, start.offset);
    }

    if (end) {
      range.setEnd(end.node, end.offset);
    }

    return range;
  }

  public highlight() {
    const match = matchQuote(this.body.textContent || '', this.content);

    if (!match) {
      return this.body.innerHTML;
    }

    const range = this.createRange(match, this.body);
    const textNodes = this.getTextNodes(range);

    this.wrapTextNodes(textNodes);

    return this.body.innerHTML;
  }
}
