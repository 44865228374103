import React from 'react';

export function useNumberFormatter(): NumberFormatter {
  const numberFormatter = React.useMemo(() => Intl.NumberFormat('en-US'), []);

  return React.useMemo(() => {
    return {
      format: (value: number) => numberFormatter.format(value)
    }
  }, [numberFormatter]);
}

export interface NumberFormatter {
  format: (value: number) => string;
}
