import { AnalyticsBrowser } from '@segment/analytics-next';

import { Analytics, EventProperties, UserProperties } from './analytics';
import { getAnalyticsEndpointsForEnv } from './environment';

export class SegmentAnalytics implements Analytics {
  private instance: AnalyticsBrowser;

  constructor(segmentInstance: AnalyticsBrowser) {
    this.instance = segmentInstance;
  }

  static create(writeKey: string, env: string, creator: SegmentCreator = createSegmentBrowserAnalytics) {
    return new SegmentAnalytics(creator(writeKey, env));
  }

  /**
   * See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
   * @param eventName
   * @param eventProperties
   */
  track(eventName: string, eventProperties?: EventProperties) {
    void this.instance.track(eventName, eventProperties);
  }

  /**
   * See: See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
   * @param userId
   * @param userProperties
   */
  identify(userId: string, userProperties?: UserProperties) {
    void this.instance.identify(userId, userProperties);
  }

  /**
   *
   * See: See:https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   * @param category
   * @param name
   * @param properties
   */
  page(category: string, name?: string, properties?: EventProperties) {
    void this.instance.page(category, name, properties);
  }
}

export function createSegmentBrowserAnalytics(writeKey: string, env: string) {
  // Note: This makes requests to Segment to retrieve configuration and load additional scripts
  const urls = getAnalyticsEndpointsForEnv(env);
  return AnalyticsBrowser.load({
    writeKey,
    cdnURL: `https://${urls.cdn}`,
  }, {
    integrations: {
      "Segment.io": {
        apiHost: `${urls.api}/v1`
      }
    },
    initialPageview: false,
    disableClientPersistence: true,
    user: {
      persist: false
    },
    group: {
      persist: false
    },
    obfuscate: true
  })
}

type SegmentCreator = (writeKey: string, env: string) => AnalyticsBrowser;
