import { Config } from '@/types';
import { ISourcesApi, SourcesApi } from '../thread/SourcesApi';
import { AskBlueJApi, IAskBlueJApi } from './api/ask-bluej-api';
import { ISentryService, SentryService } from './sentry/context';

export type Services = {
  askBlueJApi: IAskBlueJApi;
  sourcesApi: ISourcesApi;
  sentry: ISentryService;
}

interface ServicesConfig {
  backendUrl: string;
}

export function createServices(servicesConfig: ServicesConfig, config: Config): Services {
  const sourcesApi = new SourcesApi(servicesConfig.backendUrl);
  const askBlueJApi = new AskBlueJApi(servicesConfig.backendUrl, `${config.apiUrl}/`);
  const sentry = new SentryService(config.sentryDsn, config.environment);

  return {
    askBlueJApi,
    sourcesApi,
    sentry
  }
}
