import { Link } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/20/solid';

import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';

import React from 'react';
import { Button } from '../../../components/button';
import { useMobileHeaderContext } from '../contexts/MobileHeaderContext';

type Props = {
  threadTitle?: string;
}

export function MobileHeader({ threadTitle }: Props) {

  const { trackLogoClicked } = useAnalyticsEvent();
  const { toggleThreadMenuVisibility } = useMobileHeaderContext();
  return (
    <>
      <div
        className="sticky top-0 bg-white z-20 h-20 md:h-24 px-4 md:px-10 flex items-center justify-between border-b border-b-grey-300 lg:hidden">
        {threadTitle ?
          <div className="py-4 font-semibold text-lg">{threadTitle}</div> :
          <div className="py-4 w-full mx-auto">
            <Link to="/" className=" align-middle" title="Return to home" onClick={trackLogoClicked}>
              <div className="flex">
                <img className="w-32 md:w-48 h-auto"
                     src="logo.svg"
                     alt="Blue J Logo"/>
              </div>
            </Link>
          </div>
        }
        <Button
          colour="secondary"
          variant="icon"
          title="Open thread menu"
          type="button"
          className="text-blue-400 hover:text-blue-500"
          onClick={toggleThreadMenuVisibility}
        >
          <span className="sr-only">Open thread menu</span>
          <Bars3Icon className="h-8 w-8" aria-hidden="true"/>
        </Button>
      </div>
    </>
  )
}
