import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { ThreadedAskStreamState } from '../../../ask-bluej/streaming/threaded/reducer';
import { useAskBluejApiContext } from '../../../core/api/ask-bluej-api';
import { useSentryContext } from '../../../core/sentry/context';

import { StopStreamingButton } from './stop-streaming-button';

type AbortChatButtonProps = {
  state: ThreadedAskStreamState;
  onAbort: (state: ThreadedAskStreamState) => void;
}

export function StreamingChatAbort(props: AbortChatButtonProps) {
  const { state, onAbort } = props;
  const { threadId, chatId } = state.payload;

  const abortingInProgressRef = React.useRef(false);

  const { api } = useAskBluejApiContext();
  const { sentry } = useSentryContext();

  const handleAbort = useCallback(() => {
    if (!abortingInProgressRef.current) {
      // Prevent spamming this button while we're trying to abort
      abortingInProgressRef.current = true;
      api.abortChat(threadId, chatId)
        .then(() => {
          onAbort(state);
        })
        .catch((err) => {
          toast.error('Failed to stop streaming of answer', {
            toastId: 'abort-chat-failed'
          });
          sentry.reportMessage('Failed to abort chat', {
            err
          });
        })
        .finally(() => {
          abortingInProgressRef.current = false;
        });
    }
  }, [threadId, chatId, onAbort, sentry, state]);

  useEffect(() => {
    return () => {
      toast.dismiss('abort-chat-failed');
    }
  }, [toast]);

  // Don't bother rendering this button if we're not in a state where aborting makes sense
  if (state.state === 'initiated' || state.state === 'answering') {
    // This component is responsible for adding spacing to the bottom of the chat
    return (
      <div className="h-10 static">
        <div className="flex justify-center">
          <div className="absolute z-10 flex bottom-4">
            <StopStreamingButton onAbort={handleAbort} />
          </div>
        </div>
      </div>
    );
  }

  return null;
}
