import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { useShowQuestionDebug } from '../../core/feature-flag/features/use-question-debug';
import { useGlobalAnalyticsContext } from '../contexts/GlobalAnalyticsContext';

interface Props {
  strategy?: string;
}

export function QuestionDebug({ strategy, children }: PropsWithChildren<Props>) {
  const showQuestionDebug = useShowQuestionDebug();

  const { threadId, chatId } = useGlobalAnalyticsContext();

  if (!showQuestionDebug) {
    return children;
  }

  return (
    <div className="flex justify-between items-center w-full">
      <div>
        { children }
      </div>
      <div className="text-gray-400 text-sm align-top text-right">
        <p>Strategy: {strategy || 'Not defined'}</p>
        { chatId && <Link className='text-sm text-blue-400 hover:underline underline-offset-4' to={`/${threadId}/chat/${chatId}/trace`}>Trace</Link> }
      </div>
    </div>

  );
}
