import { SourceViewListing } from '@/types';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { useChatContext } from '../../contexts/ChatContext';
import { RestrictedSourceInteractionContext } from '../../types';
import { SourceBadge } from './source-badge';
import { SourceLink } from './source-link';
import { ExternalBadge } from './external-badge';
import { ExternalLinkButton } from './external-link-button';

export const SourceList = () => {
  const { chat, sourcesVisible } = useChatContext();
  const { trackAnswerContentCopied } = useAnalyticsEvent();

  const onSourceCopyHandler = useCallback(() => {
    if (!chat.chat_id || !document.getSelection()?.toString().length) {
      return;
    }
    trackAnswerContentCopied({ sourcesIncluded: true });
  }, [chat.chat_id]);

  if (!sourcesVisible) {
    return null;
  }

  if (chat.sources.length === 0) {
    return null;
  }

  return (
    <div className="mt-4" id={`sources-list-${chat.chat_id}`} onCopy={onSourceCopyHandler}>
      <div className="mb-3">Sources</div>
      <div className="min-w-full text-grey-600 text-sm overflow-hidden">
        <div className="grid gap-y-2">
          {
            chat.sources.map((source, index) => {
              return (
                <Source
                  source={source}
                  index={index + 1}
                  key={source.id}
                />
              );
            }
            )
          }
        </div>
      </div>
    </div>
  )
}

type SourceLinkSwitchProps = {
  index: number;
  source: SourceViewListing;
  onClickCallback: () => void;
  additionalSource: boolean;
};

function SourceLinkSwitch({ index, source, onClickCallback, additionalSource }: SourceLinkSwitchProps) {
  const { trackRestrictedSourceOpenInNewTabClicked } = useAnalyticsEvent();
  const showQuickSummarize = source.externalUrl === null;

  const onClickHandler = useCallback((isTitle = true) => {
    if (source.externalUrl && !isTitle) {
      return () => trackRestrictedSourceOpenInNewTabClicked({
        sourceType: source.badge, // FIXME should this be source.type? These analytics values are inconsistent
        sourceTitle: source.title,
        interactionContext: RestrictedSourceInteractionContext.QUESTION_SOURCE_LIST,
        referredUrl: source.externalUrl ?? '',
        additionalSources: additionalSource
      });
    }

    return () => onClickCallback();
  }, [source]);

  const sourceLinkStyle = clsx('hover:underline underline-offset-4 text-blue-400', {
    'mr-4': showQuickSummarize
  });

  return (
    <SourceLink number={index} additionalSource={additionalSource}>
      { ({ url }) => (
        <>
          <Link className={sourceLinkStyle} to={url} onClick={onClickHandler()}>
            <TitleComponent title={`${additionalSource ? '' : `[${index}] `}${source.title}`} />
          </Link>
          { showQuickSummarize &&
            <Link
              className="inline-flex align-sub text-xs hover:text-blue-400"
              to={url}
              state={{ context: 'quick-summarize' }}
            >
              <DocumentTextIcon className="size-4" />
              Summarize
            </Link>
          }
          <ExternalBadge
            badge={source.externalBadge}
            href={source.externalUrl}
            onClick={onClickHandler(false)}
          />
          <ExternalLinkButton
            href={source.externalUrl}
            onClick={onClickHandler(true)}
          />
        </>
      )}
    </SourceLink>
  )
}

type TitleComponentProps = {
  title: string;
}

export function TitleComponent({ title }: TitleComponentProps) {
  return (
    <span title={title}>
      {title}
    </span>
  );
}

type SourceProps = {
  index: number;
  source: SourceViewListing;
  additionalSource?: boolean;
};

export const Source: React.FC<SourceProps> = (props) => {
  const { index, source, additionalSource = false } = props;
  const { trackSourceLinkClicked } = useAnalyticsEvent();
  const handleTrackSourceLinkClicked = useCallback(() => {
    trackSourceLinkClicked({
      sourceTitle: source.title,
      sourceType: source.badge,
      contentType: source.type,
      restricted: source.externalUrl !== null,
      referredUrl: source.externalUrl || source.href,
      additionalSources: additionalSource
    });
  }, [source.id]);

  return (
    <div className="grid grid-cols-[5.75rem_auto]">
      <div>
        <SourceBadge badge={source.badge} />
      </div>
      <div>
        <SourceLinkSwitch
          index={index}
          onClickCallback={handleTrackSourceLinkClicked}
          source={source}
          additionalSource={additionalSource}
        />
      </div>
    </div>
  );
};
