import EventEmitter from 'eventemitter3';
import * as React from 'react';

type EventHandler = (event: Event) => void;

export function useSharedScrollEventListener() {
  const [emitter] = React.useState(new EventEmitter());
  const pausedRef = React.useRef(false);
  const elementRef = React.useRef<null | HTMLElement>(null);

  const skipNextEmit = React.useCallback(() => {
    pausedRef.current = true;
  }, []);

  const handleScrollEvent = React.useCallback<EventHandler>((event) => {
    if (pausedRef.current) {
      pausedRef.current = false;
      return;
    }
    emitter.emit('scroll', event);
  }, []);

  const refCallback = React.useCallback((element: HTMLElement | null) => {
    // If we have a ref for an existing element, remove listeners/reference
    if (elementRef.current) {
      elementRef.current.removeEventListener('scroll', handleScrollEvent);
      elementRef.current = null;
    }

    if (element) {
      elementRef.current = element;
      elementRef.current.addEventListener('scroll', handleScrollEvent);
    }
  }, []);

  return {
    ref: refCallback,
    skipNextEmit,
    emitter
  };
}
