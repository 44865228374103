import React, { PropsWithChildren, useEffect } from 'react';
import { useLDClient, withLDProvider, useFlags } from 'launchdarkly-react-client-sdk';

import { useUser } from '../user/context';
import { useConfigContext } from '../../config/ConfigContext';

// TODO perhaps get rid of this context provider, since feature flags are included in the config context
type Props = {
  clientId?: string;
}

function LDIdentifier({ children }: PropsWithChildren) {
  const client = useLDClient();
  const { user } = useUser();

  useEffect(() => {
    if (!client) return;

    client.identify({
      key: user.id,
      email: user.email
    })
  }, [client, user]);

  return (
    <>
      { children }
    </>
  );
}

export function FeatureFlagProvider({ children, clientId }: React.PropsWithChildren<Props>) {
  if (!clientId) {
    return (
      <>
        { children }
      </>
    );
  }

  const LDProvider = withLDProvider({
    clientSideID: clientId,
    options: {
      streaming: false
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  })(LDIdentifier);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LDProvider>
      { children }
    </LDProvider>
  )
}

export function useFeatureFlag<T>(key: string, defaultValue: T): T {
  let flags = useFlags();
  const config = useConfigContext();

  flags = {
    ...flags,
    ...config.featureFlag.overrides
  }

  if (flags[key] !== undefined) {
    return flags[key];
  }

  return defaultValue;
}
