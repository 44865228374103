import { useStrategyQuery } from '../../core/strategy/useStrategyQuery';

type Props = {
  onSelectStrategy: (strategy: string) => void;
  defaultValue?: string;
}

export function StrategyDropdown({ onSelectStrategy, defaultValue }: Props) {
  const { data, isLoading, isError } = useStrategyQuery();

  if (isLoading || isError || data === undefined) {
    return null;
  }

  const { strategiesList } = data;

  return (
    <select
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      onChange={(event) => onSelectStrategy(event.target.value)}
      defaultValue={defaultValue || strategiesList[0].value}
    >
      { strategiesList?.map(({ displayName, value }) => (
        <option key={value} value={value}>
          { displayName }
        </option>
      ))}
    </select>
  )
}
