import React from 'react';

import { ResponseError } from '../../core/api/api';
import { Button } from '../../../components/button';
import ErrorSvg from '../../../assets/svg/error.svg?react'

import { MobileHeader } from './mobile-header';

type ThreadErrorProps = {
  error: ResponseError;
  handleRetry: () => void;
}

function ErrorMessage({ error, handleRetry }: ThreadErrorProps) {
  switch (error.status) {
    case 404:
      return <h1 className="text-lg mb-4">This thread was deleted.</h1>;
    case 403:
    case 401:
      return <h1 className="text-lg mb-4">You do not have access to view this thread.</h1>;
    default:
      return (
        <>
          <h1 className="text-lg mb-4">Error loading thread.</h1>
          <Button
            colour="primary"
            className="px-3 py-1 mb-10"
            title="Try Again"
            type="submit"
            onClick={handleRetry}
            variant="contained"
          >
            Try again
          </Button>
        </>
      );
  }
}

export function ThreadError({ error, handleRetry }: ThreadErrorProps) {
  const mailMessage = () => {
    switch (error.status) {
      case 404:
      case 403:
      case 401:
        return 'If you have questions, please '
      default:
        return 'If this error persists, please '
    }
  }

  return (
    <div className="flex flex-col h-full">
      <MobileHeader/>
      <div className="flex flex-1 flex-col items-center justify-center">
        <ErrorSvg />
        <div className="text-center mt-2">
          <ErrorMessage error={error} handleRetry={handleRetry}/>
        </div>
        <div className="text-center mx-2">
          {mailMessage()}
          <a
            href="https://support.bluej.com"
            target="_blank"
            title="Contact Support"
            className="text-blue-400 hover:underline"
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  )
}
