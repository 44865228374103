import { SparklesIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../components/button';
import React from 'react';
import { AskDocInteractionType } from '../../ask-bluej/streaming/doc/shared';

type Props = {
  interactionContext: AskDocInteractionType;
  setTooltipVisible: (visible: boolean) => void;
}
export function DocTooltip(props: Props) {
  const { interactionContext, setTooltipVisible } = props;
  return (
      <div
        className="max-w-[340px] flex flex-col items-start select-none whitespace-normal tooltip bg-blue-400 text-white p-4 rounded-lg font-semibold shadow-md z-[9999]"
        role="tooltip"
      >
        <div className="flex flex-row items-center gap-2">
          <SparklesIcon className="h-6 w-6"/> Quick answers from this source
        </div>
        <Button
          onClick={() => {
            localStorage.setItem(`ask-${interactionContext}-tooltip`, 'true');
            setTooltipVisible(false);
          }}
          title="Close tooltip"
          variant="icon"
          colour="secondary"
          size="small"
          className="absolute top-2 right-2 rounded-full focus:outline-white"
        >
          <span className="sr-only">Close tooltip</span>
          <XMarkIcon className="h-6 w-6 text-white hover:text-blue-600" aria-hidden="true"/>
        </Button>
        <div className="text-sm font-normal whitespace-normal pt-2 leading-4 select-text">
          Try it out by asking "Summarize this" or "Does this source contain any information on [your area
          of interest]?"
        </div>
      </div>
  )
}
