import React from 'react';
import { ThreadedAskStreamState } from '../ask-bluej/streaming/threaded/reducer';
import { ExpectationSettingMessage } from './expectation-setting-message';
import { useFetchExpectationSettingQuery } from './useFetchExpectationSettingQuery';

type ExpectationSettingProps = {
  state: ThreadedAskStreamState;
  visible: boolean;
}

export const ExpectationSetting = ({ visible, state }: ExpectationSettingProps) => {
  const { question, strategy, chatId } = state.payload;
  const expectationSettingQuery = useFetchExpectationSettingQuery({ question, strategy, chatId });
  return <ExpectationSettingMessage visible={visible} criteria={expectationSettingQuery.data?.criteria} />;
};
