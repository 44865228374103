import { SourceView, SourceViewListing } from '@/types';
import React, { useEffect, useState } from 'react';
import { useSourceContentQuery } from '../../useSourceContentQuery';
import { renderToString } from 'react-dom/server';
import { SourceDescription } from './source-description';
import { usePrintPage } from '../../hooks/usePrintPage';

export function usePrintSourceData(sourceViewListing: SourceViewListing) {
  const [sourceView, setSourceView] = useState<SourceView | undefined>(undefined);
  const { data, status } = useSourceContentQuery(sourceViewListing);

  useEffect(() => {
    setSourceView(data);
  }, [data]);

  const description = sourceView ? renderToString(<SourceDescription data={sourceView} />) : '';
  const sourceContent = description ? `<div>${description}${sourceView?.content}</div>` : data?.content ?? '';
  const { printComponentRef, handlePrint } = usePrintPage(sourceView?.title ?? data?.title ?? '');

  return { sourceView, sourceContent, sourceContentStatus: status, printComponentRef, handlePrint, data, status };
}
