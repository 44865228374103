import { SourceContentQueryProps } from '../../thread/useSourceContentQuery';

export function getSourceTypeAndParams(ref: string): SourceContentQueryProps | undefined {
  const [type, ...params] = ref.split('/');

  // FIXME backwards-compat
  if (type === 'case' || type === 'document' || type === 'curator_case' || type === 'curator_document') {
    return {
      type,
      id: params[0]
    }
  }

  if (type === 'statute' || type === 'regulation') {
    const title = params[0];
    const sectionNumber = params[1].split('?')[0];
    return {
      type,
      id: `/${title}/${type}/${sectionNumber}`
    }
  }
}
