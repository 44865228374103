import { Transition } from '@headlessui/react';
import { DocumentTextIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { QuestionInteractionType } from '../../ask-bluej/streaming/threaded/shared';
import { PropsWithChildren } from 'react';

const memoPrompt: QuickPrompt = {
  prompt: 'Turn this into a research memo',
  context: QuestionInteractionType.CREATE_MEMO
}

const emailPrompt: QuickPrompt = {
  prompt: 'Turn this into an email that I can send to my client',
  context: QuestionInteractionType.CREATE_EMAIL
}

type QuickPrompt = {
  prompt: string;
  context: QuestionInteractionType;
}

type Props = {
  onSubmit: (prompt: string, context: QuestionInteractionType) => void;
  disabled?: boolean;
  numChats: number;
  existingThreadTopic?: string;
  isParentFocused: boolean;
};

export const QuickPrompts = ({ onSubmit, disabled, numChats, existingThreadTopic, isParentFocused, children }: PropsWithChildren<Props>) => {
  const { trackCreateMemoClicked, trackCreateEmailClicked } = useAnalyticsEvent();
  const buttonClass = 'hover:text-blue-500 hidden lg:flex flex-row gap-1 items-center text-grey-600 lg:disabled:text-grey-400';
  const handleCreateMemo = () => {
    trackCreateMemoClicked({ questionPairsInThread: numChats });
    onSubmit(memoPrompt.prompt, memoPrompt.context);
  };

  const handleCreateEmail = () => {
    trackCreateEmailClicked({ questionPairsInThread: numChats });
    onSubmit(emailPrompt.prompt, emailPrompt.context);
  };
  return (
    <Transition
      show={isParentFocused || existingThreadTopic !== undefined}
      appear={existingThreadTopic === undefined}
      enter="transition-all ease-in-out duration-1000"
      enterFrom="max-h-0 opacity-0"
      enterTo="max-h-[1000px] opacity-100"
      leave="transition-all ease-in-out duration-500"
      leaveFrom="max-h-[1000px] opacity-100"
      leaveTo="max-h-0 opacity-0"
    >
      <div className="flex flex-row gap-4">
        { children }
        <button className={buttonClass} onClick={handleCreateMemo} title="Create memo" disabled={disabled}>
          <DocumentTextIcon className="h-5 w-5 align-middle"/>
          <div className="text-xs">Create memo</div>
        </button>
        <button className={buttonClass} onClick={handleCreateEmail} title="Create client email" disabled={disabled}>
          <EnvelopeIcon className="h-5 w-5 align-middle"/>
          <div className="text-xs">Create client email</div>
        </button>
      </div>
    </Transition>
  )
};

