import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { withErrorBoundary } from '@sentry/react';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { useChatContext } from '../thread/contexts/ChatContext';
import { AdditionalSourcesList } from './additional-sources-list';

export const AdditionalSources = withErrorBoundary(() => {
  const { chat, unanswered } = useChatContext();
  const additionalSourcesVisible = !unanswered && chat.sources.length >= 1;
  const [showAdditionalSources, setShowAdditionalSources] = useState(false);

  const handleShowAdditionalSources = () => {
    setShowAdditionalSources(!showAdditionalSources);
  };

  if (!additionalSourcesVisible) {
    return null;
  }

  return (
    <div className="mt-4">
      <AnimateHeight
        duration={300}
        height={showAdditionalSources ? 'auto' : 0}
      >
        {showAdditionalSources && <AdditionalSourcesList handleShowAdditionalSources={handleShowAdditionalSources} />}
      </AnimateHeight>
      <AnimateHeight
        duration={300}
        height={showAdditionalSources ? 0 : 'auto'}
      >
        <div className="flex text-blue-400 text-sm cursor-pointer" onClick={handleShowAdditionalSources}>
          <PlusCircleIcon className="size-5" />
          View more sources
        </div>
      </AnimateHeight>
    </div>
  );
}, {});

