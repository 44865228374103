import React from 'react';
import clsx from 'clsx';
import { BUTTON_CLASSES } from './shared';

type Props = {
  onClick?: () => void;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: React.PropsWithChildren<Props>) {
  const { onClick, children, className, ...buttonProps } = props;
  const mergedClasses = clsx(BUTTON_CLASSES, className);

  return (
    <button onClick={onClick} className={mergedClasses} {...buttonProps}>
      { children }
    </button>
  )
}
