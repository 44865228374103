import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { useAskBluejApiContext } from '../core/api/ask-bluej-api';

export function useThreadCreateQuery() {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();
  const { trackThreadCreated } = useAnalyticsEvent();

  return useMutation({
    mutationFn: async () => {
      const { preferences } = await api.getUserPreferences(); // This is for-now, in the future, this will likely be selectable from a dropdown
      return api.createThread(preferences.preferredTopic);
    },
    onSuccess: (thread) => {
      trackThreadCreated(thread.id);
      queryClient.setQueryData(['threads', thread.id], {
        pages: [thread],
        pageParams: [1]
      });
      void queryClient.invalidateQueries({ queryKey: ['thread-list'] });
    }
  });
}
