import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SourceViewListing } from '@/types';

import { sortSourceByBadge } from '../source/utils/sort-source-by-badge';
import { useSourceApiContext } from '../thread/contexts/SourceApiContext';

export function useAdditionalSourcesQuery(threadId: string, chatId: string): UseQueryResult<SourceViewListing[]> {
  const { api } = useSourceApiContext();

  return useQuery({
    queryKey: ['additional-sources', threadId, chatId],
    queryFn: async () => {
      const sources = await api.getAdditionalSources(threadId, chatId);
      return sources.sort(sortSourceByBadge);
    },
    staleTime: Infinity,
    gcTime: Infinity
  });
}
