import React, { useEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';

import { ThreadedAskStreamState } from '../../../../ask-bluej/streaming/threaded/reducer';

import { createMachineFromConfig, machineUpdateEventFromStreamState } from './machine';
import { PROGRESS_COMPLETION_DELAY_MS } from './shared';

export function ProgressController(props: Props) {
  const {
    render: ProgressRender,
    onComplete,
    state,
    warningRender: WarningRender
  } = props;

  const machine = useMemo(() => {
    return createMachineFromConfig({
      transitionTiming: {
        initiated: 3000,
        searching: 5000,
        processing: 3500,
        complete: PROGRESS_COMPLETION_DELAY_MS
      }
    });
  }, []);

  const [machineState, send, machineRef] = useMachine(machine);

  useEffect(() => {
    const subscription = machineRef.subscribe((state) => {
      if (state.status === 'done') {
        // Machine has completed
        onComplete();
      }
    });
    return subscription.unsubscribe;
  }, [onComplete]);


  useEffect(() => {
    send(machineUpdateEventFromStreamState(state));
  }, [state]);

  return (
    <>
      <ProgressRender step={machineState.context.step} progress={machineState.context.progress} />
      <WarningRender visible={machineState.context.isAnswering} state={state} />
    </>
  );
}

type Props = {
  state: ThreadedAskStreamState;
  render: React.ElementType<ProgressRenderProps>;
  onComplete: () => void;
  warningRender: React.ElementType<WarningRenderProps>;
}

type WarningRenderProps = {
  visible: boolean;
  state: ThreadedAskStreamState;
}

type ProgressRenderProps = {
  step: string;
  progress: number;
}

