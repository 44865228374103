import React, { useCallback, useState } from 'react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useFloating, autoUpdate, flip, offset, shift } from '@floating-ui/react';
import { Button } from '../../../components/button';
import clsx from 'clsx';
import { convertMarkdownToHtml, convertMarkdownToText } from '../../ask-doc/extract';

interface CopyToClipboardProps {
  content: string;
  trackCopyToClipboard: () => void;
}

export function CopyToClipboard({ content, trackCopyToClipboard }: CopyToClipboardProps) {
  const [clipboardEffect, setClipboardEffect] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const { refs, floatingStyles } = useFloating({
    open: isTooltipVisible,
    onOpenChange: setTooltipVisible,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ fallbackPlacements: ['top', 'bottom'] }),
      shift(),
      offset(5)
    ],
    placement: 'top',
  });

  const handleCopyToClipboard = useCallback(() => {
    trackCopyToClipboard();
    setClipboardEffect(true);

    void window.navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([convertMarkdownToText(content)], { type: 'text/plain' }),
        'text/html': new Blob([convertMarkdownToHtml(content)], { type: 'text/html' })
      }),
    ]);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 1500);
  }, [content, trackCopyToClipboard]);

  const clipboardIconClasses = clsx('w-6 h-6', {
    'animate-clipboard-pulse ': clipboardEffect
  });

  return (
    <>
      <Button
        ref={refs.setReference}
        colour="secondary"
        onClick={handleCopyToClipboard}
        title="Copy to Clipboard"
        variant="icon"
      >
        <ClipboardDocumentIcon className={clipboardIconClasses} onAnimationEnd={() => setClipboardEffect(false)}/>
      </Button>
      {isTooltipVisible && (
        <div className="relative">
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="select-none whitespace-nowrap tooltip bg-grey-600 text-white px-2 py-1 text-xs rounded-md z-50"
            onCopy={(e) => { e.preventDefault(); return false; }}
            role="tooltip"
            title={'Copied to clipboard'}
          >
            Copied to clipboard
          </div>
        </div>
      )}
    </>
  );
}
