export type Tip = {
  title: string;
  description: string;
  prompt?: string;
  examplePrompts?: string[];
}

export const tips: Tip[] = [{
  title: 'Tips',
  description: `
## Blue&nbsp;J is an incredibly powerful generative AI tool that works differently than a traditional search box. To get the most from Blue&nbsp;J, try to provide a well-formed tax question that gives the AI guidance on what you are looking for.
`,
  examplePrompts: [
    'What are the rules for the deductibility of interest paid on business loans?',
    'How are capital gains taxed if I sell an investment property?',
    'What are the tax implications of claiming foreign tax credits on investment income?'
  ]
}, {
  title: 'Write a clear, specific and focused question',
  description: '## The more specificity you provide in your question, the more likely you are to get a helpful response. Avoid ambiguous language and basic keyword searches. You can specify the output, tone, or length of the response you want.',
  prompt: 'Are meals 50% in 2023',
  examplePrompts: ['What\'s the deduction rate for meals in 2023 for personal tax purposes?']
}, {
  title: 'Break questions into single issues and ask follow-up questions',
  description: '## Blue&nbsp;J is conversational. Try to break down complex questions into single issues. You can always ask multiple follow-up questions and Blue&nbsp;J will remember the context of your conversation.',
  prompt: 'How are intangible assets valued during an acquisition? Are there tax considerations that should be taken into account for amortization?',
  examplePrompts: [
    'How are intangible assets valued during an acquisition?',
    'Follow-up: “What tax considerations should be taken into account for their amortization?”'
  ]
}, {
  title: 'Turn research into concise client emails or memos',
  description: '## Blue&nbsp;J is great at taking research and turning it into a draft work product in seconds. For best results, ask your research questions first, then ask it to convert the conversation into a presentation, blog post, bullet points, or essay format.',
  prompt: 'Draft an email on the deductibility of home office expenses for a self-employed individual',
  examplePrompts: [
    'Summarize the rules on deducting home office expenses for a self-employed individual',
    'Follow-up: “Put that into a formal client email”'
  ]
}, {
  title: 'Summarize in seconds',
  description: '## Blue&nbsp;J excels at synthesizing large volumes of relevant material into quick, concise, and easy to follow summaries. It’s more powerful than a simple search, especially when you phrase your desired topic as a question or include a verb like explain or summarize.',
  prompt: 'safe income and corporate beneficiary',
  examplePrompts: [
    'How is safe income allocated to a corporate beneficiary?',
    'Summarize safe income in the context of a corporate beneficiary'
  ]
}, {
  title: 'Additional Guidance',
  description: `
## While Blue&nbsp;J isn’t always able to respond confidently, expect to see a wider breadth of questions answered in the future! A few areas we are working on include: dates and historical rules, legislative history, questions about how the law has changed over time, and calculations.
## Need more help? Email [support@bluej.com](mailto:support@bluej.com) with any questions.
`
}];
