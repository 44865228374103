import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
}

export function PageTitle({ title }: Props) {
  return (
    <Helmet>
      <title>{ title }</title>
    </Helmet>
  );
}
