export function validateUserInput(value: string, config: ValidateUserInputConfig = DEFAULT_USER_VALIDATION_CONFIG): InputState {
  const trimmedValue = value.trim();

  if (trimmedValue.length > config.maxInputLength) {
    return INPUT_INVALID_TOO_LONG;
  }

  return INPUT_VALID;
}

type ValidateUserInputConfig = {
  maxInputLength: number;
}

export function defaultUserInputValidationConfig() {
  return {
    maxInputLength: 10_000,
  }
}

export const DEFAULT_USER_VALIDATION_CONFIG = defaultUserInputValidationConfig();

type ValidInputState = {
  valid: true
};

type InvalidInputState = {
  valid: false;
  reason: 'too-long';
}

export type InputState = ValidInputState | InvalidInputState;

export const INPUT_VALID: ValidInputState = {
  valid: true,
};

export const INPUT_INVALID_TOO_LONG: InvalidInputState = {
  valid: false,
  reason: 'too-long'
}
