import { PrinterIcon } from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@sentry/react';
import React from 'react';

import { Button } from '../../../components/button';
import { isNoAnswer } from '../../ask-bluej';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { useThreadContext } from '../contexts/ThreadContext';
import { usePrintPage } from '../hooks/usePrintPage';
import { formatForPrint } from './utils/formatForPrint';

export const ShareThread = withErrorBoundary(() => {
  const { thread } = useThreadContext();
  const chats = thread.chats;

  const { trackThreadPrintClicked } = useAnalyticsEvent();
  const enableShareThread = thread.chats.filter(chat => chat.chat_type !== 'summary_noanswer' && chat.chat_type !== 'summary_noanswer_notax' && !isNoAnswer(chat.answer)).length > 0;

  const { handlePrint: handlePrintPage } = usePrintPage(thread.title,
    chats.filter(c => c.chat_type !== 'summary_noanswer' && c.chat_type !== 'summary_noanswer_notax' && !isNoAnswer(c.answer)) // don't print idk responses
      .map(chat => formatForPrint(chat.question, chat.originalAnswer || chat.answer, chat.sources))
      .join('')
  );

  const handlePrint = () => {
    handlePrintPage();
    trackThreadPrintClicked(thread.id, chats.length);
  };

  if (!enableShareThread) return null;

  return (
    <>
      <div className="flex align-center">
        <Button
          colour="secondary"
          className="hidden lg:flex py-1 px-1"
          onClick={handlePrint}
          title="Print"
          variant="icon"
        >
          <PrinterIcon className="w-6 h-6"/>
        </Button>
      </div>
    </>
  )
}, {});
