import { mockCustomerioAnalyticsLogger } from './mock-logger';

export function useCustomerioAnalytics() {
  const customerioAnalytics = getCustomerIo();

  return { customerioAnalytics };
}

function getCustomerIo() {
  if (import.meta.env.VITE_APP_MOCK_ANALYTICS === 'true') {
    return mockCustomerioAnalyticsLogger;
  }
  return window._cio;
}
