import React from 'react';

import { AnsweredQuestion } from '../answered-question';
import { useThreadContext } from '../../contexts/ThreadContext';

export function ThreadChatsList() {
  const { thread } = useThreadContext();
  const { chats } = thread;

  if (chats === undefined) {
    return <div>Thread not found</div>;
  }

  return (
    <div>
      {chats.map(({ chat_id }) => (
        <div className="mb-4" key={chat_id}>
          <AnsweredQuestion chatId={chat_id} />
        </div>
      ))}
    </div>
  )
}
