import { useRef } from 'react';
import { IEventSource } from '@bluejlegal/event-source-reducer';
import { FetchEventSourceAdapter } from './fetch-event-source-adapter';

const IS_STRICT_MODE = process.env.NODE_ENV === 'development';

export function useGetEventSource<TPayload extends Record<string, unknown>>(url: string, payload: TPayload) {
  if (!IS_STRICT_MODE) return createAskGetEventSource(url, payload);

  const isFirst = useRef(true)

  return () => {
    if (isFirst.current) {
      isFirst.current = false;

      // we'll return a no-op one the first time
      return {
        close: () => {
        },
      } as IEventSource;
    }
    return createAskGetEventSource(url, payload)();
  };
}

function createAskGetEventSource<TPayload extends Record<string, unknown>>(url: string, payload: TPayload) {
  return () => {
    const body = JSON.stringify(payload);
    const headers = {
      'X-Ask-Blue-J-Request': 'true',
      'Content-Type': 'application/json',
    };

    return new FetchEventSourceAdapter(url, body, headers, false);
  }
}
