import { useEventSourceReducer } from '@bluejlegal/event-source-reducer';

import { useReducerLogger } from '../../../core/hooks/useReducerLogger';

import { useGetEventSource } from '../get-event-source';
import { ThreadedAskStreamMessages } from './messages';
import { createInitialState, filter, ThreadedAskStreamState, threadedStateReducer } from './reducer';
import { QuestionInteractionType, QuestionPayload } from './shared';

export function useStreamingAskQuestion(payload: QuestionPayload, apiUrl: string, interactionContext: QuestionInteractionType | null) {
  const initialState = createInitialState(payload, apiUrl, interactionContext);
  const { strategy } = payload;

  const getEventSource = useGetEventSource<QuestionPayload>(`/ask-sse/${strategy}`, payload);
  const reducer = useReducerLogger(threadedStateReducer);

  return useEventSourceReducer<ThreadedAskStreamState, ThreadedAskStreamMessages>(
    getEventSource,
    filter,
    reducer,
    initialState,
  );
}

