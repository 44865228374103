import React from 'react';
import ErrorSvg from '../../../assets/svg/error.svg?react';
import { Button } from '../../../components/button';

type ErrorFallbackProps = {
  errorText: string;
};

export function ErrorFallback({ errorText }: ErrorFallbackProps) {
  return (
    <div className="flex flex-col h-dynamic-full w-full">
      <div className="flex flex-1 flex-col items-center justify-center">
        <ErrorSvg />
        <div className="text-center mt-2">
          <h1 className="text-lg mb-4">{errorText}</h1>
          <Button
            colour="primary"
            className="px-3 py-1 mb-10"
            title="Refresh"
            type="submit"
            onClick={() => window.location.reload()}
            variant="contained"
          >
            Refresh
          </Button>
        </div>
        <div className="text-center mx-2">
          If this error persists, please&nbsp;
          <a
            href="https://support.bluej.com"
            target="_blank"
            title="Contact Support"
            className="text-blue-400 hover:underline"
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
}
