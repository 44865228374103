import React from 'react';
import { Navigate } from 'react-router-dom';

import { AdminRoutingWrapper } from './admin-entry';
import { useShowQuestionDebug } from './modules/core/feature-flag/features/use-question-debug';
import { sentryCreateBrowserRouter } from './modules/core/sentry/utils';
import { SettingsRoute } from './modules/settings/settings-route';
import { SourceRoute } from './modules/source/pages/source';
import { ActiveChatSourcePanelRoute } from './modules/thread/components/sources/active-chat-source-panel-route';
import { AdditionalSourcesPanelRoute } from './modules/thread/components/sources/additional-source-panel-route';
import { InlineSourcePanelRoute } from './modules/thread/components/sources/inline-source-panel-route';
import { SourcesPanelRoute } from './modules/thread/components/sources/sources-panel-route';
import { NoThreadRoute } from './modules/thread/pages/no-thread';
import { ThreadContainerRoute } from './modules/thread/pages/primary';
import { ThreadRoute } from './modules/thread/pages/thread';
import { TracePanelRoute } from './modules/trace/route';
import { ConditionalRoute } from './utils/conditional-route';

export function createRouter() {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: <ThreadContainerRoute />,
      children: [
        {
          path: '/:threadId',
          element: <ThreadRoute />,
          children: [
            {
              path: 'chat/:chatId/active/source',
              element: <ActiveChatSourcePanelRoute />
            },
            {
              path: 'chat/:chatId/inline-source',
              element: <InlineSourcePanelRoute />
            },
            {
              path: 'chat/:chatId/sources/:sourceIndex',
              element: <SourcesPanelRoute />
            },
            {
              path: 'chat/:chatId/additional-sources/:sourceIndex',
              element: <AdditionalSourcesPanelRoute />
            },
            {
              path: 'chat/:chatId/trace',
              element: <ConditionalRoute children={<TracePanelRoute />} predicate={useShowQuestionDebug} />
            },
          ],
        },
        {
          index: true,
          element: <NoThreadRoute />,
          path: '/',
        },
        {
          path: '/settings',
          element: <SettingsRoute />
        },
      ],
    },
    {
      path: '/source/*',
      element: <SourceRoute />
    },
    {
      path: '/admin/*',
      element: <AdminRoutingWrapper />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    },
  ]);
}
