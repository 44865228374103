import { SourceView, SourceViewListing } from '@/types';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import EventEmitter from 'eventemitter3';
import React, { useEffect } from 'react';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { SourceBadge } from './source-badge';
import { SourceDetailsTable } from './details-table';

type SourceHeaderProps = {
  sourceData: SourceView | SourceViewListing ;
  additionalSources: boolean;
  emitter?: EventEmitter;
}

type ContentMetadataProps = {
  metadata: Record<string, unknown>;
};

export function ContentMetaData({ metadata }: ContentMetadataProps) {
  if (!metadata || !metadata.breadcrumbs) {
    return null;
  }

  return (
    <div className="text-xs font-semibold inline">
      <div className="inline text-gray-900">{metadata.breadcrumbs as string}</div>
    </div>
  )
}

type SourceDetailsDisclosureProps = {
  onClick: () => void;
  showMeta: boolean;
  detailsTableData: Record<string, unknown>;
}

function SourceDetailsDisclosure(props: SourceDetailsDisclosureProps) {
  const { onClick, showMeta, detailsTableData } = props;

  if ((!detailsTableData || Object.keys(detailsTableData).length === 0)) return null;

  return (
    <Disclosure defaultOpen>
      <DisclosureButton className="text-sm text-blue-400 hover:underline underline-offset-4" onClick={onClick}>
        { showMeta ? 'Hide detail' : 'Show detail' }
      </DisclosureButton>
      <Transition
        as="div"
        show={showMeta}
        className="overflow-hidden"
        enter="transition transition-[max-height] duration-300 ease-in"
        enterFrom="transform max-h-0"
        enterTo="transform max-h-screen"
        leave="transition transition-[max-height] duration-300 ease-out"
        leaveFrom="transform max-h-screen"
        leaveTo="transform max-h-0"
      >
        <DisclosurePanel static>
          <SourceDetailsTable data={detailsTableData} />
        </DisclosurePanel>
      </Transition>
    </Disclosure>
  );
}

type MetadataProps = {
  metadata: Record<string, unknown>;
  onClick: () => void;
  showMeta: boolean;
  detailsTableData: Record<string, unknown>;
}

function Metadata({ metadata, showMeta, onClick, detailsTableData }: MetadataProps) {
  return (
    <>
      <ContentMetaData metadata={metadata} />
      <SourceDetailsDisclosure detailsTableData={detailsTableData} showMeta={showMeta} onClick={onClick} />
    </>
  );
}

// Type guard to check if the object is a full SourceView or just a SourceViewListing
function isSourceView(obj: unknown): obj is SourceView {
  return (obj as SourceView).description !== undefined;
}

export const SourceHeader: React.FC<SourceHeaderProps> = ({ sourceData, emitter, additionalSources }) => {
  const [showMeta, setShowMeta] = React.useState(true);
  const [isShowMetaFixed, setIsShowMetaFixed] = React.useState(false);
  const { trackSourceDetailsToggledOn, trackSourceDetailsToggledOff } = useAnalyticsEvent();

  const isSourceViewData = isSourceView(sourceData);
  const { title, badge, type } = sourceData;

  const metadata = isSourceViewData ? sourceData.metadata : null;
  const detailsTableData = isSourceViewData ? sourceData.detailsTableData : null;


  useEffect(() => {
    setIsShowMetaFixed(false);
    setShowMeta(true);
  }, [metadata]);

  const handleShowHideMeta = () => {
    if (!showMeta) {
      trackSourceDetailsToggledOn({ sourceType: type, additionalSources });
    } else {
      trackSourceDetailsToggledOff({ sourceType: type, additionalSources });
    }
    setIsShowMetaFixed(true);
    setShowMeta(!showMeta);
  }

  React.useEffect(() => {
    function handleEmit() {
      if (isShowMetaFixed) {
        return;
      }
      setShowMeta(false);
    }
    emitter?.addListener('scroll', handleEmit);
    return () => {
      emitter?.removeListener('scroll', handleEmit);
    };
  }, [emitter, isShowMetaFixed]);



  return (
    <div className="px-6 pb-2 border-b">
      <SourceBadge badge={badge} />
      <h2 className="font-semibold my-2">{title}</h2>

      {metadata && detailsTableData && (<Metadata
          metadata={metadata}
          onClick={handleShowHideMeta}
          showMeta={showMeta}
          detailsTableData={detailsTableData}
        />
      )}   
    </div>
  )
}
