import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SubscriptionSettings } from '../../admin/managers/subscriptions/data';
import { useAskBluejApiContext } from '../api/ask-bluej-api';

export function useSubscriptionSettingsQuery(): UseQueryResult<SubscriptionSettings> {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['subscription-settings'],
    queryFn: async () => {
      return await api.getSubscriptionSettings();
    },
    staleTime: Infinity,
    gcTime: Infinity
  });
}
