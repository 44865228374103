import { SourceViewListing } from '@/types';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@sentry/react';
import React from 'react';

import { Button } from '../../../components/button';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { useChatContext } from '../contexts/ChatContext';
import { usePrintPage } from '../hooks/usePrintPage';
import { CopyToClipboard } from './copy-to-clipboard';
import { replaceInlineCitationsWithPlainText } from './inline-citations/replace-inline-citations';
import { formatForPrint } from './utils/formatForPrint';
import { backCompatUrl } from './sources/external-badge';

export const Share = withErrorBoundary(() => {
  const { chat, shareVisible } = useChatContext();

  const answer = chat.originalAnswer || chat.answer;

  const { trackShareCopy, trackSharePrint } = useAnalyticsEvent();
  let formattedSources = '';
  if (chat.sources && chat.sources.length > 0) {
    formattedSources = `\n\nSources:\n\n${chat.sources.map((source, index) => {
      if (source.badge === 'commentary') {
        const url = getUrl(source);
        return `[${index + 1}] ${source.title}: ${url}\n`;
      } else if (source.externalUrl) {
        const url = getUrl(source);
        return `[${index + 1}] ${url}\n`;
      }
      return `[${index + 1}] ${source.title}: ${window.location.origin}${source.href}\n`;
    }).join('\n')}`;
  }
  const content = `${chat.question}\n\n${answer}${formattedSources}`;

  const { handlePrint: handlePrintPage } = usePrintPage(chat.question, formatForPrint(chat.question, answer, chat.sources));
  const handlePrint = () => {
    trackSharePrint();
    handlePrintPage();
  }

  if (!shareVisible) {
    return null;
  }

  return (
    <>
      <div className="flex align-center">
        <CopyToClipboard content={replaceInlineCitationsWithPlainText(content, chat.sources)} trackCopyToClipboard={() => trackShareCopy()} />
        <Button
          colour="secondary"
          className="hidden lg:flex py-1 px-1"
          onClick={handlePrint}
          title="Print"
          variant="icon"
        >
          <PrinterIcon className="w-6 h-6"/>
        </Button>
      </div>
    </>
  )
}, {});

// FIXME (backwards-compat)
function getUrl(source: SourceViewListing) {
  if (source.externalUrl) {
    return backCompatUrl(source.externalUrl ?? source.href);
  }

  return `${window.location.origin}${source.href}`;
}
