import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { backCompatUrl } from './external-badge';

type Props = {
  href: string | null;
  onClick: () => void;
}

export function ExternalLinkButton({ href, onClick }: Props) {
  if (!href) return null;

  return (
    <Link
      title="Open source in new tab"
      type="button"
      target="_blank"
      className="rounded-md text-blue-400 focus:outline-none focus-visible:ring-2 inline-flex align-sub ml-2"
      to={backCompatUrl(href)}
      onClick={onClick}
      rel="noopener noreferrer"
    >
      <ArrowTopRightOnSquareIcon className="w-4 h-4" aria-hidden="true"/>
    </Link>
  )
}
