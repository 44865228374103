import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorFallback } from '../../../core/errors/error-fallback';
import { SourceContentQueryProps, useSourceContentQuery } from '../../useSourceContentQuery';
import { Panel } from '../panel';
import { SourcesViewErrorComponent } from './sources-panel-route';
import { SourcesView, SourceViewLoadingComponent } from './sources-view';
import { SourceViewListing } from '@/types';

type Params = {
  threadId: string;
  chatId: string;
}
export function InlineSourcePanelRoute() {
  const { threadId, chatId } = useParams<Params>();
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state?.inlineSource || !threadId || !chatId) {
    navigate(-1);
    return null;
  }

  const handleOnClose = () => {
    navigate(`/${threadId}`);
  }

  return (
    <Panel animate={true} onClose={handleOnClose}>
      <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
        <InlineSourceComponent source={state.inlineSource} href={state.inlineHref} />
      </ErrorBoundary>
    </Panel>
  )
}

function InlineSourceComponent({ source, href }: { source: SourceContentQueryProps, href: string }) {
  const { data, status } = useSourceContentQuery(source);

  if (status === 'pending') {
    return (
      <div className="flex flex-col overflow-hidden w-full">
        <div className="border-b shrink-0 h-36"/>
        <SourceViewLoadingComponent/>
      </div>
    )
  }

  if (status === 'error' || !data) {
    return (
      <div className="flex flex-col overflow-hidden w-full">
        <div className="shrink-0 h-[25%]"/>
        <SourcesViewErrorComponent/>
      </div>
    )
  }

  // FIXME I don't know what's going on here, but this doesn't seem right, why does InlineSourceComponent fetch a SourceView but pass it as a Listing?
  //  can this be solved by returning 'href' from the backend on a SourceView?
  return (
    <SourcesView sourceViewListing={{ ...data, href } as SourceViewListing} />
  )
}
