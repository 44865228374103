export function getAnalyticsEndpointsForEnv(env: string) {
  // Sending sandbox (preview builds for pull requests) to dev in lieu of having a sandbox env for the events proxy
  const analyticsEnvironment = getAnalyticsEnv(env);

  if (analyticsEnvironment === 'prod') {
    return {
      cdn: `events-cdn.askbluej.com`,
      api: `events-api.askbluej.com`
    };
  }

  return {
    cdn: `events-cdn.${analyticsEnvironment}.askbluej-dev.com`,
    api: `events-api.${analyticsEnvironment}.askbluej-dev.com`
  };
}

export function getAnalyticsEnv(env: string) {
  // Defaulting to `prod` to ensure it points to a known good address until we're flipped over to the new proxy
  //   As well, segment is configured to serve things on the prod domains when not specified
  return ['dev', 'stg', 'prod'].includes(env) ? env : 'prod';
}
