import React from 'react';
import { useMobileKeyboardOpen } from './useMobileKeyboardOpen';

export function useHandleEnterKeySubmit(props: HandleEnterKeySubmitProps): React.KeyboardEventHandler {
  const { onSubmit } = props;
  const isMobileKeyboardOpen = useMobileKeyboardOpen();

  return React.useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (isMobileKeyboardOpen) return;
      event.preventDefault();
      onSubmit();
    }
  }, [onSubmit, isMobileKeyboardOpen]);
}

type HandleEnterKeySubmitProps = {
  onSubmit: () => void;
}