import React, { useEffect, useRef } from 'react';
import { Highlighter } from './Highlighter';
import { useLocation } from 'react-router-dom';

type Props = {
  content: string;
  source: string;
  onContentReady?: (element: HTMLDivElement | null) => void;
  skipNextEmit?: () => void;
}

export function SourceHighlighter({ content, onContentReady, source, skipNextEmit }: Props) {
  const [result, setResult] = React.useState<string>(source);
  const resultRef = useRef<HTMLDivElement | null>(null);

  // if the source viewed is an inline source, need to scroll to the target
  const { state } = useLocation();
  const inlineSourceHref = state?.inlineHref ? state?.inlineHref?.split(/(?=#)/)[1] : undefined;
  const scrollToTop = state?.scrollToTop;
  const replaceLinksAndScrollToTarget = (element: HTMLDivElement | null) => {
    if (!element) {
      return;
    }

    onContentReady && onContentReady(element);
    resultRef.current = element;

    const target = resultRef.current?.querySelector(inlineSourceHref ?? '#askbluej-highlight-start');
    if (target && !scrollToTop) {
      skipNextEmit && skipNextEmit();
      target.scrollIntoView();
    }
  };

  useEffect(() => {
    const highlighter = new Highlighter(source, content);
    const highlightedSource = highlighter.highlight();
    setResult(highlightedSource);
  }, [source, content]);

  if (!content) {
    console.warn('No content to highlight');
    return <div
      className="vendor-content"
      dangerouslySetInnerHTML={{ __html: source }}
      ref={replaceLinksAndScrollToTarget}
    />
  }

  return (
    <div
      className="vendor-content"
      dangerouslySetInnerHTML={{ __html: result ?? source }}
      ref={replaceLinksAndScrollToTarget}
    />
  );
}
