import { useForm } from 'react-hook-form';

import { Button } from '../../../../components/button';
import { FeedbackType } from '../../types';

interface CommentFormValues {
  comment: string;
}

interface CommentFormProps {
  type: FeedbackType;
  onSubmit: (type: FeedbackType, comment: string) => void;
}

export default function CommentForm(props: CommentFormProps) {
  const { onSubmit, type } = props;
  const { register, handleSubmit } = useForm<CommentFormValues>();

  const handleFormSubmit = (values: CommentFormValues) => {
    onSubmit(type, values.comment);
  }

  const feedbackHeadline = type === 'agree' ? 'Provide feedback' : 'Help us improve';
  const feedbackBody = (
    <>
      Our legal team will see the questions and answers in this thread when you submit feedback.
      {type === 'disagree' && (
        <>
          <br /><br />
          Please be as specific as possible in how we can improve. For example: missing a source, or using an outdated source.
        </>
      )}
    </>
  );

  return (
    <div className="bg-white p-3 rounded-lg w-96 border border-grey-3gi00 shadow-xl max-w-[85vw] sm:max-w-[326px]">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <label htmlFor="feedback-comment" className="text-black-400 font-semibold block mb-2">{feedbackHeadline}</label>
        <p className="text-xs mb-4 text-black-400">
          {feedbackBody}
        </p>
        <textarea id="feedback-comment"
                  autoFocus={true} {...register('comment')}
                  className="block h-28 p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"/>
        <Button colour="primary" title="Submit Feedback" type="submit" variant="contained" size="small" className="px-2 py-2">
          Submit Feedback
        </Button>
      </form>
    </div>
  )
}
