import React, { ComponentProps, JSX, PropsWithChildren } from 'react';
import { FlagKey } from '@/types';
import { ExperimentWrapper } from './experiment-wrapper';
import { ErrorBoundary } from '@sentry/react';

const featureSym = Symbol('_flaggedFeature');

type FlaggedExperimentOptions = {
  withErrorReporting?: boolean;
};

export function withFlag<
  ElementType extends React.ElementType,
  ElementProps extends ComponentProps<PropsWithChildren<ElementType>>
>(
  Element: ElementType,
  featureKey: FlagKey,
  defaultVisibility = false,
  options: FlaggedExperimentOptions = {},
): WrappedComponent<ElementProps> {
  const { withErrorReporting = true } = options;
  const wrappedComponent = (props: ElementProps) => {
    const element = <Element {...props} />;
    return (
      <ErrorBoundary showDialog={withErrorReporting}>
        <ExperimentWrapper element={element} featureKey={featureKey} defaultVisibility={defaultVisibility}>
          {props.children}
        </ExperimentWrapper>
      </ErrorBoundary>
    );
  };
  wrappedComponent.displayName = `WrappedExperiment(${featureKey})`;
  wrappedComponent[featureSym] = {
    featureKey,
    defaultVisibility,
  } as WrappedComponentInfo;

  return wrappedComponent;
}

export function getWrappedExperimentComponentInfo(component: React.ElementType) {
  if (isWrappedWithFlag(component)) {
    return component[featureSym];
  }

  return {
    defaultVisibility: false,
    featureKey: 'Unknown',
  }
}

export function isWrappedWithFlag(component: unknown): component is WrappedComponent<unknown> {
  return component !== null && Object.getOwnPropertySymbols(component).includes(featureSym);
}

export type WrappedComponentInfo = {
  featureKey: FlagKey;
  defaultVisibility: boolean;
};

export type WrappedComponent<Props> = {
  (props: PropsWithChildren<Props>): JSX.Element;
  [featureSym]: WrappedComponentInfo;
};
