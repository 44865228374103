import React from 'react';

type SourceBadgeProps = {
  badge?: string;
}

export const SourceBadge: React.FC<SourceBadgeProps> = ({ badge }) => {
  if (!badge) return null;

  return (
    <div className="print-bg inline-block font-medium text-xxs px-2 py-1 rounded-xl text-black-600 leading-3 bg-blue-200">
      {badge.toUpperCase()}
    </div>
  )
}
