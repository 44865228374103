import { fromHtml } from 'hast-util-from-html';
import { toMarkdown } from 'mdast-util-to-markdown';
import { toMdast } from 'hast-util-to-mdast';
import { gfmFromMarkdown, gfmToMarkdown } from 'mdast-util-gfm';
import { toHtml } from 'hast-util-to-html'
import { fromMarkdown } from 'mdast-util-from-markdown'
import { toHast } from 'mdast-util-to-hast'
import { gfm } from 'micromark-extension-gfm';
import { toText } from 'hast-util-to-text';

export function extractTextFromHtml(data: string) {
  const tree = fromHtml(data);
  const mdTree = toMdast(tree);
  return toMarkdown(mdTree, {
    emphasis: null,
    handlers: {
      link: (node) => {
        if (node.children.length === 1 && node.children[0].type === 'text') {
          return node.children[0].value;
        }
        return node.url;
      }
    },
    extensions: [
      gfmToMarkdown()
    ]
  }).trim();
}

export function convertMarkdownToHtml(data: string) {
  const tree = fromMarkdown(data, {
    extensions: [gfm()],
    mdastExtensions: [
      gfmFromMarkdown()
    ]
  });
  const hast = toHast(tree);
  return toHtml(hast);
}

export function convertMarkdownToText(data: string) {
  const tree = fromMarkdown(data, {
    extensions: [gfm()],
    mdastExtensions: [
      gfmFromMarkdown()
    ]
  });
  const hast = toHast(tree);
  return toText(hast);
}
