import { SourceView } from '@/types';
import React, { useCallback, useEffect } from 'react';

import { useConfigContext } from '../../config/ConfigContext';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { IDK_COMPLETION_DELAY_MS } from '../../thread/components/active-question-view/progress/shared';
import { isNoAnswer } from '../../ask-bluej';
import { AskDocStreamState } from '../../ask-bluej/streaming/doc/reducer';
import { useStreamingAskDoc } from '../../ask-bluej/streaming/doc/useStreamingAskDoc';
import { AskDocInteractionType, AskDocPayload, AskDocSubmit } from '../../ask-bluej/streaming/doc/shared';
import { AnswerPanel } from './answer-panel';

export function ActiveDocQuestion(props: Props) {
  const {
    payload,
    onClose,
    render: RenderComponent,
    interactionContext,
    sourceView,
    onResubmit,
    displayQuestion,
    additionalSources
  } = props;
  const { apiUrl } = useConfigContext();
  const state = useStreamingAskDoc(payload, apiUrl, interactionContext);
  const { trackDocQuestionUnableToAnswerReceived } = useAnalyticsEvent();

  const handleStateUpdated = useCallback(async (state: AskDocStreamState) => {
    if (state.state === 'complete' || state.state === 'error') {
      if (state.state === 'complete' && isNoAnswer(state.answer)) {
        trackDocQuestionUnableToAnswerReceived({
          interactionContext: state.interactionContext,
          questionCharacterCount: state.payload.question.length,
          sourceType: sourceView.type,
          sourceTitle: sourceView.title,
          sourceId: sourceView.id,
          additionalSources
        });
        // If we're getting an IDK we want to wait a bit before completing, otherwise things can flip really
        await new Promise((resolve) => setTimeout(resolve, IDK_COMPLETION_DELAY_MS));
      }
    }
  }, []);

  useEffect(() => {
    void handleStateUpdated(state);
  }, [state, handleStateUpdated]);

  return (
    <AnswerPanel question={displayQuestion} onClose={onClose} interactionContext={interactionContext} state={state} sourceView={sourceView} animate={!payload.isRetry} additionalSources={additionalSources}>
      <RenderComponent state={state} onResubmit={onResubmit} />
    </AnswerPanel>
  );
}

type Props = {
  payload: AskDocPayload;
  render: React.ElementType<RenderProps>;
  onClose: () => void;
  interactionContext: AskDocInteractionType;
  sourceView: SourceView;
  onResubmit: AskDocSubmit;
  displayQuestion: string;
  additionalSources?: boolean;
}

export type RenderProps = {
  state: AskDocStreamState;
  onResubmit: AskDocSubmit;
}
