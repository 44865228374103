import { Config, User } from '@/types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import { ConfigContextProvider } from '../../config/ConfigContext';
import { MobileContextProvider } from '../../thread/contexts/MobileHeaderContext';
import { SourceApiContextProvider } from '../../thread/contexts/SourceApiContext';
import { ISourcesApi } from '../../thread/SourcesApi';
import { StrategyContextProvider } from '../../thread/contexts/StrategyContext';
import { AnalyticsProvider } from '../analytics/context';
import { AskBlueJApiContextProvider, IAskBlueJApi } from '../api/ask-bluej-api';
import { FeatureFlagProvider } from '../feature-flag/context';
import { SentryContextProvider, SentryService } from '../sentry/context';
import { UserProvider } from '../user/context';
import { DevToolsProvider } from './debug-provider';
import { AppLoadTracker } from './load-tracker';

type AppProviderProps = React.PropsWithChildren<{
  askBlueJApi: IAskBlueJApi;
  sourcesApi: ISourcesApi;
  queryClient: QueryClient;
  user: User;
  config: Config;
  sentryService: SentryService;
}>;

export const AppProvider = (props: AppProviderProps) => {
  const sourcesApiContextValue = {
    api: props.sourcesApi
  };

  const askBlueJApiContextValue = {
    api: props.askBlueJApi
  };

  const sentryContextValue = {
    sentry: props.sentryService
  };

  return (
    <QueryClientProvider client={props.queryClient}>
      <AskBlueJApiContextProvider value={askBlueJApiContextValue}>
        <UserProvider user={props.user}>
          <ConfigContextProvider value={props.config}>
            <FeatureFlagProvider clientId={props.config.featureFlag.clientId}>
              <AnalyticsProvider>
                <DevToolsProvider>
                  <SourceApiContextProvider value={sourcesApiContextValue}>
                    <SentryContextProvider value={sentryContextValue}>
                      <MobileContextProvider>
                        <StrategyContextProvider>
                          <AppLoadTracker/>
                          {props.children}
                        </StrategyContextProvider>
                      </MobileContextProvider>
                    </SentryContextProvider>
                  </SourceApiContextProvider>
                </DevToolsProvider>
              </AnalyticsProvider>
            </FeatureFlagProvider>
          </ConfigContextProvider>
        </UserProvider>
      </AskBlueJApiContextProvider>
    </QueryClientProvider>
  );
}
