import React, { useCallback, useRef, useState } from 'react';

import { QuestionInteractionType } from '../../../ask-bluej/streaming/threaded/shared';
import { useShowStrategyDropdown } from '../../../core/feature-flag/features/use-show-strategy-dropdown';
import { useStrategyContext } from '../../contexts/StrategyContext';

import { QuickPrompts } from '../quick-prompts';
import { StrategyDropdown } from '../strategy-dropdown';
import { InputFrame, InputVariant } from './input-frame';
import { UserPromptInput, UserPromptInputRef } from './user-prompt-input';
import { INPUT_VALID, InputState, validateUserInput } from './validation';
import { ViewSavedPromptsButton } from '../../../saved-prompts/view-saved-prompts-button';
import { SavedPromptPanel } from '../../../saved-prompts/saved-prompt-panel';


type Props = {
  onSubmit: (question: string, interactionContext: QuestionInteractionType, strategy?: string) => Promise<boolean>;
  disabled?: boolean;
  variant?: InputVariant;
  existingThreadTopic?: string;
  numChats?: number;
  autoFocusUserInput?: boolean;
  input?: string;
}

export function ThreadedQuestionInput(props: Props) {
  const {
    onSubmit,
    disabled,
    variant = 'normal',
    autoFocusUserInput = false,
    existingThreadTopic,
    numChats = 0,
    input = ''
  } = props;

  const [waitingForSubmit, setWaitingForSubmit] = useState(false);
  const showStrategyDropdown = useShowStrategyDropdown();
  const { selectedStrategy, setSelectedStrategy } = useStrategyContext();
  const [isParentFocused, setIsParentFocused] = useState(false);
  const [inputState, setInputState] = useState<InputState>(INPUT_VALID);
  const [promptsVisible, setPromptsVisible] = useState(false);

  const promptInputRef = useRef<UserPromptInputRef | null>(null);

  const submit = React.useCallback(async (prompt: string, context: QuestionInteractionType) => {
    if (prompt.trim() === '') {
      return false;
    }

    setWaitingForSubmit(true);

    // TODO: We can lift this logic and the strategy selector outside of this component
    //   - Rationale: this component can become more "dumb", no knowledge of strategy, and may become reusable for ask a doc
    let strategyOverride = '';
    if (showStrategyDropdown) {
      strategyOverride = selectedStrategy || '';
    }

    // Allow the caller to do something after submission
    return onSubmit(prompt, context, strategyOverride).then((success) => {
      setWaitingForSubmit(false);
      return success;
    });
  }, [onSubmit, selectedStrategy, showStrategyDropdown, existingThreadTopic]);

  const onSelectStrategy = useCallback((strategy: string) => {
    setSelectedStrategy(strategy);
  }, []);

  const handleClickPrompt = React.useCallback((prompt: string, context: QuestionInteractionType) => {
    void submit(prompt, context);
  }, [submit]);

  const handleFocusWithinWrapper = () => setIsParentFocused(true);

  const handleBlurWithinWrapper = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsParentFocused(false);
    }
  };

  const handleUserInputSubmit = React.useCallback((value: string) => {
    return submit(value, QuestionInteractionType.QUESTION_INPUT);
  }, [submit]);

  const handleUserInputChange = React.useCallback((value: string) => {
    const trimmedValue = value.trim();
    setInputState(validateUserInput(trimmedValue));
  }, []);

  const userPromptInputDisabled = disabled || waitingForSubmit || !inputState.valid;

  const handleShowPrompts = () => {
    setPromptsVisible(true);
  }

  const handleHidePrompts = () => {
    setPromptsVisible(false);
  }

  const handleUpdatePrompt = (prompt: string) => {
    if (promptInputRef.current !== null) {
      promptInputRef.current.updatePrompt(prompt);
    }
    handleHidePrompts();
  }

  const promptsDisabled = disabled || numChats === 0;
  return (
    <>
      <div onFocus={handleFocusWithinWrapper} onBlur={handleBlurWithinWrapper}>
        <InputFrame state={inputState} variant={variant}>
          <div className="flex justify-end mx-auto mb-2 -mt-2 w-full items-center">
            <QuickPrompts
              onSubmit={handleClickPrompt}
              disabled={promptsDisabled}
              numChats={numChats}
              existingThreadTopic={existingThreadTopic}
              isParentFocused={isParentFocused}
            >
              <ViewSavedPromptsButton disabled={promptsDisabled} showPrompts={handleShowPrompts} />
            </QuickPrompts>
          </div>
          <div className="flex flex-row justify-start mx-auto w-full items-center gap-4">
            <div className="flex-1">
              <UserPromptInput
                onSubmit={handleUserInputSubmit}
                onChange={handleUserInputChange}
                disabled={userPromptInputDisabled}
                autoFocus={autoFocusUserInput}
                input={input}
                ref={promptInputRef}
              />
            </div>
            <div>
              {showStrategyDropdown &&
                <StrategyDropdown onSelectStrategy={onSelectStrategy} defaultValue={selectedStrategy}/>}
            </div>
          </div>
        </InputFrame>
      </div>
      { promptsVisible && <SavedPromptPanel updatePrompt={handleUpdatePrompt} onClose={handleHidePrompts} /> }
    </>
  );
}
