import React from 'react';

import { Button } from '../../../../components/button';
import ErrorSvg from '../../../../assets/svg/error.svg?react'
import { AnswerMarkdown } from '../answer-markdown';
import { ReadOnlyInlineCitation } from '../inline-citations/inline-citation-link';

type Props = {
  answer: string;
  resubmit: () => void;
}

export function QuestionError({ answer, resubmit }: Props) {
  return (
    <div className="flex md:items-center gap-4 md:gap-0 w-full flex-col md:flex-row">
      <div className="w-12 text-grey-600 flex justify-center mr-6 align-top h-full">
        <ErrorSvg />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-1 min-w-0 justify-center flex-col">
          <AnswerMarkdown answer={answer} renderInlineCitation={ReadOnlyInlineCitation} />
        </div>
        <div className="align-self-center">
          <Button
            className="py-1 px-3"
            colour="primary"
            onClick={resubmit}
            title="Retry"
            type="submit"
            variant="contained"
          >
            Retry
          </Button>
        </div>
      </div>
    </div>
  )
}
