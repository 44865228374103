import { SourceView } from '@/types';
import React from 'react';

import { AskDocInteractionType, AskDocPayload, AskDocSubmit } from '../../ask-bluej/streaming/doc/shared';
import { ActiveDocQuestion } from './active-doc-question';
import { ActiveDocQuestionRender } from './active-doc-question-render';

type ActiveQuestionInitiatorProps = {
  payload: AskDocPayload | null;
  interactionContext: AskDocInteractionType;
  onClose: () => void;
  sourceView: SourceView;
  onResubmit: AskDocSubmit;
  displayQuestion: string;
  additionalSources?: boolean;
}

export function AskDocQuestionInitiator(props: ActiveQuestionInitiatorProps) {
  const { payload, interactionContext, displayQuestion, sourceView, onClose, onResubmit, additionalSources } = props;

  if (payload === null) {
    return null;
  }

  return (
    <ActiveDocQuestion
      payload={payload}
      onClose={onClose}
      interactionContext={interactionContext}
      render={ActiveDocQuestionRender}
      sourceView={sourceView}
      onResubmit={onResubmit}
      displayQuestion={displayQuestion}
      additionalSources={additionalSources}
    />
  );
}
