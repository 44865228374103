import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useCallback } from 'react';

import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { useChatContext } from '../contexts/ChatContext';
import { useThreadContext } from '../contexts/ThreadContext';
import { useChatDeleteQuery } from '../useChatDeleteQuery';
import { ConfirmDelete } from './confirm-delete';

export function DeleteChat() {
  const { thread } = useThreadContext();
  const { chat } = useChatContext();

  const { trackQuestionDeleted, trackQuestionDeleteInitiated } = useAnalyticsEvent();
  const { deleteChat } = useChatDeleteQuery(thread.id, chat.chat_id);

  const handleDeleteItem = useCallback(() => {
    trackQuestionDeleted();
    void deleteChat(thread.id, chat.chat_id);
  }, [chat.chat_id, deleteChat, thread.id]);

  const handleOnClick = useCallback(() => {
    trackQuestionDeleteInitiated();
  }, [thread, chat]);

  return (
    <div className="hidden lg:flex flex-row self-center gap-1 lg:gap-0 w-12 align-middle">
      <ConfirmDelete
        onConfirm={handleDeleteItem}
        title={'Delete Question'}
        prompt="Are you sure you want to delete this question?"
        description="Deleting this question will also delete the associated response."
        includeCancelButton
        alwaysVisible
      >
        <div className="text-grey-600 hover:text-blue-400 items-center" onClick={handleOnClick}>
          <TrashIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block" />
        </div>
      </ConfirmDelete>
    </div>
  )
}
