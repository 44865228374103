import React from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';

import { ThreadView } from '../components/thread-view';

type Params = {
  threadId: string;
}

export function ThreadRoute() {
  const { threadId } = useParams<Params>();
  const navigate = useNavigate();

  // TODO: Generic 'Thread not found' page

  React.useEffect(() => {
    if (!threadId) {
      navigate('/');
    }
  }, [threadId]);

  if (!threadId) {
    return null;
  }

  return (
    <>
      <ThreadView threadId={threadId} />
      <Outlet />
    </>
  )
}
