import React, { useCallback } from 'react';
import {
  createAbortedStateFromExisting,
  ThreadedAskStreamAbortedState,
  ThreadedAskStreamState
} from '../../../ask-bluej/streaming/threaded/reducer';
import { QuestionInteractionType, QuestionPayload } from '../../../ask-bluej/streaming/threaded/shared';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { GlobalAnalyticsContextProvider } from '../../contexts/GlobalAnalyticsContext';
import ActiveQuestion from './active-question';
import { ActiveQuestionRender } from './active-question-render';

type ActiveQuestionInitiatorProps = {
  payload: QuestionPayload | null;
  onComplete: (state: ThreadedAskStreamState) => void;
  onAbort: (state: ThreadedAskStreamAbortedState) => void;
  resubmitQuestion: (question: string, interactionContext: QuestionInteractionType, strategy?: string) => Promise<boolean>;
  interactionContext: QuestionInteractionType | null;
}

export function ActiveQuestionInitiator(props: ActiveQuestionInitiatorProps) {
  const { payload, resubmitQuestion, onComplete, onAbort, interactionContext } = props;
  const { trackAnswerGenerationEnded } = useAnalyticsEvent();

  const handleResubmitQuestion = useCallback((question: string, strategy?: string) => {
    void resubmitQuestion(question, QuestionInteractionType.RESUBMIT, strategy);
  }, [resubmitQuestion]);

  const handleAbort = useCallback((state: ThreadedAskStreamState) => {
    const abortedState = createAbortedStateFromExisting(state);

    // Currently, these are separate from useAskQuestionAnalytics because aborting isn't a state it will see
    if (state.state === 'initiated') {
      trackAnswerGenerationEnded({
        threadId: abortedState.payload.threadId,
        chatId: abortedState.payload.chatId,
        answerStrategy: abortedState.payload.strategy,
        elapsedTime: 0,
        characterCount: 0,
        reason: 'aborted',
        sources: state.sources
      });
    } else if (state.state === 'answering') {
      trackAnswerGenerationEnded({
        threadId: abortedState.payload.threadId,
        chatId: abortedState.payload.chatId,
        answerStrategy: abortedState.payload.strategy,
        elapsedTime: abortedState.answerStreamingEndedTimestamp - abortedState.answerStreamingStartedTimestamp,
        characterCount: abortedState.answer.length,
        reason: 'aborted',
        sources: state.sources
      });
    }
    onAbort(abortedState);
  }, [trackAnswerGenerationEnded, onAbort]);

  if (payload === null) {
    return null;
  }

  return (
    <GlobalAnalyticsContextProvider value={{ chatId: payload.chatId, answerStrategy: payload.strategy }}>
      <ActiveQuestion
        payload={payload}
        onComplete={onComplete}
        onAbort={handleAbort}
        resubmitQuestion={handleResubmitQuestion}
        interactionContext={interactionContext}
        render={ActiveQuestionRender}
      />
    </GlobalAnalyticsContextProvider>
  )
}

