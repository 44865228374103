import { Thread } from '@/types';
import { InfiniteData, keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { FOUR_HOURS_MS } from './constants';

export function useChatQuery(threadId: string, chatId: string) {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['threads', threadId, 'chats', chatId],
    queryFn: async () => {
      return api.getChat(threadId, chatId);
    },
    initialData: () => {
      const infiniteData: InfiniteData<Thread> | undefined = queryClient.getQueryData(['threads', threadId]);
      const chats = infiniteData?.pages.flatMap((thread) => thread.chats) ?? [];
      return chats.find(({ chat_id }) => chat_id === chatId);
    },
    placeholderData: keepPreviousData,
    initialDataUpdatedAt: () => {
      return queryClient.getQueryState(['threads', threadId])?.dataUpdatedAt ?? 0;
    },
    staleTime: FOUR_HOURS_MS,
    gcTime: FOUR_HOURS_MS
  });
}
