import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import React, { PropsWithChildren, Suspense } from 'react';

import { FeedbackType } from '../../types';
import CommentForm from './comment-form';

interface CommentSubmitProps {
  type: FeedbackType;
  onSubmit: (type: FeedbackType, comment: string) => void;
  title: string;
}

export default function CommentSubmit(props: PropsWithChildren<CommentSubmitProps>) {
  const { children, onSubmit, type, title } = props;

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(16),
      shift({
        padding: 16,
        crossAxis: true,
        altBoundary: true
      }),
    ],
    placement: 'right',
    strategy: 'fixed'
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton className="items-center flex focus:outline-none focus-visible:ring-2" title={title}>
            <span ref={refs.setReference}>
              {children}
            </span>
          </PopoverButton>
          {open && (
            <div className="z-10 relative" ref={refs.setFloating} style={floatingStyles}>
              <Transition
                enter="ease-in-out duration-200"
                enterFrom="opacity-0 translate-y-20"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-20"
              >
                <PopoverPanel>
                  <Suspense fallback={null}>
                    <CommentForm type={type} onSubmit={onSubmit} />
                  </Suspense>
                </PopoverPanel>
              </Transition>
            </div>
          )}
        </>
      )}
    </Popover>
  );
}
