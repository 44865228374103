import { Thread } from '@/types';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { DEFAULT_NUM_CHATS_PER_THREAD } from './constants';

export function useThreadQuery(threadId: string) {
  const { api } = useAskBluejApiContext();

  return useInfiniteQuery<Thread, Error>({
    placeholderData: keepPreviousData,
    queryKey: ['threads', threadId],
    queryFn: async ({ pageParam }) => await api.getThread(threadId, Number(pageParam), DEFAULT_NUM_CHATS_PER_THREAD),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      return lastPage.chats.length < DEFAULT_NUM_CHATS_PER_THREAD ? undefined : Number(lastPageParam) + 1;
    },
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse()
    })
  });
}
