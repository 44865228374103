import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DocumentVerified from '../../../../assets/svg/document-verified.svg?react';
import Gavel from '../../../../assets/svg/gavel.svg?react';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { AnswerLink } from '../../components/answer-markdown';
import { Tip, tips } from './constants';
import { BlockQuote, Header2, PromptTipsModal } from './prompt-tips-modal';

const tiles = [
  {
    text: 'Answers should not be interpreted as legal advice.',
    image: Gavel
  },
  {
    text: 'All responses should be reviewed for accuracy.',
    image: DocumentVerified
  }
];

type TileProps = {
  index: number;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

function Tile({ index, image, text }: TileProps) {
  return (
    <div
      key={index}
      className={clsx("flex border border-grey-300 p-3 md:p-4 bg-grey-200 rounded-2xl w-full items-center lg:justify-center", {
        "mt-2": index !== 0
      })}
    >
      { React.createElement(image, { className:'size-10 lg:size-12 object-fit' }) }
      <p className="text-sm pl-5">{ text }</p>
    </div>
  )
}

export const PromptTips = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const { trackTipsNextClicked, trackTipsBackClicked } = useAnalyticsEvent();

  const navigate = (direction: 'next' | 'prev') => {
    const offset = direction === 'next' ? 1 : -1;
    const nextTipIndex = (currentItem + offset + tips.length) % tips.length;
    const trackEvent = direction === 'next' ? trackTipsNextClicked : trackTipsBackClicked;
    trackEvent({
      fromCard: currentItem + 1,
      fromCardTitle: tips[currentItem].title,
      toCard: nextTipIndex + 1,
      toCardTitle: tips[nextTipIndex].title
    });
    setCurrentItem(nextTipIndex);
  }

  return (
    <div className="flex flex-col lg:flex-row py-4 md:px-10 max-w-5xl gap-4 align-middle justify-center mx-auto w-full">
      <div className="border relative w-full border-grey-300 px-2 pt-4 pb-2 rounded-2xl bg-grey-200 overflow-hidden">
        <div className="flex flex-row items-center">
          <div className="-left-2 relative">
            <button title="Previous tip" className="p-1" onClick={() => navigate('prev')}>
              <ChevronLeftIcon className="size-8 text-blue-500" />
            </button>
          </div>
          {
            /*
              - Items are laid out in a 1 col/row grid and stacked using col/row-start-1
              - Items are hidden visually, allowing them to be responsive and contribute to the final layout when not visible
            */
          }
          <ul className="grid grid-cols-1 grid-rows-1 flex-1">
            {
              tips.map((tip, index) => {
                const classes = clsx('col-start-1', 'row-start-1', {
                  'block': index === currentItem,
                  'invisible': index !== currentItem,
                });
                return (
                  <li key={tip.title} className={classes}>
                    <TipCard key={tip.title} tip={tip} />
                  </li>
                );
              })
            }
          </ul>
          <div className="-right-2 relative">
            <button title="Next tip" className="p-1" onClick={() => navigate('next')}>
              <ChevronRightIcon className="size-8 text-blue-500" />
            </button>
          </div>
        </div>
        <div className="text-center">
          {tips.map((_, index) => (
            <div
              key={index}
              className={clsx("size-1.5 rounded-full duration-1000 inline-block", {
                "bg-grey-400": index !== currentItem,
                "bg-blue-500": index === currentItem,
                "mr-1": index !== tips.length - 1
              })}
            />
          ))}
        </div>
      </div>
      <div className="lg:flex-row grid grid-cols-1">
        {tiles.map((tile, index) => Tile({ ...tile, index }))}
      </div>
    </div>
  );
};

function TipCard({ tip }: TipCardProps) {
  const { title, description, examplePrompts } = tip;
  const { trackTipsExampleClicked } = useAnalyticsEvent();
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    trackTipsExampleClicked(tip.title);
    setModalVisible(true);
  };

  return (
    <>
      <div className="font-semibold text-sm mb-4 relative">
        <SparklesIcon className="size-6 absolute -left-9 text-blue-500" />
        {title}
      </div>
      <ReactMarkdown
        components={{
          h2: Header2,
          blockquote: BlockQuote,
          a: ({ ...props }) => React.createElement(AnswerLink, props)
        }}
      >
        {description}
      </ReactMarkdown>
      { examplePrompts && (
        <>
          <button onClick={showModal} className="mt-3 p-2 font-semibold rounded-md cursor-pointer border border-blue-500 text-blue-500 text-xs block">
            See an example
          </button>
          <PromptTipsModal handleClose={() => setModalVisible(false)} show={modalVisible} tip={tip} />
        </>
      )}
    </>
  )
}

type TipCardProps = {
  tip: Tip;
};
