import React, { forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';

type ButtonVariant = 'icon' | 'outlined' | 'contained';
type ButtonColour = 'primary' | 'secondary' | 'error';
type ButtonSize = 'small' | 'medium' | 'large';

type SharedProps = {
  ariaControls?: string;
  ariaSelected?: boolean;
  className?: string;
  colour: ButtonColour;
  onClick?: (event: React.MouseEvent) => void;
  size?: ButtonSize;
  title: string;
  variant: ButtonVariant;
}

type ButtonProps = PropsWithChildren<{
  disabled?: boolean;
  role?: string;
  type?: HTMLButtonElement['type'];
  sentenceCase?: boolean;
} & SharedProps>;

const COLOUR_CLASSES: Record<ButtonColour, string> = {
  'primary': 'bg-blue-400 hover:bg-blue-500',
  'secondary': 'hover:text-blue-500 hover:border-blue-500',
  'error': 'bg-red-400 hover:bg-red-600 border-red-400 text-white'
}

const VARIANT_CLASSES: Record<ButtonVariant, string> = {
  'contained': 'text-white',
  'outlined': 'border border-grey-600',
  'icon': 'text-grey-600 focus:outline-none focus-visible:ring-2'
}

const SIZE_CLASSES: Record<ButtonSize, string> = {
  'small': 'text-xs',
  'medium': 'text-md',
  'large': 'text-lg'
}

function convertToSentenceCase(children: React.ReactNode) {
  return React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      const lower = child.toLowerCase();
      return lower.substring(0, 1).toUpperCase() + lower.substring(1);
    }

    return child;
  });
}

const COMMON_CLASSES = [
  'transition-colors',
  'duration-300',
  'font-semibold',
  'rounded-md',
  'cursor-pointer',
  'disabled:bg-grey-400',
  'disabled:cursor-not-allowed'
];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  ariaControls,
  ariaSelected,
  children,
  className,
  colour,
  disabled,
  onClick,
  role,
  sentenceCase = true,
  size = 'medium',
  title,
  type = 'button',
  variant
}, ref) => {
  const colourClasses = COLOUR_CLASSES[colour];
  const variantClasses = VARIANT_CLASSES[variant];
  const sizeClasses = SIZE_CLASSES[size];
  const classes = clsx(COMMON_CLASSES, variantClasses, colourClasses, sizeClasses, className);

  return (
    <button ref={ref} aria-controls={ariaControls} aria-selected={ariaSelected} className={classes} disabled={disabled} onClick={onClick} role={role} type={type} title={title}>
      { sentenceCase ? convertToSentenceCase(children) : children }
    </button>
  )
});
