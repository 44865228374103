import { useQuery } from '@tanstack/react-query';
import { useAskBluejApiContext } from '../core/api/ask-bluej-api';

type FetchExpectationSettingData = {
  question: string;
  strategy: string;
  chatId: string;
};

export function useFetchExpectationSettingQuery({ question, strategy, chatId }: FetchExpectationSettingData) {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['expectationSetting', 'criteria', chatId],
    queryFn: async (): Promise<{ criteria: string }> => {
      return await api.expectation(question, strategy);
    },
    refetchOnWindowFocus: false,
    gcTime: Infinity,
    staleTime: Infinity
  })
}
