import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { toast } from 'react-toastify';
import AsyncButton from '../admin/components/button/async-button';
import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useUser } from '../core/user/context';

export function ManageSubscriptionSetting() {
  const { api } = useAskBluejApiContext();
  const { subscriptionSettings } = useUser();

  if (!subscriptionSettings || !subscriptionSettings.manageable) {
    return null;
  }

  const handleOpenPaymentPortal = async () => {
    const { url } = await api.getBillingPortalUrl(subscriptionSettings.region).catch(() => ({ url: '' }));
    if (!url) {
      toast.error('Failed to open payment portal', {
        toastId: 'payment-portal-error'
      });
      return;
    }
    window.open(url);
  };

  return (
    <div className="flex flex-col w-full mt-8 gap-2">
      <label className="block text-md font-semibold leading-6 text-gray-900">
        Manage subscription
      </label>
      <AsyncButton
        className="bg-transparent hover:bg-transparent disabled:!bg-transparent !p-0 !text-blue-400 !text-base !font-normal !shadow-none hover:underline underline-offset-4"
        onClick={handleOpenPaymentPortal}
      >
        Open payment portal
        <ArrowTopRightOnSquareIcon className="size-6" />
      </AsyncButton>
    </div>
  );
}
