import { SourceView, SourceViewListing } from '@/types';

const findSourceIndex = (answerId: string, sources: Array<SourceViewListing | SourceView>): number => {
  const exactMatchIndex = sources.findIndex(s => answerId === s.id);
  if (exactMatchIndex >= 0) return exactMatchIndex;
  return sources.findIndex(s => answerId.startsWith(s.id) && answerId[s.id.length] === '(');
};

export const replaceInlineCitationsWithLinks = (answer: string, sources?: SourceViewListing[], newTab = false): string => {
  if (!sources) return answer;
  return answer.replace(/ ?\[\[ID: (.*?)]]/g, (_, answerId) => {
    const index = findSourceIndex(answerId, sources);
    if (index < 0) return '';
    const sourceLink = newTab ?  sources[index].href : `/sources/${index + 1}`;
    return ` [[${index + 1}]](${sourceLink})`;
  });
};

export const replaceInlineCitationsWithPlainText = (answer: string, sources?: SourceViewListing[]): string => {
  if (!sources) return answer;
  return answer.replace(/\[\[ID: (.*?)]]/g, (_, answerId) => {
    const index = findSourceIndex(answerId, sources);
    return index >= 0 ? `[${index + 1}]` : '';
  });
};
