export function isNoAnswer(answer: string): boolean {
  if (!answer) {
    return true;
  }

  return answer.replace(/["'.]/g, '').toLowerCase() === 'i dont know';
}

export function isPartialNoAnswer(answer: string): boolean {
  if (!answer) {
    return true;
  }

  const replacedAnswer = answer.replace(/["'.]/g, '').toLowerCase();
  const regexTest = new RegExp(/^i( (d(o(n(t( (k(n(o(w)?)?)?)?)?)?)?)?)?)?$/);

  return regexTest.test(replacedAnswer);
}
