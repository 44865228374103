import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { useChatContext } from './contexts/ChatContext';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';

export function useSavedPromptCreateQuery() {
  const { api } = useAskBluejApiContext();
  const savedPromptId = v4();
  const { chat } = useChatContext();
  const queryClient = useQueryClient();
  const { trackSavedPromptCreated } = useAnalyticsEvent();

  return useMutation({
    mutationFn: async () => {
      trackSavedPromptCreated({ question: chat.question, promptId: savedPromptId });
      return await api.createSavedPrompt(savedPromptId, chat.question);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['savedPrompt', chat.chat_id], data.savedPromptId);
    },
    onError: () => {
      toast.error('Couldn\'t save your prompt. Wait a moment and please try again.');
    }
  });
}
