export interface Analytics {
  track(eventName: string, eventProperties?: EventProperties): void;
  page(category: string, name?: string, properties?: EventProperties): void;
  identify(userId: string, userProperties?: UserProperties): void;
}

export async function track(analytics: Analytics, name: string, properties?: EventProperties) {
  analytics.track(name, properties);
}

export async function page(analytics: Analytics, category: string, name?: string, properties?: EventProperties) {
  analytics.page(category, name, properties);
}

export type UserProperties = Record<string, unknown>;
export type EventProperties = Record<string, unknown>;