import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import clsx from 'clsx';
import React, { PropsWithChildren, useState } from 'react';
import { Button } from '../../../components/button';

interface ConfirmDeleteProps {
  onConfirm: () => void;
  title: string;
  prompt?: string;
  description?: string;
  deleteButtonTitle?: string;
  includeCancelButton?: boolean;
  alwaysVisible?: boolean;
}

export function ConfirmDelete(props: PropsWithChildren<ConfirmDeleteProps>) {
  const {
    children,
    onConfirm,
    title,
    prompt = 'Are you sure you want to delete this?',
    deleteButtonTitle = 'Delete',
    description,
    includeCancelButton,
    alwaysVisible = false
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(25),
      flip(),
      shift({
        padding: 25
      })
    ],
    placement: 'right'
  })
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const deleteButtonClasses = clsx('items-center flex w-6 h-6 justify-center', {
    'lg:opacity-0 lg:group-hover:opacity-100 focus-visible:opacity-100': !alwaysVisible
  });

  return (
    <>
      <Button
        colour="secondary"
        variant="icon"
        className={deleteButtonClasses}
        title={title}
        onClick={() => setIsOpen(true)}
        {...getReferenceProps()}
      >
        <span ref={refs.setReference}>
          {children}
        </span>
      </Button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} initialFocus={refs.floating} modal={false}>
            <div
              className="bg-white p-3 rounded-lg w-72 border border-grey-3gi00 shadow-xl max-w-[85vw] sm:max-w-full focus:outline-none z-[1000]"
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div className="text-black-400 font-semibold block mb-4 text-sm">{prompt}</div>
              <div className="text-black-400 block mb-4 text-xs">{description}</div>
              <div className="flex gap-2">
                <Button
                  colour="error"
                  className="py-2 px-2"
                  onClick={() => onConfirm()}
                  size="small"
                  title={deleteButtonTitle}
                  type="submit"
                  variant="contained"
                >
                  Delete
                </Button>
                {
                  includeCancelButton && (
                    <Button
                      className="py-2 px-2"
                      colour="secondary"
                      onClick={() => setIsOpen(false)}
                      size="small"
                      title="Cancel"
                      type="submit"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  )
                }
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
