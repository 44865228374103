import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useChatContext } from './contexts/ChatContext';
import { useAnalyticsEvent } from '../core/analytics/useAnalyticsEvent';
import { SavedPromptInteractionType } from '../saved-prompts/shared';

export function useSavedPromptDeleteQuery() {
  const { api } = useAskBluejApiContext();
  const { trackSavedPromptRemoved } = useAnalyticsEvent();

  return useMutation({
    mutationFn: async ({ savedPromptId, question }: { savedPromptId: string, question: string }) => {
      trackSavedPromptRemoved({ question, promptId: savedPromptId, interactionContext: SavedPromptInteractionType.PANEL })
      return await api.deleteSavedPrompt(savedPromptId);
    },
    onError: () => {
      toast.error('Couldn\'t remove your prompt. Wait a moment and please try again.');
    }
  });
}

export function useSavedPromptDeleteQueryWithChat() {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();
  const { chat } = useChatContext();
  const { trackSavedPromptRemoved } = useAnalyticsEvent();

  return useMutation({
    mutationFn: async () => {
      const querySavedPromptId = queryClient.getQueryData<string>(['savedPrompt', chat.chat_id]);
      if (!querySavedPromptId) return;
      trackSavedPromptRemoved({ question: chat.question, promptId: querySavedPromptId, interactionContext: SavedPromptInteractionType.CHAT })
      return await api.deleteSavedPrompt(querySavedPromptId);
    },
    onError: () => {
      toast.error('Couldn\'t remove your prompt. Wait a moment and please try again.');
    }
  });
}
