import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';

export function useThreadDeleteQuery() {
  const { api } = useAskBluejApiContext();

  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      return api.deleteThread(id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['thread-list'] });
    }
  });

  function deleteThread(id: string) {
    return mutation.mutateAsync(id);
  }

  return {
    deleteThread
  }
}
