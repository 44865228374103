import React from 'react';
import AutosizingTextarea from 'react-textarea-autosize';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../../components/button';

interface EditThreadViewProps {
  newTitle: string;
  onBlur: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const EditThreadView: React.FC<EditThreadViewProps> = ({ newTitle, onBlur, onKeyDown, onChange }) => (
  <div className="row-start-1 col-start-1 flex justify-between z-[1]">
    <AutosizingTextarea
      autoFocus
      onFocus={(e) => {
        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
        e.currentTarget.focus();
      }}
      className="caret-blue-600 mx-2 bg-grey-300 px-[6px] py-[3px] flex-1 resize-none rounded-md border-0 focus-within:ring-2 focus-within:ring-grey-500 w-[90%]"
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      value={newTitle}
    />
    <div className="flex">
      <Button
        className="w-6 h-6"
        colour="secondary"
        title={`Confirm Rename Thread "${newTitle}"`}
        onClick={onBlur}
        variant="icon"
      >
        <CheckIcon className="w-6 h-6 lg:w-5 lg:h-5 inline-block" />
      </Button>
    </div>
  </div>
);

export default EditThreadView;
