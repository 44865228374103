import { SourceViewListing } from '@/types';
import React from 'react';
import { renderToString } from 'react-dom/server';
import BlueJ from '../../../../assets/svg/bluej.svg?react';
import { AnswerMarkdown } from '../answer-markdown';
import { replaceInlineCitationsWithLinks } from '../inline-citations/replace-inline-citations';
import { backCompatUrl } from '../sources/external-badge';

export function formatForPrint(question: string, answer: string, sources: SourceViewListing[]) {
  return renderToString(
    <div className="space-y-2 mb-5">

      {/* Question */}
      <div className="border text-black-400 p-3 rounded-2xl flex flex-row gap-6 mb-4">
        <div className="flex-1 self-center">
          <div className="whitespace-pre-wrap">
            {question}
          </div>
        </div>
      </div>

      <div className="border p-3 rounded-2xl">
        {/* Answer */}
        <div className="text-black-400 grid grid-cols-[auto_1fr] gap-6">
          <div className="rounded-full text-grey-600 w-12 h-12 flex justify-center items-center">
            <BlueJ className="w-12 h-12"/>
          </div>
          <div className="self-center">
            <div className="mb-5">
              <AnswerMarkdown answer={replaceInlineCitationsWithLinks(answer, sources, true)} />
            </div>
            {/* Sources */}
            <div className="text-grey-600 text-sm">

              {sources && sources.length > 0 ? <>
                <div className="mb-3">Sources</div>
                <div className="mb-2 w-full">
                  {
                    sources.map((source, index) =>
                      (
                        <div className="grid grid-cols-[5.75rem_auto]" key={`${source.title}-${index}`}>
                          <div className="text-left align-top py-2 pr-2 w-24">
                            <div
                              className="border font-medium text-xxs px-2 py-1 border-grey-300 rounded-xl text-black-600 leading-3 max-h-5 inline-block">
                              {(source.badge).toUpperCase()}
                            </div>
                          </div>
                          <div className="text-left align-top p-2">
                            <a className="text-blue-400 underline underline-offset-4" href={getUrl(source)}>
                              {`[${index + 1}] ${source.title}`}
                            </a>
                          </div>
                        </div>
                      )
                    )
                  }
                </div>
              </> : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// FIXME (backwards-compat)
export function getUrl(source: SourceViewListing) {
  if (source.externalUrl) {
    return backCompatUrl(source.externalUrl ?? source.href);
  }

  return `${window.location.origin}${source.href}`;
}
