const requiredAttributes = [
  'data-document-type', // statute, regulation, etc.
  'data-title', // the "title" of the document, some part of the law (Title 26, Title 42, etc.)
  'data-path' // A "path" to the content in the section, i.e. 3561, 3561(a), 3561(a)(1), 3561(a)(1)#root_a
]
export function replaceLinks(element: HTMLDivElement | null) {
  if (!element) {
    return;
  }

  const links = element.querySelectorAll('a[data-bluej-link]');

  for (const link of links) {
    if (link.hasAttribute('data-anchor-target')) {
      const anchorTarget = link.getAttribute('data-anchor-target');
      link.setAttribute('href', `#${anchorTarget}`);
      continue;
    }

    if (requiredAttributes.every(attribute => link.hasAttribute(attribute))) {
      const documentType = link.getAttribute('data-document-type');
      const title = link.getAttribute('data-title');
      const path = link.getAttribute('data-path');

      const url = `/source?ref=${documentType}/${title}/${path}`;
      link.setAttribute('href', url);
    }
  }
}
