import React from 'react';
import { useAnalytics } from '../analytics/context';
import { useAnalyticsEvent } from '../analytics/useAnalyticsEvent';

export const AppLoadTracker = () => {
  const { trackAppLoaded } = useAnalyticsEvent();
  const analytics = useAnalytics();

  React.useEffect(() => {
    trackAppLoaded();
    analytics.page('pageLoaded');
  }, [analytics]);

  return null;
};