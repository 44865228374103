import { Thread } from '@/types';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useCallback } from 'react';

export function useGetChatQuery() {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();

  const getChat = useCallback(
    async (threadId: string, chatId: string) => {
      const chat = await api.getChat(threadId, chatId);

      queryClient.setQueryData(['threads', threadId], (oldThread: InfiniteData<Thread> | undefined) => {
        if (!oldThread || oldThread.pages.length === 0) {
          return oldThread;
        }

        const chatExists = oldThread.pages.flatMap((thread) => thread.chats).some((chat) => chat.chat_id === chatId);

        // Ensure we haven't already populated the cache with this chat
        if (!chatExists) {
          const [firstPage, ...restPages] = oldThread.pages;
          const updatedPages = [
            {
              ...firstPage,
              chats: [...firstPage.chats, chat],
            },
            ...restPages,
          ];
          return { ...oldThread, pages: updatedPages };
        }
        return oldThread;
      });

      return chat;
    },
    [api, queryClient]
  );

  return {
    getChat,
  };
}
