import {
  HandThumbDownIcon as HandThumbDownIconUnfilled,
  HandThumbUpIcon as HandThumbUpIconUnfilled
} from '@heroicons/react/24/outline';
import {
  HandThumbDownIcon as HandThumbDownIconFilled,
  HandThumbUpIcon as HandThumbUpIconFilled
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React from 'react';

import { Button } from '../../../../components/button';
import { useConfigContext } from '../../../config/ConfigContext';
import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { useAskBluejApiContext } from '../../../core/api/ask-bluej-api';
import { useChatContext } from '../../contexts/ChatContext';
import { useThreadContext } from '../../contexts/ThreadContext';
import { FeedbackType } from '../../types';
import { useFeedbackState } from '../../useFeedbackState';
import CommentSubmit from './feedback-comment';

export function Feedback() {
  const { thread } = useThreadContext();
  const { chat, feedbackVisible } = useChatContext();

  const { api } = useAskBluejApiContext();
  const { setFeedbackGiven, getGivenFeedback } = useFeedbackState();
  const { trackQuestionFeedback, trackQuestionFeedbackClicked } = useAnalyticsEvent();
  const { environment } = useConfigContext();
  const feedbackPayload = {
    chatId: chat.chat_id,
    threadId: thread.id,
    environment
  };

  // Using this to force the comment component's state to be cleared
  const [lastComment, setLastComment] = React.useState(0);
  const [animate, setAnimate] = React.useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = React.useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

  const givenFeedback = getGivenFeedback(thread.id, chat.chat_id);

  React.useEffect(() => {
    if (feedbackSubmitted) {
      const startTimer = setTimeout(() => {
        setAnimate(true);
      }, 300);
      const endTimer = setTimeout(() => {
        setAnimate(false);
        setShowConfirmationMessage(true);
      }, 1300);
      return () => {
        clearTimeout(startTimer);
        clearTimeout(endTimer);
      };
    } else {
      setShowConfirmationMessage(false);
    }
  }, [feedbackSubmitted]);

  const updateLastComment = () => {
    setLastComment(Date.now());
  }

  const handleFeedback = React.useCallback((type: FeedbackType, comment = '') => {
    const payload = { ...feedbackPayload, type, comment, environment };
    // Has given feedback and is clicking the same one
    if (givenFeedback && givenFeedback === type) {
      setFeedbackGiven(thread.id, chat.chat_id);
    } else {
      // Ignoring if this request fails
      setLastComment(Date.now());
      setFeedbackGiven(thread.id, chat.chat_id, type);
      void api.sendFeedback(payload);
      setFeedbackSubmitted(true);
    }

    trackQuestionFeedback({ type, comment });
  }, [feedbackPayload, givenFeedback, thread.id, chat.chat_id]);

  const handleFeedbackWithComment = React.useCallback((type: FeedbackType, comment: string) => {
    handleFeedback(type, comment);
  }, [handleFeedback]);

  const handleFeedbackClick = React.useCallback((type: FeedbackType) => () => {
    trackQuestionFeedbackClicked({ type });
    // If they've already given feedback of this type, we want to clear it out
    if (givenFeedback && givenFeedback === type) {
      setFeedbackGiven(thread.id, chat.chat_id);
      updateLastComment();
    }
  }, [givenFeedback, thread.id, chat.chat_id]);

  const iconClasses = clsx('inline-block w-6 h-6');

  const VoteUpIcon = givenFeedback === 'agree' ? HandThumbUpIconFilled : HandThumbUpIconUnfilled;
  const VoteDownIcon = givenFeedback === 'disagree' ? HandThumbDownIconFilled : HandThumbDownIconUnfilled;

  const voteUpClasses = clsx(iconClasses, 'text-blue-400 hover:text-blue-500');
  const voteDownClasses = clsx(iconClasses, 'text-blue-400 hover:text-blue-500');

  const borderClasses = clsx(
    'border-2 transition duration-500',
    {
      'border-transparent': !animate,
      'border-blue-400': animate,
      'shadow-md': animate
    }
  );

  const disagreeButton = givenFeedback && givenFeedback === 'disagree' ? (
    <Button variant="icon" colour="secondary" onClick={handleFeedbackClick('disagree')} title="Disagree">
      <VoteDownIcon className={voteDownClasses} />
    </Button>
  ) : (
    <CommentSubmit type="disagree" onSubmit={handleFeedbackWithComment} title="Disagree">
      <VoteDownIcon className={voteDownClasses} onClick={handleFeedbackClick('disagree')} />
    </CommentSubmit>
  );

  const agreeButton = givenFeedback && givenFeedback === 'agree' ? (
    <Button colour="secondary" variant="icon" onClick={handleFeedbackClick('agree')} title="Agree">
      <VoteUpIcon className={voteUpClasses} />
    </Button>
  ) : (
    <CommentSubmit type="agree" onSubmit={handleFeedbackWithComment} title="Agree">
      <VoteUpIcon className={voteUpClasses} onClick={handleFeedbackClick('agree')} />
    </CommentSubmit>
  );

  if (!feedbackVisible) {
    return null;
  }

  return (
    <div
      className={clsx(
        'w-fit pl-4 pr-2 py-1 bg-blue-50 rounded-full justify-between items-center gap-2 inline-flex',
        borderClasses
      )}
      key={lastComment}>
      <div className="text-sm">{ showConfirmationMessage ? 'Thanks, your feedback was received!' : 'Like this answer?' }</div>
      <div className="px-2 py-1 bg-white rounded-full items-center gap-2 flex ml-auto">
        { agreeButton }
        { disagreeButton }
      </div>
    </div>
  )
}
