import { SourceView } from '@/types';
import React, { forwardRef } from 'react';

import Logo from '../../../../assets/svg/logo.svg?react'
import { SourceHeader as SourceHeaderPrint } from '../../../source/components/source-header';
import { replaceLinks } from '../../../source/utils/replace-links';
import { SourceHighlighter } from '../../source-highlighter/source-highlighter';
import { SourceBadge } from './source-badge';
import { ContentMetaData } from './source-header';
import { SourceDetailsTable } from './details-table';

type PrintSourceProps = {
  sourceView?: SourceView | null;
  sourceContent: string;
  highlightContent: string;
}
export const PrintSource = forwardRef<HTMLDivElement, PrintSourceProps>(
  ({ sourceContent, highlightContent, sourceView }: PrintSourceProps, ref) => {
    return (
      <div className="hidden">
        <div ref={ref}>
          <div className="max-w-5xl mx-auto ">
            <div className="flex flex-col items-center">
              <Logo className="w-48 h-auto"/>
              <div className="items-start print-bg">
                {sourceView && (
                  <>
                    <SourceHeaderPrint badge={sourceView.badge} title={sourceView.title}/>
                    <ContentMetaData metadata={sourceView.metadata} />
                    <SourceDetailsTable data={sourceView.detailsTableData} />
                  </>
                )}
                {sourceView?.externalUrl && (
                  <>
                    <SourceBadge badge={sourceView.badge}/>
                    <div className="my-2">
                      <a className="text-blue-400 hover:underline underline-offset-4" href={sourceView?.externalUrl}
                         target="_blank"
                         rel="noreferrer noopener">
                        <span className="whitespace-nowrap overflow-hidden text-ellipsis" title={sourceView.title}>
                          {sourceView.title}
                        </span>
                      </a>
                    </div>
                  </>
                )}
                <div className="mt-2 prose prose-pre:whitespace-pre-wrap [&_*]:!prose-sm max-w-full prose-custom-links">
                  <SourceHighlighter
                    content={highlightContent}
                    source={sourceContent}
                    onContentReady={replaceLinks}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
)

