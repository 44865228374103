import { Context, useContext } from 'react';

export function createUseContext<TContext>(context: Context<TContext>, fallback?: TContext) {
  return function() {
    const value = useContext(context);

    if (!value && fallback) {
      return fallback;
    }

    if (!value) {
      throw new Error(`${context.displayName} value must be set`);
    }

    return value;
  }
}
