import { SourceViewListing } from '@/types';

export type ThreadedAskStreamMessages =
  | { type: 'answer_chunk'; data: string; }
  | { type: 'answer_complete'; data: null; }
  | { type: 'request_complete'; data: null; }
  | { type: 'request_error'; data: null; }
  | { type: 'error'; data: null; } // string maybe?
  | { type: 'potential_sources_identified'; sources: SourceViewListing[] }
  | { type: 'source_identified'; source: SourceViewListing }
  | { type: 'irrelevant_source_identified'; source: SourceViewListing };

export const ThreadedAskStreamMessageTypes: Set<ThreadedAskStreamMessages['type']> = new Set([
  'answer_chunk' as const,
  'answer_complete' as const,
  'request_complete' as const,
  'request_error' as const,
  'error' as const,
  'potential_sources_identified' as const,
  'source_identified' as const,
  'irrelevant_source_identified' as const
]);
