import { Thread } from '@/types';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useSentryContext } from '../core/sentry/context';
import { UpdatableThreadProperties } from './types';

export function useThreadUpdateQuery(threadId: string) {
  const { api } = useAskBluejApiContext();
  const queryClient = useQueryClient();
  const { sentry } = useSentryContext();

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({ queryKey: ['thread-list'] });
    void queryClient.invalidateQueries({ queryKey: ['threads', threadId] });
  }

  const mutation = useMutation<Thread, unknown, UpdatableThreadProperties>({
    mutationFn: async (data) => {
      return api.updateThread(threadId, data.title);
    },
    onSuccess: () => {
      invalidateQueries();
    }
  });

  function generateThreadTitle(chatId: string) {
    const infiniteData: InfiniteData<Thread> | undefined = queryClient.getQueryData(['threads', threadId]);
    const thread = infiniteData?.pages[0];
    const chats = infiniteData?.pages.flatMap((thread) => thread.chats)
      .filter((chat) => !chat.chat_type.includes('aborted')) ?? [];

    if (chats.length === 1 && thread?.title === 'Untitled Thread') {
      api.generateThreadTitle(thread.id, chatId)
        .then(() => invalidateQueries())
        .catch(sentry.reportError);
    }
  }

  function updateTitle(title: string) {
    return mutation.mutateAsync({ title });
  }

  return {
    generateThreadTitle,
    updateTitle
  }
}
