import { useEventSourceReducer } from '@bluejlegal/event-source-reducer';

import { useGetEventSource } from '../get-event-source';
import { useReducerLogger } from '../../../core/hooks/useReducerLogger';

import { askDocStateReducer, AskDocStreamState, createInitialState, filter } from './reducer';
import { AskDocStreamMessages } from './messages';
import { AskDocInteractionType, AskDocPayload, DOC_SSE_ENDPOINT } from './shared';

export function useStreamingAskDoc(payload: AskDocPayload, apiUrl: string, interactionContext: AskDocInteractionType) {
  const initialState = createInitialState(payload, apiUrl, interactionContext);
  const getEventSource = useGetEventSource(DOC_SSE_ENDPOINT, payload);

  const reducer = useReducerLogger(askDocStateReducer);

  return useEventSourceReducer<AskDocStreamState, AskDocStreamMessages>(
    getEventSource,
    filter,
    reducer,
    initialState
  );
}
