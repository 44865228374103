import { useLayoutEffect, useRef, useState } from 'react';

export const useGetScrollBarWidth = () => {
  const scrollableElementRef = useRef<HTMLDivElement>(null);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    if (scrollableElementRef.current) {
      setScrollbarWidth(scrollableElementRef.current.offsetWidth - scrollableElementRef.current.clientWidth);
    }
  }, [scrollableElementRef.current, scrollbarWidth]);

  // For when we're unable to add a ref to the scrollable element
  useLayoutEffect(() => {
    if (outerContainerRef.current && innerContainerRef.current) {
      setScrollbarWidth(outerContainerRef.current.clientWidth - innerContainerRef.current.clientWidth);
    }
  }, [outerContainerRef, innerContainerRef]);

  return { scrollbarWidth, scrollableElementRef, outerContainerRef, innerContainerRef };
}
