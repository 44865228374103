import { QuestionDebug } from '../question-debug';

type Props = {
  question: string;
  strategy?: string;
}

export function Question({ question, strategy }: Props) {
  return (
    <QuestionDebug strategy={strategy}>
      <div className="whitespace-pre-wrap flex-1 font-semibold">
        { question }
      </div>
    </QuestionDebug>
  );
}
