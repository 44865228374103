import { Config, User } from '@/types';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { callWhoAmIWithRetry } from './functions/utils';
import { AppLayout } from './modules/core/app/app-layout';
import { AppProvider } from './modules/core/app/provider';
import { SEO } from './modules/core/app/seo';
import { ErrorPage } from './modules/core/errors/error-page';
import { createServices, Services } from './modules/core/init';
import { WelcomeModal } from './modules/welcome/components/modal';
import { createRouter } from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false
    }
  }
});

function renderApplication(user: User, config: Config, services: Services) {
  const router = createRouter();
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <HelmetProvider>
        <ErrorBoundary
          fallback={
            <ErrorPage
              title="Something went wrong."
              description="Please try again later. If the problem persists, please contact support."
            />}>
          <SEO />
          <AppProvider
            askBlueJApi={services.askBlueJApi}
            sourcesApi={services.sourcesApi}
            queryClient={queryClient}
            user={user}
            config={config}
            sentryService={services.sentry}
          >
            <WelcomeModal />
            <AppLayout>
              <RouterProvider router={router} />
            </AppLayout>
          </AppProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </React.StrictMode>
  );
}

// From https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateViewportHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

async function startApp() {
  const config: Config = await getConfig();
  const services = createServices({ backendUrl: '/' }, config);
  const user = await callWhoAmIWithRetry(services, 0, 3, 1000);
  updateViewportHeight();
  if (!(user instanceof Error)) {
    services.sentry.setUserData({ email: user.email });
    return renderApplication(user, config, services);
  } else {
    if (user.message.includes('whoami retry exceeded')) {
      return ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorPage
          title="Temporary Service Disruption"
          description={(
            <div>
              <p>We are currently experiencing a service disruption to Blue J. For direct updates as new information becomes available, please visit our <a className="text-blue-400" href="https://status.askbluej.com/" target="_blank" rel="noreferrer noopener">status page</a>.</p>
            </div>
          )}
        />
      );
    }
  }
}

async function getConfig() {
  return await fetch('/config', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());
}

(async () => {
  if (import.meta.env.VITE_APP_MOCKED === 'true') {
    console.log('Loading MSW...');
    void import('./mocks/browser')
      .then(({ worker }) => worker.start())
      .then(startApp);
  } else {
    void startApp();
  }
})();
