import { Chat } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { isNoAnswer } from '../../ask-bluej';
import { ThreadedAskStreamAbortedState } from '../../ask-bluej/streaming/threaded/reducer';
import { useUser } from '../../core/user/context';

export function useFollowUpQuestions(questionInProgress: boolean, abortedQuestion: ThreadedAskStreamAbortedState | null) {
  const { preferences } = useUser();
  const { preferredFollowUpOption = true } = preferences ?? {};
  const [questionAskedAtLeastOnce, setQuestionAskedAtLeastOnce] = useState(false);
  const [completedChat, setCompletedChat] = useState<Chat | null>(null);
  const queryClient = useQueryClient();
  const expectationSettingQuery = completedChat && queryClient.getQueryData<{ criteria: string }>(['expectationSetting', 'criteria', completedChat.chat_id]);

  const showFollowUpQuestions = (
    preferredFollowUpOption &&
    questionAskedAtLeastOnce &&
    !questionInProgress &&
    abortedQuestion === null &&
    !isNoAnswer(completedChat?.answer ?? '') &&
    (!expectationSettingQuery || expectationSettingQuery?.criteria === 'pass')
  );

  return {
    questionAskedAtLeastOnce,
    setQuestionAskedAtLeastOnce,
    completedChat,
    setCompletedChat,
    showFollowUpQuestions
  };
}
