import { SourceMetaRow } from './source-meta/source-meta-row';

type DetailsTableProps = {
  data?: Record<string, unknown>;
}

const detailsTableKeyToTitle: Record<string, string> = {
  citation: 'Citation/Reference',
  judgmentDate: 'Judgment Date',
  fileNumbers: 'Docket Number(s)',
  jurisdiction: 'Jurisdiction',
  court: 'Court',
  published: 'Published',
  released: 'Released',
  type: 'Type'
};

export function SourceDetailsTable({ data }: DetailsTableProps) {
  if (!data) return null;
  if (Object.keys(data).length === 0) return null;

  return (
    <div
      className="text-black-400 p-3 rounded-2xl flex flex-row gap-6 my-2 bg-grey-200 meta-box overflow-hidden print:bg-white print:border">
      <table className="w-full text-sm">
        <tbody>
          { Object.entries(data).map(([key, value]) => {
            return <SourceMetaRow key={key} title={detailsTableKeyToTitle[key] ?? key} value={String(value)} />
          }) }
        </tbody>
      </table>
    </div>
  );
}
