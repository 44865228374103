import { autoUpdate, offset, shift, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { AskDocInteractionType } from '../ask-bluej/streaming/doc/shared';

type useDocTooltipProps = {
  isTooltipVisible: boolean;
  setTooltipVisible: (visible: boolean) => void;
  interactionContext: AskDocInteractionType;
}

export function useDocTooltip(props: useDocTooltipProps) {
  const { isTooltipVisible, setTooltipVisible, interactionContext } = props;

  const { refs, floatingStyles, context } = useFloating({
    open: isTooltipVisible,
    onOpenChange(isOpen, _, reason) {
      setTooltipVisible(isOpen);
      if (!isOpen && reason !== 'outside-press') {
        localStorage.setItem(`ask-${interactionContext}-tooltip`, 'true');
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 10, crossAxis: 28 }),
      shift()
    ],
    placement: 'top-start',
  });

  const dismiss = useDismiss(context, {
    referencePress: true,
    outsidePress: false,
    bubbles: {
      escapeKey: false,
    }
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
  ]);

  return { tooltipRefs: refs, floatingStyles, getReferenceProps, getFloatingProps };
}
