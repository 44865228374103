import { ErrorBoundary } from '@sentry/react';
import { useParams } from 'react-router';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { ErrorFallback } from '../core/errors/error-fallback';
import { Panel } from '../thread/components/panel';
import { Span } from './span';

type Params = {
  chatId: string;
  threadId: string;
}

export function TracePanelRoute() {
  const { chatId, threadId } = useParams<Params>();

  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const handleOnClose = () => {
    navigate(`/${threadId}`);
  }

  if (!chatId || !threadId) {
    return null;
  }

  const shouldAnimate = navigationType === 'PUSH';

  return (
    <Panel onClose={handleOnClose} animate={shouldAnimate}>
      <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
        <Span chatId={chatId} />
      </ErrorBoundary>
    </Panel>
  )
}
