import React from 'react';
import { isNoAnswer } from '../../ask-bluej';
import { ThreadedAskStreamState } from '../../ask-bluej/streaming/threaded/reducer';
import { QuestionPayload } from '../../ask-bluej/streaming/threaded/shared';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';

export function useAskQuestionAnalytics(payload: QuestionPayload, state: ThreadedAskStreamState) {
  const {
    trackAnswerGenerationStarted,
    trackAnswerGenerationEnded,
    trackQuestionAsked,
    trackUnableToAnswerReceived,
    trackErrorTriggered
  } = useAnalyticsEvent();

  const eventsSentRef = React.useMemo(() => {
    return ({
      questionAsked: false,
      answerGenerationStarted: false,
      answerGenerationEnded: false,
      unableToAnswerReceived: false,
      errorTriggered: false
    });
  }, [payload.chatId]);

  if (state.state === 'initiated' && !eventsSentRef.questionAsked) {
    trackQuestionAsked({
      threadId: state.payload.threadId,
      chatId: state.payload.chatId,
      answerStrategy: state.payload.strategy,
      question: state.payload.question,
      interactionContext: state.interactionContext
    });
    eventsSentRef.questionAsked = true;
  }

  if (state.state === 'answering' && !eventsSentRef.answerGenerationStarted) {
    trackAnswerGenerationStarted({
      threadId: state.payload.threadId,
      chatId: state.payload.chatId,
      answerStrategy: state.payload.strategy,
      elapsedTime: state.answerStreamingStartedTimestamp - state.requestInitiatedTimestamp
    });
    eventsSentRef.answerGenerationStarted = true;
  }

  if (state.state === 'complete') {
    if (!eventsSentRef.unableToAnswerReceived && isNoAnswer(state.answer)) {
      trackUnableToAnswerReceived({
        threadId: state.payload.threadId,
        chatId: state.payload.chatId,
        answerStrategy: state.payload.strategy
      });
      eventsSentRef.unableToAnswerReceived = true;
    }

    if (eventsSentRef.answerGenerationStarted && !eventsSentRef.answerGenerationEnded) {
      trackAnswerGenerationEnded({
        threadId: state.payload.threadId,
        chatId: state.payload.chatId,
        answerStrategy: state.payload.strategy,
        elapsedTime: state.answerStreamingEndedTimestamp - state.answerStreamingStartedTimestamp,
        characterCount: state.answer.length,
        reason: 'completed',
        sources: state.sources
      });
      eventsSentRef.answerGenerationEnded = true;
    }
  }

  if (state.state === 'error') {
    if (eventsSentRef.answerGenerationStarted && !eventsSentRef.answerGenerationEnded) {
      // We only send this if we actually saw it start
      trackAnswerGenerationEnded({
        threadId: state.payload.threadId,
        chatId: state.payload.chatId,
        answerStrategy: state.payload.strategy,
        elapsedTime: state.answerStreamingEndedTimestamp - state.answerStreamingStartedTimestamp,
        characterCount: state.answer.length,
        reason: 'failed',
        sources: state.sources
      });
      eventsSentRef.answerGenerationEnded = true;
    }

    if (!eventsSentRef.errorTriggered) {
      trackErrorTriggered({ threadId: state.payload.threadId, answerStrategy: state.payload.strategy });
      eventsSentRef.errorTriggered = true;
    }
  }
}
