import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { PropsWithChildren, useState } from 'react';
import { Button } from '../../../components/button';

// Borrowed from https://tailwindui.com/components/application-ui/overlays/slide-overs

type Props = {
  animate: boolean;
  onClose: () => void;
}

export function Panel(props: PropsWithChildren<Props>) {
  const { onClose, children, animate } = props;

  const handleClose = () => {
    onClose();
  }

  // Kinda hacky. We're deferring panel render until the next event loop when we know the DOM is settled.
  // This avoids an issue with useOutsideClick in headless-ui retrieving wrong targets for click events on initial render.
  if (!useDelay()) {
    return null;
  }

  return (
    <Transition show={true} appear={animate}>
      <Dialog className="relative z-30" onClose={handleClose}>
        <TransitionChild
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full lg:w-auto">
              <TransitionChild
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto relative w-full max-w-[1000px] lg:w-[50vw]">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="absolute z-10 right-0 mt-6 mx-6">
                      <Button
                        colour="secondary"
                        onClick={handleClose}
                        title="Close panel"
                        variant="icon"
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="w-7 h-7 lg:w-6 lg:h-6" aria-hidden="true" />
                      </Button>
                    </div>
                    <div className="relative flex-1 flex overflow-hidden">{children}</div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function useDelay() {
  const [state, setState] = useState(false);
  setTimeout(() => {
    setState(true);
  }, 0);

  return state;
}
