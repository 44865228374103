import { ErrorBoundary } from '@sentry/react';
import React, { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../../assets/svg/logo.svg?react';
import { QuestionInteractionType } from '../../ask-bluej/streaming/threaded/shared';
import { ErrorFallback } from '../../core/errors/error-fallback';
import { MobileHeader } from '../components/mobile-header';
import { NPSModal } from '../components/nps-modal';
import { ThreadedQuestionInput } from '../components/prompting/threaded-question-input';
import { useGetScrollBarWidth } from '../hooks/useGetScrollBarWidth';
import { useThreadCreateQuery } from '../useThreadCreateQuery';
import { PromptTips } from './prompt-tips/prompt-tips';

function useAskQuestionNoThread() {
  const mutation = useThreadCreateQuery();
  const navigate = useNavigate();

  const handleSubmit = async (question: string, interactionContext: QuestionInteractionType, strategy = '') => {
    const { id } = await mutation.mutateAsync();
    navigate({
      pathname: id,
      search: createSearchParams({
        question,
        interactionContext,
        strategy
      }).toString()
    });
    return true;
  }

  return { handleSubmit };
}

export function NoThreadRoute() {
  const { handleSubmit } = useAskQuestionNoThread();
  const { state } = useLocation();

  useEffect(() => {
    const stateCopy = { ...state };
    delete stateCopy.question;
    window.history.replaceState({ state: stateCopy }, '');
  }, [state]);

  const { scrollbarWidth, scrollableElementRef } = useGetScrollBarWidth();

  return (
    <>
      <div className="flex justify-between flex-col h-full">
        <MobileHeader/>
        <ErrorBoundary fallback={<ErrorFallback errorText="Something went wrong." />}>
          <div ref={scrollableElementRef} className="overflow-y-scroll flex flex-1 flex-col justify-between">
            <div className="lg:flex-1 flex flex-col justify-center w-full align-middle">
              <div className="hidden lg:flex mb-8 mx-auto mt-2">
                <div className="flex max-w-1/2 min-w-[250px]">
                  <Logo className="w-80 h-auto block" />
                </div>
              </div>
              <PromptTips />
            </div>
          </div>
          <div className="px-4 md:px-10 py-4" style={{ marginRight: `${scrollbarWidth}px` }}>
            <ThreadedQuestionInput onSubmit={handleSubmit} variant="condensed" input={state?.question} />
          </div>
          <NPSModal />
        </ErrorBoundary>
      </div>
    </>
  );
}
