import match, { Match } from 'approx-string-match';

type MatchContext = {
  prefix?: string;
  suffix?: string;
  hint?: number;
}

function search(text: string, str: string, maxErrors: number) {
  let matchPos = 0;
  const exactMatches: Match[] = [];
  while (matchPos !== -1) {
    matchPos = text.indexOf(str, matchPos);
    if (matchPos !== -1) {
      exactMatches.push({
        start: matchPos,
        end: matchPos + str.length,
        errors: 0,
      });
      matchPos += 1;
    }
  }
  if (exactMatches.length > 0) {
    return exactMatches;
  }

  // If there are no exact matches, do a more expensive search for matches
  return match(text, str, maxErrors);
}

function textMatchScore(text: string, str: string) {
  if (str.length === 0) {
    return 0.0;
  }
  const matches = search(text, str, str.length);

  if (!matches) {
    return 1 / str.length;
  }

  return 1 - ((matches[0]?.errors || 0) / str.length);
}

export function matchQuote(text: string, quote: string, context: MatchContext = {}) {
  if (quote.length === 0) {
    return null;
  }

  // Choose the maximum number of errors to allow for the initial search.
  // This choice involves a tradeoff between:
  //
  //  - Recall (proportion of "good" matches found)
  //  - Precision (proportion of matches found which are "good")
  //  - Cost of the initial search and of processing the candidate matches [1]
  //
  // [1] Specifically, the expected-time complexity of the initial search is
  //     `O((maxErrors / 32) * text.length)`. See `approx-string-match` docs.
  const maxErrors = Math.min(256, quote.length / 2);

  // Find closest matches for `quote` in `text` based on edit distance.
  const matches = search(text, quote, maxErrors);

  if (matches.length === 0) {
    return null;
  }

  const scoreMatch = (match: Match) => {
    const quoteWeight = 50; // Similarity of matched text to quote.
    const prefixWeight = 20; // Similarity of text before matched text to `context.prefix`.
    const suffixWeight = 20; // Similarity of text after matched text to `context.suffix`.
    const posWeight = 2; // Proximity to expected location. Used as a tie-breaker.
    const quoteScore = 1 - (match?.errors || 0) / quote.length;

    const prefixScore = context.prefix
      ? textMatchScore(
        text.slice(match.start - context.prefix.length, match.start),
        context.prefix
      )
      : 1.0;
    const suffixScore = context.suffix
      ? textMatchScore(
        text.slice(match.end, match.end + context.suffix.length),
        context.suffix
      )
      : 1.0;

    let posScore = 1.0;
    if (typeof context.hint === 'number') {
      const offset = Math.abs(match.start - context.hint);
      posScore = 1.0 - offset / text.length;
    }

    const rawScore =
      quoteWeight * quoteScore +
      prefixWeight * prefixScore +
      suffixWeight * suffixScore +
      posWeight * posScore;
    const maxScore = quoteWeight + prefixWeight + suffixWeight + posWeight;
    return rawScore / maxScore;
  };

  // Rank matches based on similarity of actual and expected surrounding text
  // and actual/expected offset in the document text.
  const scoredMatches = matches.map(m => ({
    start: m.start,
    end: m.end,
    score: scoreMatch(m),
  }));

  scoredMatches.sort((a, b) => b.score - a.score);

  const { start, end } = scoredMatches[0];
  return {
    start,
    end
  }
}
