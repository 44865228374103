import { SourceViewListing } from '@/types';
import { ErrorBoundary } from '@sentry/react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorFallback } from '../../../core/errors/error-fallback';
import { GlobalAnalyticsContextProvider } from '../../contexts/GlobalAnalyticsContext';
import { Panel } from '../panel';
import { SourcesView } from './sources-view';

type Params = {
  threadId: string;
  chatId: string;
}

export function ActiveChatSourcePanelRoute() {
  const { threadId, chatId } = useParams<Params>();
  const { state } = useLocation();
  const navigate = useNavigate();

  const sourceViewListing: SourceViewListing = state?.source;

  if (!sourceViewListing || !threadId || !chatId) {
    navigate(-1);
    return null;
  }
  const handleOnClose = () => {
    navigate(`/${threadId}`);
  }

  const answerStrategy = state?.answerStrategy || '';

  return (
    <GlobalAnalyticsContextProvider value={{ threadId, chatId, answerStrategy }}>
      <Panel animate={true} onClose={handleOnClose}>
        <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
          <SourcesView sourceViewListing={sourceViewListing} />
        </ErrorBoundary>
      </Panel>
    </GlobalAnalyticsContextProvider>
  )
}
