import { assign, createMachine } from 'xstate';
import { ThreadedAskStreamState } from '../../../../ask-bluej/streaming/threaded/reducer';

export const progressMachine = createMachine({
  types: {} as ProgressMachineType,
  id: 'answer_progress',
  initial: 'initiated',
  context: {
    step: '',
    progress: 0,
    isAnswering: true
  },
  on: {
    // Allow the machine to be completed from any state
    completed: {
      target: '#answer_progress.answering'
    }
  },
  states: {
    initiated: {
      entry: [
        createProgressAssigner('processing_request', 5)
      ],
      after: {
        initiatedDelay: 'searching'
      }
    },
    searching: {
      entry: [
        createProgressAssigner('searching', 20)
      ],
      after: {
        searchingDelay: 'processing'
      }
    },
    processing: {
      initial: 'processing_step_1',
      entry: [
        createProgressAssigner('processing_documents', 30)
      ],
      states: {
        processing_step_1: {
          entry: [
            createProgressAssigner('processing_documents')
          ],
          after: {
            processingDelay: [
              {
                target: 'processing_step_2',
                actions: assign({
                  progress: ({ context }) => Math.min(context.progress + 10, 90)
                })
              }
            ]
          }
        },
        processing_step_2: {
          entry: [
            createProgressAssigner('processing_documents')
          ],
          after: {
            processingDelay: [
              {
                target: 'processing_step_1',
                actions: assign({
                  progress: ({ context }) => Math.min(context.progress + 10, 90)
                })
              }
            ]
          }
        }
      }
    },
    answering: {
      entry: [
        createProgressAssigner('generating_answer', 100),
        assign({
          isAnswering: false
        })
      ],
      on: {
        // Overrides the global event so that it doesn't re-transition into this state and cancel moving to complete
        completed: {}
      },
      after: {
        completeDelay: 'complete'
      }
    },
    complete: {
      type: 'final'
    }
  }
});

type ProgressContext = {
  step: string;
  progress: number;
  isAnswering: boolean;
}

export type InitiatedEvent = {
  type: 'initiated';
};

export type CompletedEvent = {
  type: 'completed';
}

type ProgressEvents = InitiatedEvent | CompletedEvent;

type DelayName = 'completeDelay' | 'searchingDelay' | 'processingDelay' | 'initiatedDelay';

type ProgressMachineType = {
  context: ProgressContext;
  events: ProgressEvents;
  delays: DelayName;
};

type MachineConfig = {
  transitionTiming: {
    initiated: number; // How long to show "Processing request"
    searching: number; // How long we show "Searching content"
    processing: number; // How long we show "Processing documents"
    complete: number; // How long we wait before completing
  }
}

export function createMachineFromConfig(config: MachineConfig, machine = progressMachine) {
  return machine.provide({
    delays: {
      initiatedDelay: config.transitionTiming.initiated,
      searchingDelay: config.transitionTiming.searching,
      processingDelay: config.transitionTiming.processing,
      completeDelay: config.transitionTiming.complete
    },
  })
}


export function machineUpdateEventFromStreamState(state: ThreadedAskStreamState): ProgressEvents {
  switch (state.state) {
    case 'initiated':
      return {
        type: 'initiated'
      };
    case 'answering':
    case 'aborted':
    case 'complete':
    case 'error':
    default:
      return {
        type: 'completed'
      };
  }
}

/**
 * Create an assign action that updates the progress context
 *
 * If no progress is supplied, the current progress in context is retained

 * @param step
 * @param progress
 */
function createProgressAssigner(step: string, progress?: number) {
  return assign<ProgressContext, ProgressEvents, undefined, ProgressEvents, never>(({ context }) => {
    return {
      step,
      progress: progress ?? context.progress,
    }
  });
}
