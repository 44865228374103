import { Config } from '@/types';
import React from 'react';
import { createUseContext } from '../../utils/create-use-context';

const ConfigContext = React.createContext<Config | null>(null);
ConfigContext.displayName = 'ConfigContext';

export const useConfigContext = createUseContext(ConfigContext);

export const ConfigContextProvider = ConfigContext.Provider;
