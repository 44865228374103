import React from 'react';

import { AskDocStreamState } from '../../ask-bluej/streaming/doc/reducer';
import { AnswerMarkdown } from '../../thread/components/answer-markdown';
import { isNoAnswer, isPartialNoAnswer } from '../../ask-bluej';
import { UnableToAnswerDoc } from './unable-to-answer-doc';
import { AnswerLoader } from './answer-loader';
import { QuestionError } from "../../thread/components/active-question-view/error";
import { AskDocSubmit } from "../../ask-bluej/streaming/doc/shared";

type ActiveQuestionViewProps = {
  state: AskDocStreamState;
  onResubmit: AskDocSubmit;
}

export function ActiveDocQuestionView(props: ActiveQuestionViewProps) {
  const { state, onResubmit } = props;

  if (state.state === 'initiated' || (state.state === 'answering' && isPartialNoAnswer(state.answer))) {
    return (
      <AnswerLoader />
    )
  }

  if (state.state === 'answering' || state.state === 'complete') {
    if (isNoAnswer(state.answer)) {
      return (
        <UnableToAnswerDoc />
      )
    }
    return (
      <AnswerMarkdown answer={state.answer}/>
    );
  }

  if (state.state === 'error') {
    return (
      <QuestionError answer={state.answer} resubmit={() => onResubmit(state.payload.question, true)} />
    )
  }

  if (state.state === 'aborted') {
    return (
      <div>TBD: Aborted View</div>
    )
  }
}
