import * as Sentry from '@sentry/react';
import { Extras, User } from '@sentry/types';
import React, { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { createUseContext } from '../../../utils/create-use-context';
import { scrubBreadcrumb } from './utils';

export interface ISentryService {
  setUserData: (userData: User) => void;
  reportError: (err: Error, extras?: Extras) => void;
  reportMessage: (message: string, extras?: Extras) => void;
}

export class SentryService implements ISentryService {
  constructor(dsn: string, environment: string) {
    if (!dsn) {
      return;
    }
    Sentry.init({
      dsn,
      environment,
      beforeBreadcrumb(breadcrumb) {
        return scrubBreadcrumb(breadcrumb);
      },
      integrations: [
        Sentry.feedbackIntegration({
          autoInject: false
        }),
        Sentry.browserProfilingIntegration(),
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // TODO: Set this through configuration!
      tracesSampleRate: 1.0,

      // Set profilesSampleRate to 1.0 to profile every transaction.
      // Since profilesSampleRate is relative to tracesSampleRate,
      // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      // results in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0,
    });
  }

  public reportMessage = (message: string, extras?: Extras) => {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureMessage(message);
    });
  };

  public setUserData = (userData: User) => {
    Sentry.getCurrentScope().setUser(userData);
  };

  public reportError = (err: Error, extras?: Extras) => {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureException(err);
    });
  };
}

const SentryContext = React.createContext<{ sentry: ISentryService } | null>(null);
SentryContext.displayName = 'SentryContext';

export const useSentryContext = createUseContext(SentryContext);

export const SentryContextProvider = SentryContext.Provider;
