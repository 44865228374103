import clsx from 'clsx';
import { useState } from 'react';

import LoadingSvg from './ring-resize.svg?react';
import { useTraceQuery } from './useTraceQuery';
import { SpanTagValue, TSpanEvent } from './types';

type SpanRendererProps = {
  chatId: string;
}

export function Span({ chatId }: SpanRendererProps) {
  const { data, status } = useTraceQuery(chatId);

  if (status === 'error') {
    return (
      <div className="ml-10 mt-10">
        Error Loading Trace data!
      </div>
    )
  }

  if (status === 'pending' || (data && data.spans.length === 0)) {
    return (
      <div className="m-auto">
        <LoadingSvg className="stroke-blue-400 w-10 h-10 mb-25vh" />
      </div>
    )
  }

  return (
    <div className="p-2 mt-12 overflow-y-scroll w-full max-w-full">
      {data.tree.map((spanEvent) => {
        return (
          <div>
            <SpanEvent {...spanEvent} />
          </div>
        )
      })}
    </div>
  )
}

export function SpanEvent({ ...props }: TSpanEvent) {
  const [open, setOpen] = useState(true);
  const containerClassName = clsx('border border-grey-300 border-1 flex flex-col w-full hover:bg-grey-200', {
    'p-4': open,
    'pt-4 pr-4 pl-4 pb-2': !open,
    'hover:bg-red-100 bg-red-50 border-red-100': Object.keys(props.tags).includes('success') && !(props.tags.success as boolean)
  });

  return (
    <div className={containerClassName}>
      <details open={open} onToggle={(e) => setOpen(e.currentTarget.open)}>
        <summary className="flex pb-4 hover:cursor-pointer">
          <SpanEventHeader {...props} />
        </summary>
        <SpanEventTagTable {...props.tags} />

        {props.children && props.children.length > 0 && (
          <div className="flex flex-col bg-white">
            {props.children.sort((a, b) => a.timestamp - b.timestamp).map((event) => <SpanEvent {...event} />)}
          </div>
        )}
      </details>
    </div>
  )
}

function SpanEventHeader({ ...props }: TSpanEvent) {
  return (
    <div className="flex flex-row w-full justify-between">
      <div className="flex flex-row space-x-2 align-middle">
        <div className="font-semibold uppercase text-xs underline underline-offset-4">{props.name}</div>
        <div className="uppercase text-xs text-grey-500">{new Date(props.timestamp).toLocaleString()}</div>
        {Object.keys(props.tags).includes('success') && props.tags.success === false &&
            <div className="font-semibold uppercase text-xxs text-red-500">Unsuccessful</div>}
      </div>
      <div className="font-semibold text-xs">{formatDuration(props.duration)}</div>
    </div>
  )
}

function SpanEventTagTable({ ...props }: Record<string, SpanTagValue>) {
  if (Object.keys(props).length === 0) {
    return null;
  }

  const MAX_TAG_CONTENT_VISIBLE_LENGTH = 200;

  return (
    <table>
      <tbody>
        {Object.entries(props).map(([key, value]) => {
          const strValue = String(value);
          const hide = strValue.length > MAX_TAG_CONTENT_VISIBLE_LENGTH;

          return (
            <tr className="flex flex-row space-x-2 border-b p-2">
              {hide
                ? <LargeField name={key} strValue={strValue} />
                : <NormalField name={key} strValue={strValue} />}
            </tr>
          );
        })
        }
      </tbody>
    </table>
  )
}

type Field = { name: string, strValue: string }

function NormalField({ name, strValue }: Field) {
  return (
    <>
      <td className="font-semibold text-sm">{name}:</td>
      <td className="text-sm text-gray-700 break-all whitespace-pre-line">{strValue} </td>
    </>
  );
}

function LargeField({ name, strValue }: Field) {
  return (
    <td colSpan={2}>
      <details className="align-middle hover:cursor-pointer">
        <summary className="font-semibold pb-2 uppercase text-xs pt-[2px]">{name}</summary>
        <pre className="whitespace-pre-wrap">
         {strValue}
        </pre>
      </details>
    </td>
  );
}

function formatDuration(durationMs: number) {
  if (durationMs < 1) return null;

  const seconds = durationMs / 1000;

  const formatSeconds = seconds.toFixed(3);

  return `${formatSeconds}s`;
}
