import { ErrorBoundary } from '@sentry/react';
import React, { useCallback, useEffect } from 'react';
import { NavigationType, useNavigate, useNavigationType, useParams } from 'react-router-dom';

import ErrorSvg from '../../../../assets/svg/error.svg?react'
import { ErrorFallback } from '../../../core/errors/error-fallback';
import { GlobalAnalyticsContextProvider } from '../../contexts/GlobalAnalyticsContext';
import { useChatQuery } from '../../useChatQuery';
import { useThreadQuery } from '../../useThreadQuery';
import { Panel } from '../panel';
import { SourcesPager } from './sources-pager';
import { SourcesView } from './sources-view';

type Params = {
  threadId: string;
  chatId: string;
  sourceIndex: string;
}

export function SourcesViewErrorComponent() {
  return (
    <div className="flex flex-col items-center h-full w-full mt-10">
      <ErrorSvg />
      <p className="py-4 text-lg">
        Error loading source
      </p>
    </div>
  );
}

function SourcesViewLoadingComponent() {
  return (
    <div>Loading</div>
  )
}

export function SourcesPanelRoute() {
  const { threadId, chatId, sourceIndex } = useParams<Params>();

  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const handleOnClose = useCallback(() => {
    navigate(`/${threadId}`);
  }, [threadId]);

  useEffect(() => {
    if (!threadId || !chatId || !sourceIndex) {
      // router complains if we do this outside of an effect
      handleOnClose();
    }
  }, [handleOnClose, threadId, chatId, sourceIndex]);

  if (!threadId || !chatId || !sourceIndex) {
    return null;
  }

  return (
    <SourcesPanel
      chatId={chatId}
      onClose={handleOnClose}
      navigationType={navigationType}
      sourceIndex={sourceIndex}
      threadId={threadId}
    />
  );
}

type SourcesPanelProps = {
  chatId: string;
  onClose: () => void;
  navigationType: NavigationType;
  sourceIndex: string;
  threadId: string;
}

function SourcesPanel({ chatId, onClose, navigationType, sourceIndex, threadId }: SourcesPanelProps) {
  const { data, status } = useThreadQuery(threadId);
  const { data: chatData, status: chatStatus } = useChatQuery(threadId, chatId);

  if (!data || status !== 'success') {
    return null;
  }

  // URLs are 1-indexed
  const parsedSourceIndex = parseInt(sourceIndex) - 1;

  const shouldAnimate = navigationType === 'PUSH';

  if (chatStatus === 'error') {
    return <SourcesViewErrorComponent />
  }

  // TODO: Figure out what happens when it uses initial data, it drops `loading` from status type.
  //   - It starts as `success` initially and data is whatever was returned in initialData... should we assume loading?
  if (chatData === undefined) {
    return <SourcesViewLoadingComponent />
  }

  if (parsedSourceIndex < 0 || parsedSourceIndex >= chatData.sources.length) {
    return <div>No source here, right?</div>
  }

  const sourceViewListing = chatData.sources[parsedSourceIndex];

  const sourceNavigationComponent = (
    <SourcesPager
      number={parsedSourceIndex}
      sourceType={sourceViewListing.type}
      sources={chatData.sources}
      additionalSources={false}
    />
  );

  const answerStrategy = chatData.meta?.answerStrategyName || '';

  return (
    <GlobalAnalyticsContextProvider value={{ threadId, chatId, answerStrategy }}>
      <Panel onClose={onClose} animate={shouldAnimate}>
        <ErrorBoundary fallback={<ErrorFallback errorText="Error loading source." />}>
          <SourcesView
            sourceViewListing={sourceViewListing}
            sourceNavigationComponent={sourceNavigationComponent}
          />
        </ErrorBoundary>
      </Panel>
    </GlobalAnalyticsContextProvider>
  );
}
