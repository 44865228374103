export const BUTTON_CLASSES_WITHOUT_COLOR = [
  'transition-colors',
  'inline-flex',
  'items-center',
  'gap-x-1.5',
  'rounded-md',
  'px-2.5',
  'py-1.5',
  'text-sm',
  'font-semibold',
  'text-white',
  'shadow-sm',
  'focus-visible:outline',
  'focus-visible:outline-2',
  'focus-visible:outline-offset-2',
  'focus-visible:outline-blue-400',
  'disabled:bg-grey-400',
  'disabled:cursor-not-allowed'
];

export const BUTTON_CLASSES = [
  ...BUTTON_CLASSES_WITHOUT_COLOR,
  'bg-blue-400',
  'hover:bg-blue-500'
];
