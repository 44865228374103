import { v4 as uuid } from 'uuid';
import { SpanKind, TSpanEvent } from './types';

function createShadowParent() {
  const id = uuid();
  const name = `Pending Span Event`;

  return {
    id,
    traceId: id,
    timestamp: Date.now(),
    duration: 0,
    parentId: null,
    name,
    tags: {},
    kind: 'server' as SpanKind
  };
}

export function spanEventArrayToTree(spanEvents: TSpanEvent[]): TSpanEvent[] {
  const sorted = spanEvents.sort((a, b) => a.timestamp  - b.timestamp);
  const map: Record<string, TSpanEvent & { children: TSpanEvent[] }> = {};
  const tree: TSpanEvent[] = [];

  sorted.forEach(event => map[event.id] = { ...event, children: [] });
  sorted.forEach(event => {
    if (event.parentId) {
      if (map[event.parentId]) {
        map[event.parentId].children.push(map[event.id]);
      } else {
        map[event.parentId] = { ...createShadowParent(), children: [map[event.id]] };
        tree.push(map[event.parentId]);
      }
    } else {
      tree.push(map[event.id]);
    }
  });

  return tree;
}
