import { useAskBluejApiContext } from '../core/api/ask-bluej-api';
import { useQuery } from '@tanstack/react-query';

export function useSavedPromptGetQuery() {
  const { api } = useAskBluejApiContext();

  return useQuery({
    queryKey: ['saved-prompts'],
    queryFn: async () => {
      return await api.getSavedPrompts();
    }
  });
}
