import clsx from 'clsx';
import React, { useState } from 'react';
import Button from './button';
import { BUTTON_CLASSES } from './shared';

type Props = {
  onClick: () => Promise<void>;
  className?: string;
  disabled?: boolean;
};

export default function AsyncButton(props: React.PropsWithChildren<Props>) {
  const { onClick, className, disabled, children } = props;
  const [isLoading, setIsLoading] = useState(false);

  const mergedClasses = clsx(
    BUTTON_CLASSES,
    className
  );

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={handleClick}
      className={mergedClasses}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <>
          <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Loading...
        </>
      ) : (
        children
      )}
    </Button>
  );
}
