export type QuestionPayload = {
  question: string;
  strategy: string;
  threadId: string;
  chatId: string;
}

export enum QuestionInteractionType {
  QUESTION_INPUT = 'question-input',
  RESUBMIT = 'resubmit',
  FOLLOW_UP = 'follow-up',
  CREATE_MEMO = 'create-memo',
  CREATE_EMAIL = 'create-email'
}
