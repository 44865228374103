import { useConfigContext } from '../../config/ConfigContext';
import { PropsWithChildren } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const ALLOWED_ENVIRONMENTS = ['dev', 'local'];

export function DevToolsProvider({ children }: PropsWithChildren) {
  const { environment } = useConfigContext();

  if (ALLOWED_ENVIRONMENTS.includes(environment)) {
    return (
      <>
        <ReactQueryDevtools initialIsOpen={false} />
        { children }
      </>
    )
  }

  return (
    <>
      { children }
    </>
  );
}
