import { Chat } from '@/types';
import React from 'react';
import { createUseContext } from '../../../utils/create-use-context';
import { isNoAnswer } from '../../ask-bluej';

type InternalChatContextValue = {
  chat: Chat;
}

type ChatContextValue = InternalChatContextValue & {
  sourcesVisible: boolean;
  feedbackVisible: boolean;
  shareVisible: boolean;
  unanswered: boolean;
}

const ChatContext = React.createContext<InternalChatContextValue | null>(null);
ChatContext.displayName = 'ChatContext';
export const ChatContextProvider = ChatContext.Provider;

const _useChatContext = createUseContext(ChatContext);

export function useChatContext(): ChatContextValue {
  const { chat } = _useChatContext();
  return {
    chat,
    sourcesVisible: sourcesVisible(chat),
    feedbackVisible: feedbackVisible(chat),
    shareVisible: shareVisible(chat),
    unanswered: isNoAnswer(chat.answer)
  }
}

function sourcesVisible(chat: Chat) {
  return chat.chat_type !== 'aborted';
}

function feedbackVisible(chat: Chat) {
  return chat.chat_type !== 'aborted';
}

function shareVisible(chat: Chat) {
  return !isNoAnswer(chat.answer) && chat.chat_type !== 'summary_noanswer' && chat.chat_type !== 'aborted';
}
