import clsx from "clsx";
import React from "react";

type SourceContentProps = {
  content: string;
  onContentReady: (element: HTMLDivElement | null) => void;
}
export function SourceContent({ content, onContentReady }: SourceContentProps) {
  const TYPOGRAPHY_CLASSES = [
    'prose',
    'prose-pre:whitespace-pre-wrap',
    '[&_*]:!prose-sm', // apply prose-sm class to all descendants
    '[&_*]:!font-sans', // apply font-sans class to all descendants
    'prose-custom-links'
  ];

  const contentClasses = clsx(
    TYPOGRAPHY_CLASSES,
    'mt-8',
    'max-w-full',
    'print-bg',
    'vendor-content' // custom class to apply custom styles to vendor content
  );

  return (
    <div className={contentClasses} ref={onContentReady} dangerouslySetInnerHTML={{
      __html: content
    }}/>
  );
}
