import React, { PropsWithChildren, useCallback, useState } from 'react';
import { createUseContext } from '../../../utils/create-use-context';

const BLUEJ_USER_STRATEGY_STORAGE_KEY = 'bluej-user-strategy';

export type StrategyContext = {
  setSelectedStrategy: (strategy: string) => void;
  selectedStrategy: string;
}

export const StrategyContext = React.createContext<StrategyContext | null>(null);
StrategyContext.displayName = 'StrategyContext';

export function StrategyContextProvider(props: PropsWithChildren) {
  let _strategy = localStorage.getItem(BLUEJ_USER_STRATEGY_STORAGE_KEY) || '';

  const [selectedStrategy, _setSelectedStrategy] = useState(_strategy);

  const setSelectedStrategy = useCallback((strategy: string) => {
    _strategy = strategy;
    _setSelectedStrategy(strategy);
    localStorage.setItem(BLUEJ_USER_STRATEGY_STORAGE_KEY, strategy);
  }, []);

  const strategyContextValue = {
    setSelectedStrategy,
    selectedStrategy
  }

  return (
    <StrategyContext.Provider value={strategyContextValue}>
      {props.children}
    </StrategyContext.Provider>
  );
}

export const useStrategyContext = createUseContext(StrategyContext);
