import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect } from 'react';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import { NumberFormatter, useNumberFormatter } from '../../../core/hooks/useNumberFormatter';

import { DEFAULT_USER_VALIDATION_CONFIG, InputState } from './validation';

export type InputVariant = 'condensed' | 'normal';

type InputFrameProps = {
  state: InputState;
  variant: InputVariant;
}

export function InputFrame(props: PropsWithChildren<InputFrameProps>) {
  const { children, variant, state } = props;

  const formatter = useNumberFormatter();
  const { trackTextLimitWarningViewed } = useAnalyticsEvent();

  const wrapperClasses = clsx(
    'transition-all',
    'flex flex-col border items-center border-grey-400 shadow-sm px-4 rounded-2xl bg-grey-100 focus-within:ring-2 focus-within:ring-blue-400 max-w-5xl mx-auto overflow-hidden',
    {
      'py-8': variant === 'normal',
      'py-4': variant === 'condensed',
    },
    {
      'border-red-200 focus-within:ring-red-200': !state.valid,
    },
  );

  const { refs, floatingStyles } = useFloating({
    open: !state.valid,
    whileElementsMounted: autoUpdate,
    placement: 'top',
    middleware: [
      offset({ mainAxis: -8 }),
    ],
  });

  useEffect(() => {
    if (state.valid) return;

    if (state.reason === 'too-long') {
      trackTextLimitWarningViewed();
    }
  }, [state.valid])

  return (
    <>
      {!state.valid && (
        <div ref={refs.setFloating} style={floatingStyles} role="tooltip" className="px-20">
          <Transition show={true} appear={true}>
            <div className="transition duration-100 ease-in data-[closed]:opacity-0">
              <div className="max-w-[80ch] flex text-black-400 flex-row items-center select-none tooltip bg-red-100 border border-red-200 p-4 rounded-lg shadow-md z-[9999]">
                <div aria-hidden="true">
                  <ExclamationCircleIcon className="w-8 h-8 block text-red-500 mr-2" />
                </div>
                <div>
                  <span>{promptWarningMessage(state, formatter)}</span>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      )}
      <div ref={refs.setReference} className={wrapperClasses}>
        {children}
      </div>
    </>
  )
}

function promptWarningMessage(state: InputState, formatter: NumberFormatter) {
  if (state.valid) {
    return '';
  }

  if (state.reason === 'too-long') {
    return `This prompt is too long. The maximum number of characters is ${formatter.format(DEFAULT_USER_VALIDATION_CONFIG.maxInputLength)}.`;
  }
}
