import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface ThreadListItemProps {
  active?: boolean;
}

export default function ThreadListItem(props: PropsWithChildren<ThreadListItemProps>) {
  const { active } = props;
  const classes = clsx('bg-grey-300 hover:bg-grey-350 group relative', {
    'bg-grey-350 hover:bg-grey-400': active
  });

  return (
    <li className={classes}>
      { props.children }
    </li>
  )
}

