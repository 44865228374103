import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ErrorPage } from '../../core/errors/error-page';

import { ThreadSidebar } from '../components/thread-sidebar';
import { useMobileHeaderContext } from '../contexts/MobileHeaderContext';

type Params = {
  threadId?: string;
}

export function ThreadContainerRoute() {
  const { threadId } = useParams<Params>();
  const { threadMenuVisible, toggleThreadMenuVisibility } = useMobileHeaderContext();

  return (
    <ErrorBoundary
      fallback={
        <ErrorPage
          title="Something went wrong."
          description="Please try again later. If the problem persists, please contact support."
        />
      }
    >
      <div className="flex h-dynamic-full">
        <Transition show={threadMenuVisible}>
          <Dialog className="relative z-20 overflow-hidden lg:hidden" onClose={toggleThreadMenuVisibility}>
            <div className="fixed inset-0">
              <TransitionChild
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full opacity-0"
                enterTo="-translate-x-0 opacity-100"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="-translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="flex flex-1 flex-col bg-grey-200 h-dynamic-full">
                  <ThreadSidebar threadId={threadId} showCloseButton />
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
        <aside className="hidden lg:flex flex-col justify-between bg-grey-200 border-r border-r-grey-300 w-80">
          <ThreadSidebar threadId={threadId}/>
        </aside>
        <main key={threadId} className="flex flex-1 flex-col h-full bg-grey-100 justify-center min-w-0">
          <Outlet/>
        </main>
      </div>
    </ErrorBoundary>
  )
}
