import { PartialThread } from '@/types';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';
import AnimateHeight from 'react-animate-height';
import { useNavigate } from 'react-router-dom';

import { useAnalyticsEvent } from '../../../core/analytics/useAnalyticsEvent';
import ThreadItem from './thread-item';
import ThreadListItem from './thread-list-item';

interface ListComponentProps {
  activeThreadId?: string;
  threads: PartialThread[];
  header?: string;
  defaultOpen?: boolean;
  inSearch: boolean;
}

export function ThreadListComponent(props: ListComponentProps) {
  const { threads, activeThreadId, header, defaultOpen = true, inSearch } = props;
  const navigate = useNavigate();
  const { trackThreadGroupToggled } = useAnalyticsEvent();

  const handleOnDelete = useCallback( (id: string) => {
    if (id === activeThreadId) {
      navigate('/');
    }
  }, [activeThreadId]);

  if (threads.length === 0) return null;

  const onThreadGroupHeaderClick = (open: boolean, threadGroupTitle: string) => {
    trackThreadGroupToggled({ open, threadGroupTitle });
  }

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) =>
        <>
          { header &&
            <DisclosureButton
              className="group bg-grey-200 text-sm text-grey-600 p-2 font-semibold w-full text-left"
              onClick={() => onThreadGroupHeaderClick(!open, header)}
            >
                <ChevronRightIcon className="inline-block h-5 w-5 align-middle mr-1 transition group-data-[open]:rotate-90 group-data-[open]:transform" />
                { header }
              </DisclosureButton>
          }
          <AnimateHeight
            duration={300}
            height={open ? 'auto' : 0}
          >
            <DisclosurePanel static as="ul">
              { threads.map(({ id, title, topic }) => {
                const active = id === activeThreadId;
                return (
                  <ThreadListItem key={id} active={active}>
                    <ThreadItem
                      active={active}
                      id={id}
                      title={title}
                      onDelete={handleOnDelete}
                      topic={topic}
                      inSearch={inSearch}
                    />
                  </ThreadListItem>
                )
              })}
            </DisclosurePanel>
          </AnimateHeight>
        </>
      }
    </Disclosure>
  );
}
