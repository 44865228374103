import React from 'react';

import { ThreadedAskStreamAbortedState } from '../../ask-bluej/streaming/threaded/reducer';
import { GlobalAnalyticsContextProvider } from '../contexts/GlobalAnalyticsContext';

import { ActiveQuestionView } from './active-question-view';

export function AbortedChat(props: AbortedQuestionProps) {
  const { state, clearAbortedQuestion } = props;

  if (state === null) {
    return null;
  }

  return (
    <GlobalAnalyticsContextProvider value={{ chatId: state.payload.chatId, answerStrategy: state.payload.strategy }}>
      <div className="mb-4">
        <ActiveQuestionView state={state} resubmitQuestion={noop} clearAbortedQuestion={clearAbortedQuestion} />
      </div>
    </GlobalAnalyticsContextProvider>
  );
}

type AbortedQuestionProps = {
  state: ThreadedAskStreamAbortedState | null;
  clearAbortedQuestion: () => void;
}

const noop = () => {};
