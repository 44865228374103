import { useEffect, useState } from 'react';

export function useMobileKeyboardOpen() {

  const [isMobileKeyboardOpen, setIsMobileKeyboardOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // If visual viewport is small (i.e., mobile keyboard is open)
      if (window.visualViewport?.offsetTop || 0 > 0) {
        setIsMobileKeyboardOpen(true);
      }
    }

    window.visualViewport?.addEventListener('scroll', handleScroll);

    return () => {
      window.visualViewport?.removeEventListener('scroll', handleScroll);
    };
  }, [window.visualViewport]);

  return isMobileKeyboardOpen;
}
