import { Placeholder } from '../placeholder';
import React from 'react';

export const ThreadChatLoader = () => (
  <div className="pt-2 w-full mx-auto">
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 py-1">
        <div className="space-y-6">
          <Placeholder count={2}>
            <div className="flex">
              <div className="flex flex-row w-full items-center">
                <div className="rounded-full h-14 w-16 mr-5 bg-slate-200"/>
                <div className="rounded-sm h-16 w-full bg-slate-200"/>
              </div>
            </div>
          </Placeholder>
        </div>
        <div className="mt-8 space-y-4">
          <Placeholder count={3}>
            <div className="grid grid-cols-3 gap-2">
              <div className="rounded-sm h-2 bg-slate-100 col-span-0.5"></div>
              <div className="rounded-sm h-2 bg-slate-100 col-span-2"></div>
            </div>
          </Placeholder>
        </div>
      </div>
    </div>
  </div>
);
