import React from "react";
import { SourceView } from "@/types";
import { SourceBadge } from "../../thread/components/sources/source-badge";

type SourceHeaderProps = {
  badge: SourceView['badge'];
  title: string;
}
export function SourceHeader({ badge, title }: SourceHeaderProps) {
  return (
    <div className="w-full">
      <SourceBadge badge={badge} />
      <h2 className="font-semibold my-2">{title}</h2>
    </div>
  );
}
